import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-info-gen',
  templateUrl: './info-gen.component.html',
  styleUrls: ['./info-gen.component.css']
})
export class InfoGenComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public buildings = [];

  public building: any = {
    building_id: '-1',
    building_name: '',
    building_cplx_id: '-1',
    building_note: '',
    building_active: 'Y',
    building_log: '',
  };

  public complex: any = {
    cplx_id: '-1',
    cplx_name: '',
    cplx_logo: '',
    cplx_layout: '',
    cplx_contact_name: '',
    cplx_cust_id: '-1',
    cplx_address: '',
    cplx_zip: '',
    cplx_phone1: '',
    cplx_phone2: '',
    cplx_type: '',
    cplx_camera1: '',
    cplx_camera2: '',
    cplx_active: 'Y',
    cplx_timezone: 'America/Puerto_Rico',
    cplx_log: ''
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'building_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public building_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public building_cplx_id_payload_search = [
  ];





  // tslint:disable-next-line:variable-name
  public building_cplx_id_payload = [
  ];



  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

  }

  public payloadsChanged() {
  }

  public init() {
    this.buildings = [];
    this.reset();
    this.getAllBuildings();
  }

  public reset() {
    this.building = {
      building_id: '-1',
      building_name: '',
      building_cplx_id: '-1',
      building_note: '',
      building_active: 'Y',
      building_log: '',
    };
    this.complex = {
      cplx_id: '-1',
      cplx_name: '',
      cplx_logo: '',
      cplx_layout: '',
      cplx_contact_name: '',
      cplx_cust_id: '-1',
      cplx_address: '',
      cplx_zip: '',
      cplx_phone1: '',
      cplx_phone2: '',
      cplx_type: '',
      cplx_camera1: '',
      cplx_camera2: '',
      cplx_timezone: 'America/Puerto_Rico',
      cplx_active: 'Y',
      cplx_log: ''
    };

    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllBuildings() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      id: this.building_cplx_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('buildings/get_selected_complex', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.buildings = this.buildings.concat(result.data);
        if (result.data.length > 0) {
          this.complex = result.data[0];
        } else {
          this.reset();
        }
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.buildings.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllBuildings();
  }

  public onSubmit() {
    // this.loadingText = 'Saving...';
    // const itemObj = JSON.parse(JSON.stringify(this.building));
    // this.app.getQuery('buildings/add_edit_building', itemObj).then(result => {
    //   this.loadingText = 'Save';
    //   console.log(result);
    //   if (result.status === 'OK') {
    //     $('#myModal').modal('hide');
    //     this.onClear();
    //   } else {
    //     this.message = result.message;
    //     setTimeout(() => {
    //       this.message = '';
    //     }, 4000);
    //   }
    // });
  }



  public add() {
    this.reset();
    $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.building = tempItem;

    $('#myModal').modal('show');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



