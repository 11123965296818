
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-emergency-agencies',
  templateUrl: './emergency-agencies.component.html',
  styleUrls: ['./emergency-agencies.component.css']
})
export class EmergencyAgenciesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public emergencyAgencies = [];

  public emergencyAgency: any = {
    ea_id: '-1',
    ea_name: '',
    ea_contact_name: '',
    ea_phone1: '',
    ea_phone2: '',
    ea_email: '',
    ea_address: '',
    ea_latitude: '',
    ea_longitude: '',
    complexes: [],
    ea_active: 'Y',
    ea_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'ea_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';












  // tslint:disable-next-line:variable-name
  public complexes_payload = [
  ];


  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {
  }

  public payloadsChanged() {
  }

  public init() {
    this.emergencyAgencies = [];
    this.reset();
    this.getAllEmergencyAgencies();
  }

  public reset() {
    this.emergencyAgency = {
      ea_id: '-1',
      ea_name: '',
      ea_contact_name: '',
      ea_phone1: '',
      ea_phone2: '',
      ea_email: '',
      ea_address: '',
      ea_latitude: '',
      ea_longitude: '',
      complexes: [],
      ea_active: 'Y',
      ea_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }



  public onMapCordsChanged(obj) {
    this.emergencyAgency.ea_latitude = obj.lat;
    this.emergencyAgency.ea_longitude = obj.lon;
  }

  public getAllEmergencyAgencies() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('emergencyagencies/get_all_emergency_agencies', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.emergencyAgencies = this.emergencyAgencies.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.emergencyAgencies.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllEmergencyAgencies();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.emergencyAgency));
    this.app.getQuery('emergencyagencies/add_edit_emergencyagency', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModal').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.emergencyAgency = tempItem;

    this.get_selected_complexes();
    $('#myModal').modal('show');
  }

  private get_selected_complexes() {
    this.app.getQuery('emergencyagencies/get_selected_complexes', { id: this.emergencyAgency.ea_id }).then(result => {
      console.log(result);
      if (result.status === 'OK') {
        this.emergencyAgency.complexes = result.data;
      }
    });
  }


  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



