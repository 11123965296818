
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timeLinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput, Calendar } from '@fullcalendar/core';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  @ViewChild('baseForm') baseForm: NgForm;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  public calendarPlugins = [dayGridPlugin, timeGridPlugin, timeLinePlugin, interactionPlugin];
  public calendarWeekends = true;
  public calendarEvents: EventInput[] = [];
  public calendarApi: Calendar;

  public fromDate = '';
  public toDate = '';
  public isAdmin = localStorage.getItem('user_level');

  public actfilter = true;
  public inactfilter = false;
  public loaded = 'N';
  public appointments = [];

  public appointment: any = {
    appointment_id: '-1',
    appointment_cplx_id: '-1',
    appointment_home_id: '-1',
    appointment_res_id: '-1',
    appointment_datetime_from: '',
    appointment_datetime_to: '',
    appointment_for_person: '',
    appointment_for_resident: 'Y',
    appointment_notes: '',
    appointment_status: 'pending_approval',
    appointment_active: 'Y',
    appointment_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '1000';
  // tslint:disable-next-line:variable-name
  public order_by = 'appointment_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public appointment_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public appointment_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public appointment_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public appointment_home_id_payload_search = [
    { id: 'appointment_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public appointment_res_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public appointment_res_id_payload_search = [
    { id: 'appointment_cplx_id', value: '-1' },
    { id: 'appointment_home_id', value: '-1' },
  ];




  //add filters to apointment 

  // tslint:disable-next-line:variable-name
  public appointment_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public appointment_home_id_payload = [
    { id: 'appointment_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public appointment_res_id_payload = [
    { id: 'appointment_cplx_id', value: '-1' },
    { id: 'appointment_home_id', value: '-1' },
  ];





  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    // this.init();
  }

  ngAfterViewChecked() {
    this.calendarApi = this.calendarComponent.getApi();
  }

  datesRender(model: any) {
    // model.el.style.backgroundColor = 'blue';
    console.log(moment(model.view.activeStart).format('YYYY-MM-DD'));
    console.log(moment(model.view.activeEnd).format('YYYY-MM-DD'));
    this.fromDate = moment(model.view.activeStart).format('YYYY-MM-DD') + ' 00:00:00';
    this.toDate = moment(model.view.activeEnd).format('YYYY-MM-DD') + ' 23:59:59';
    this.init();
    // this.getRosterCal(moment(model.view.activeStart).format("YYYY-MM-DD"), moment(model.view.activeEnd).format("YYYY-MM-DD"));
  }

  public handleEventClick(model: any) {
    console.log(model.event._def.extendedProps);
    this.editItem(model.event._def.extendedProps);
    // this.getRoster(model.event.id);
  }

  public searchPayloadsChanged() {

    this.appointment_home_id_payload_search = [
      { id: 'appointment_cplx_id', value: this.appointment_cplx_id_search },
    ];

    this.appointment_res_id_payload_search = [
      { id: 'appointment_cplx_id', value: this.appointment_cplx_id_search },
      { id: 'appointment_home_id', value: this.appointment_home_id_search },
    ];

  }

  public payloadsChanged() {
    this.appointment_home_id_payload = [
      { id: 'appointment_cplx_id', value: this.appointment.appointment_cplx_id },
    ];
    this.appointment_res_id_payload = [
      { id: 'appointment_cplx_id', value: this.appointment.appointment_cplx_id },
      { id: 'appointment_home_id', value: this.appointment.appointment_home_id },
    ];
  }

  public init() {
    this.appointments = [];
    this.reset();
    this.getAllAppointments();
  }

  public reset() {
    this.appointment = {
      appointment_id: '-1',
      appointment_cplx_id: '-1',
      appointment_home_id: '-1',
      appointment_res_id: '-1',
      appointment_datetime_from: '',
      appointment_datetime_to: '',
      appointment_for_person: '',
      appointment_for_resident: 'Y',
      appointment_notes: '',
      appointment_active: 'Y',
      appointment_status: 'pending_approval',
      appointment_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.appointment_home_id_search = '-1';
    this.appointment_res_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.appointment_res_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onResIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.appointment.appointment_home_id = '-1';
    this.appointment.appointment_res_id = '-1';
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.appointment.appointment_res_id = '-1';
    this.payloadsChanged();
  }
  public onResIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllAppointments() {
    this.loaded = 'N';
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      appointment_cplx_id_search: this.appointment_cplx_id_search,


      appointment_home_id_search: this.appointment_home_id_search,


      appointment_res_id_search: this.appointment_res_id_search,
      from_date: this.fromDate,
      to_date: this.toDate,
      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('appointments/get_all_appointments', obj).then(result => {

      console.log(result);
      this.loaded = 'Y';
      if (result.status === 'OK') {
        this.appointments = this.appointments.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.appointments.length) + ' of ' + this.count;
        const calendarEvents = [];
        result.data.forEach(element => {
          // element.start = moment(element.start);
          // element.end = moment(element.end);
          // if (element.apnt_status !== 'Cancelled') {
          console.log(element)
          element.id = element.appointment_id;
          element.start = element.appointment_datetime_from;
          element.end = element.appointment_datetime_to;
          // tslint:disable-next-line:max-line-length
          // element.title = element.apnt_patient_last + ' ' + element.apnt_patient_name + ' (' + this.convertToTime(element.apnt_date_time) + '-' + this.convertToTime(element.apnt_date + ' ' + element.end) + ')';
          element.title = element.appointment_notes;
          calendarEvents.push(element);
          // console.log(element);
          // }
        });
        this.calendarEvents = calendarEvents;
        this.calendarApi.removeAllEventSources();
        this.calendarApi.addEventSource(this.calendarEvents);
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllAppointments();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.appointment));
    if (itemObj.appointment_for_resident === 'Y') {
      itemObj.appointment_for_person = '';
    } else {
      itemObj.appointment_home_id = '-1';
      itemObj.appointment_res_id = '-1';
    }
    this.app.getQuery('appointments/add_edit_appointment', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModalappointment_id').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModalappointment_id').modal('show');
    // $('#myModalappointment_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.appointment = tempItem;

    this.payloadsChanged();
    $('#myModalappointment_id').modal('show');
    // $('#myModalappointment_id').appendTo('body');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



