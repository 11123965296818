
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-package-logs',
  templateUrl: './package-logs.component.html',
  styleUrls: ['./package-logs.component.css']
})
export class PackageLogsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public loaded = 'N';
  public packageLogs = [];

  public packageLog: any = {
    pack_id: '-1',
    pack_cplx_id: '-1',
    pack_home_id: '-1',
    pack_res_id: '-1',
    pack_datetime: '',
    pack_note: '',
    pack_picked: 'N',
    pack_active: 'Y',
    pack_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '50';
  // tslint:disable-next-line:variable-name
  public order_by = 'pack_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public pack_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public pack_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public pack_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public pack_home_id_payload_search = [
    { id: 'pack_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public pack_res_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public pack_res_id_payload_search = [
    { id: 'pack_cplx_id', value: '-1' },
    { id: 'pack_home_id', value: '-1' },
  ];




  // tslint:disable-next-line:variable-name
  public pack_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public pack_home_id_payload = [
    { id: 'pack_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public pack_res_id_payload = [
    { id: 'pack_cplx_id', value: '-1' },
    { id: 'pack_home_id', value: '-1' },
  ];





  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

    this.pack_home_id_payload_search = [
      { id: 'pack_cplx_id', value: this.pack_cplx_id_search },
    ];

    this.pack_res_id_payload_search = [
      { id: 'pack_cplx_id', value: this.pack_cplx_id_search },
      { id: 'pack_home_id', value: this.pack_home_id_search },
    ];

  }

  public payloadsChanged() {
    this.pack_home_id_payload = [
      { id: 'pack_cplx_id', value: this.packageLog.pack_cplx_id },
    ];
    this.pack_res_id_payload = [
      { id: 'pack_cplx_id', value: this.packageLog.pack_cplx_id },
      { id: 'pack_home_id', value: this.packageLog.pack_home_id },
    ];
  }

  public init() {
    this.packageLogs = [];
    this.reset();
    this.getAllPackageLogs();
  }

  public reset() {
    this.packageLog = {
      pack_id: '-1',
      pack_cplx_id: '-1',
      pack_home_id: '-1',
      pack_res_id: '-1',
      pack_datetime: '',
      pack_note: '',
      pack_picked: 'N',
      pack_active: 'Y',
      pack_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.pack_home_id_search = '-1';
    this.pack_res_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.pack_res_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onResIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.packageLog.pack_home_id = '-1';
    this.packageLog.pack_res_id = '-1';
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.packageLog.pack_res_id = '-1';
    this.payloadsChanged();
  }
  public onResIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllPackageLogs() {
    this.loaded = 'N';
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      pack_cplx_id_search: this.pack_cplx_id_search,


      pack_home_id_search: this.pack_home_id_search,


      pack_res_id_search: this.pack_res_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('packagelogs/get_all_package_logs', obj).then(result => {

      console.log(result);
      this.loaded = 'Y';
      if (result.status === 'OK') {
        this.packageLogs = this.packageLogs.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.packageLogs.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllPackageLogs();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.packageLog));
    this.app.getQuery('packagelogs/add_edit_packagelog', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModalpack_id').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModalpack_id').modal('show');
    // $('#myModalpack_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.packageLog = tempItem;

    this.payloadsChanged();
    $('#myModalpack_id').modal('show');
    // $('#myModalpack_id').appendTo('body');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



