
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-offices',
  templateUrl: './offices.component.html',
  styleUrls: ['./offices.component.css']
})
export class OfficesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public offices = [];
  //TODO: added 
  public isAdmin = localStorage.getItem('user_level');

  public office: any = {
    off_id: '-1',
    off_name: '',
    off_number: '',
    off_cplx_id: '-1',
    off_building_id: '-1',
    off_phone: '',
    off_phone_extn: '',
    off_floor: '',
    off_curr_emp: '',
    off_lat: '18.391042',
    off_lng: '-66.13852',
    off_active: 'Y',
    off_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'off_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public off_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public off_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public off_building_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public off_building_id_payload_search = [
    { id: 'off_cplx_id', value: '-1' },
  ];






  // tslint:disable-next-line:variable-name
  public off_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public off_building_id_payload = [
    { id: 'off_cplx_id', value: '-1' },
  ];







  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

    this.off_building_id_payload_search = [
      { id: 'off_cplx_id', value: this.off_cplx_id_search },
    ];

  }

  public payloadsChanged() {
    this.off_building_id_payload = [
      { id: 'off_cplx_id', value: this.office.off_cplx_id },
    ];
  }

  public init() {
    this.offices = [];
    this.reset();
    this.getAllOffices();
  }

  public reset() {
    this.office = {
      off_id: '-1',
      off_name: '',
      off_number: '',
      off_cplx_id: '-1',
      off_building_id: '-1',
      off_phone: '',
      off_phone_extn: '',
      off_floor: '',
      off_curr_emp: '',
      off_lat: '18.391042',
      off_lng: '-66.13852',
      off_active: 'Y',
      off_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.off_building_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onBuildingIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.office.off_building_id = '-1';
    this.payloadsChanged();
  }
  public onBuildingIdChanged(obj) {
    this.payloadsChanged();
  }

  public onMapCordsChanged(obj) {
    this.office.off_lat = obj.lat;
    this.office.off_lng = obj.lon;
  }

  public getAllOffices() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      off_cplx_id_search: this.off_cplx_id_search,


      off_building_id_search: this.off_building_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('offices/get_all_offices', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.offices = this.offices.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.offices.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllOffices();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.office));
    this.app.getQuery('offices/add_edit_office', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModal').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged();
    $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.office = tempItem;

    this.payloadsChanged();
    $('#myModal').modal('show');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



