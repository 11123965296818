
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public sales = [];

  public sale: any = {
         sales_id: '-1',
                   sales_email: '',
                   sales_name: '',
                   sales_last_name: '',
                   sales_phone: '',
                   sales_shipping_address1: '',
                   sales_shipping_address2: '',
                   sales_shipping_country_id: '-1',
                   sales_shipping_state_id: '-1',
                   sales_shipping_city_id: '-1',
                   sales_shipping_zip_code: '',
                   sales_billing_address1: '',
                   sales_billing_address2: '',
                   sales_billing_country_id: '-1',
                   sales_billing_state_id: '-1',
                   sales_billing_city_id: '-1',
                   sales_billing_zip_code: '',
                   sales_completed: '',
                   sales_active: 'Y',
                   sales_log: '',
          };

  
  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'sales_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
                                                                                                                
    
      
      
      
      
      
      
      
       
  // tslint:disable-next-line:variable-name
  public sales_shipping_country_id_payload = [
      ];    
        
       
  // tslint:disable-next-line:variable-name
  public sales_shipping_state_id_payload = [
        {id: 'sales_shipping_country_id', value: '-1'},
      ];    
        
       
  // tslint:disable-next-line:variable-name
  public sales_shipping_city_id_payload = [
        {id: 'sales_shipping_state_id', value: '-1'},
      ];    
        
      
      
      
       
  // tslint:disable-next-line:variable-name
  public sales_billing_country_id_payload = [
      ];    
        
       
  // tslint:disable-next-line:variable-name
  public sales_billing_state_id_payload = [
        {id: 'sales_billing_country_id', value: '-1'},
      ];    
        
       
  // tslint:disable-next-line:variable-name
  public sales_billing_city_id_payload = [
        {id: 'sales_billing_state_id', value: '-1'},
      ];    
        
      
      
      
        public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {
       
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {
                                                                                                                                                                                                                              }

  public payloadsChanged() {
                                                               this.sales_shipping_state_id_payload = [
          {id: 'sales_shipping_country_id', value: this.sale.sales_shipping_country_id},
         ];
                         this.sales_shipping_city_id_payload = [
          {id: 'sales_shipping_state_id', value: this.sale.sales_shipping_state_id},
         ];
                                                    this.sales_billing_state_id_payload = [
          {id: 'sales_billing_country_id', value: this.sale.sales_billing_country_id},
         ];
                         this.sales_billing_city_id_payload = [
          {id: 'sales_billing_state_id', value: this.sale.sales_billing_state_id},
         ];
                             }

  public init() {
    this.sales = [];
    this.reset();
    this.getAllSales();
  }

  public reset() {
    this.sale = {
              sales_id: '-1',
                   sales_email: '',
                   sales_name: '',
                   sales_last_name: '',
                   sales_phone: '',
                   sales_shipping_address1: '',
                   sales_shipping_address2: '',
                   sales_shipping_country_id: '-1',
                   sales_shipping_state_id: '-1',
                   sales_shipping_city_id: '-1',
                   sales_shipping_zip_code: '',
                   sales_billing_address1: '',
                   sales_billing_address2: '',
                   sales_billing_country_id: '-1',
                   sales_billing_state_id: '-1',
                   sales_billing_city_id: '-1',
                   sales_billing_zip_code: '',
                   sales_completed: '',
                   sales_active: 'Y',
                   sales_log: '',
              };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

                                                                                                              
                                  public onShippingCountryIdChanged(obj) {
         this.sale.sales_shipping_state_id = '-1';
         this.sale.sales_shipping_city_id = '-1';
         this.payloadsChanged();
  }
        public onShippingStateIdChanged(obj) {
         this.sale.sales_shipping_city_id = '-1';
         this.payloadsChanged();
  }
        public onShippingCityIdChanged(obj) {
         this.payloadsChanged();
  }
                    public onBillingCountryIdChanged(obj) {
         this.sale.sales_billing_state_id = '-1';
         this.sale.sales_billing_city_id = '-1';
         this.payloadsChanged();
  }
        public onBillingStateIdChanged(obj) {
         this.sale.sales_billing_city_id = '-1';
         this.payloadsChanged();
  }
        public onBillingCityIdChanged(obj) {
         this.payloadsChanged();
  }
                      
                                                                                            
  public getAllSales() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
                                                                                                                  search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('sales/get_all_sales', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
                this.sales = this.sales.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.sales.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllSales();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.sale));
    this.app.getQuery('sales/add_edit_sale', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModal').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
                                            this.payloadsChanged();    $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
        this.sale = tempItem;
                                           
        this.payloadsChanged();
                                                                                        $('#myModal').modal('show');
  }

                                                                                    

  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



