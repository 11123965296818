import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;
declare var videojs: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy, AfterViewInit {


  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public visits = [];

  public visit: any = {
    vi_id: '-1',
    vi_cplx_id: '-1',
    vi_home_id: '-1',
    vi_res_id: '-1',
    vi_av_id: '-1',
    vi_name: '',
    vi_last_name: '',
    vi_vehicle: '',
    vi_color: '',
    vi_plate: '',
    vi_approved_by: '',
    vi_photo_1: '',
    vi_photo_2: '',
    vi_auth_id: '',
    vi_active: 'Y',
    vi_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'vi_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  public cplxType = '';
  // tslint:disable-next-line:variable-name
  public vi_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public vi_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_home_id_payload_search = [
    { id: 'vi_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public vi_res_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_res_id_payload_search = [
    { id: 'vi_home_id', value: '-1' },
  ];




  // tslint:disable-next-line:variable-name
  public vi_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public vi_home_id_payload = [
    { id: 'vi_cplx_id', value: '-1' },
  ];

  // tslint:disable-next-line:variable-name
  public vi_building_id_payload = [
    { id: 'vi_cplx_id', value: '-1' },
  ];

  // tslint:disable-next-line:variable-name
  public vi_off_id_payload = [
    { id: 'vi_building_id', value: '-1' },
  ];

  // tslint:disable-next-line:variable-name
  public vi_emp_id_payload = [
    { id: 'vi_off_id', value: '-1' },
  ];

  // tslint:disable-next-line:variable-name
  public vi_res_id_payload = [
    { id: 'vi_home_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vi_av_id_payload = [
  ];











  public message = '';
  public logs = [];
  public browse = 'Browse';
  // private canvas: any;
  private context: CanvasRenderingContext2D;
  video: HTMLVideoElement;
  ratio: number;
  h: number;
  w: number;
  canvas: HTMLCanvasElement;
  public snapImg1 = '';
  playerReady = 'N';
  ngOnInit() {
   
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    console.log('loaded');
    this.playerReady = 'Y';
    setTimeout(() => {
      const oldPlayer = document.querySelector('video');
      videojs(oldPlayer).dispose();
      this.playerReady = 'N';
    }, 1000 * 1);
    setTimeout(() => {
      this.playerReady = 'Y';
    }, 1000 * 2);
    setTimeout(() => {
      // const oldPlayer = document.querySelector('video');
      // videojs(oldPlayer).dispose();
     //  this.playerReady = 'Y';
      this.video = document.querySelector('video');
      // this.video.pause();
      // this.video.currentTime = 0;
      this.canvas = document.querySelector('canvas');
      this.context = this.canvas.getContext('2d');
      this.w = 0; this.h = 0; this.ratio = 0;

      // add loadedmetadata which will helps to identify video attributes

      this.video.addEventListener('loadedmetadata', () => {
        this.ratio = this.video.videoWidth / this.video.videoHeight;
        this.w = this.video.videoWidth - 100;
        this.h = this.w / this.ratio;
        this.canvas.width = this.w;
        this.canvas.height = this.h;
      }, false);

      const player = videojs('vid1');
      videojs(player).dispose();
      player.pause().currentTime(0).trigger('loadstart');
      player.play();
    }, 1000 * 3);


    this.init();
  }

  public takeSnap1(fromSubmit = false) {
    this.context.fillRect(0, 0, this.w, this.h);
    this.context.drawImage(this.video, 0, 0, this.w, this.h);
    const d = this.canvas.toDataURL();
    console.log(d);
    this.generatePhotoUrl(d, 'vi_photo_1', fromSubmit);
  }
  private generatePhotoUrl(base64, key: string, fromSubmit) {
    this.browse = 'Processing..';
    this.app.getQuery('clients/get_photo_save', { photo: base64 }).then(result => {
      console.log(result);
      this.browse = 'Browse';
      if (result.status === 'OK') {
        this.snapImg1 = result.file_name;
        this.visit[key] = result.file_name;
        if (fromSubmit) {
          this.onSubmit();
        }
        // this.mPhotoSrc = result.file_name;
        // this.onChange(this.mPhotoSrc);
        // console.log
      }
    });
  }

  public searchPayloadsChanged() {

    this.vi_home_id_payload_search = [
      { id: 'vi_cplx_id', value: this.vi_cplx_id_search },
    ];

    this.vi_res_id_payload_search = [
      { id: 'vi_home_id', value: this.vi_home_id_search },
    ];

  }

  public payloadsChanged() {

    this.vi_home_id_payload = [
      { id: 'vi_cplx_id', value: this.visit.vi_cplx_id },
    ];

    this.vi_res_id_payload = [
      { id: 'vi_home_id', value: this.visit.vi_home_id },
    ];

    this.vi_building_id_payload = [
      { id: 'vi_cplx_id', value: this.visit.vi_cplx_id },
    ];

    this.vi_off_id_payload = [
      { id: 'vi_building_id', value: this.visit.vi_building_id },
    ];

    // this.vi_emp_id_payload = [
    //   { id: 'vi_off_id', value: this.visit.vi_off_id },
    // ];
    // this.onChangePage();
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.visits = [];
    this.getAllVisits();

  }

  public init() {
    this.visits = [];
    this.reset();
    this.getAllVisits();
  }

  public reset() {
    this.visit = {
      vi_id: '-1',
      vi_cplx_id: '-1',
      vi_home_id: '-1',
      vi_res_id: '-1',
      vi_av_id: '-1',
      vi_name: '',
      vi_last_name: '',
      vi_vehicle: '',
      vi_color: '',
      vi_plate: '',
      vi_approved_by: '',
      vi_photo_1: '',
      vi_photo_2: '',
      vi_auth_id: '',
      vi_active: 'Y',
      vi_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.vi_home_id_search = '-1';
    this.vi_res_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.vi_res_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onResIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    console.log(obj);
    this.visit.vi_home_id = '-1';
    this.visit.vi_res_id = '-1';
    this.visit.vi_building_id = '-1';
    this.visit.vi_off_id = '-1';
    // this.visit.vi_em


    this.app.getQuery('visits/get_cplx_type', { vi_cplx_id: obj }).then(result => {
      if (result.status === 'OK') {
        console.log(result);
        this.payloadsChanged();
        this.cplxType = result.data[0].cplx_type;
      }
    });
  }
  public onHomeIdChanged(obj) {
    this.visit.vi_res_id = '-1';
    this.payloadsChanged();
  }
  public onResIdChanged(obj) {
    this.payloadsChanged();
  }
  public onAvIdChanged(obj) {
    this.payloadsChanged();
  }

  public getAllVisits() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      vi_cplx_id_search: this.visit.vi_cplx_id,


      vi_home_id_search: this.visit.vi_home_id,


      vi_res_id_search: '-1',

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('visits/get_all_visits', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.visits = this.visits.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.visits.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.cplxType = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllVisits();
  }

  public onSubmit() {
    if (this.visit.vi_photo_1 !== '') {
      this.loadingText = 'Saving...';
      const itemObj = JSON.parse(JSON.stringify(this.visit));
      itemObj.vi_id = '-1';
      this.app.getQuery('visits/add_edit_visit', itemObj).then(result => {
        this.loadingText = 'Save';
        console.log(result);
        if (result.status === 'OK') {
          $('#myModal').modal('hide');
          this.onClear();
        } else {
          this.message = result.message;
          setTimeout(() => {
            this.message = '';
          }, 4000);
        }
      });
    } else {
      this.takeSnap1(true);
    }
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.visit = tempItem;
    this.app.getQuery('visits/get_cplx_type', { vi_cplx_id: this.visit.vi_cplx_id }).then(result => {
      if (result.status === 'OK') {
        console.log(result);
        this.payloadsChanged();
        this.cplxType = result.data[0].cplx_type;
      }
    });
    // this.payloadsChanged();
    // $('#myModal').modal('show');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



