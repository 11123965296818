
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import { AddlogService } from '../Services/addlog.service';
import { CommentStmt } from '@angular/compiler';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-complexes',
  templateUrl: './complexes.component.html',
  styleUrls: ['./complexes.component.css']
})
export class ComplexesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public complexes = [];

  public complex: any = {
    cplx_id: '-1',
    cplx_name: '',
    cplx_logo: '',
    cplx_layout: '',
    cplx_contact_name: '',
    cplx_cust_id: '-1',
    cplx_address: '',
    cplx_zip: '',
    cplx_phone1: '',
    cplx_phone2: '',
    cplx_type: 'Gated Community',
    cplx_camera1: '',
    cplx_camera2: '',
    cplx_camera1_apikey: '',
    cplx_camera2_apikey: '',
    cplx_admin_phone: '',
    cplx_gate_phone: '',
    cplx_max_authorized_users: '-1',
    cplx_gate_house_num: '',
    cplx_is_admin_office: 'N',
    cplx_stripe_api: '-1',
    cplx_mon_open_time: '',
    cplx_mon_close_time: '',
    cplx_mon_closed: 'N',
    cplx_tue_open_time: '',
    cplx_tue_close_time: '',
    cplx_tue_closed: 'N',
    cplx_wed_open_time: '',
    cplx_wed_close_time: '',
    cplx_wed_closed: 'N',
    cplx_thr_open_time: '',
    cplx_thr_close_time: '',
    cplx_thr_closed: 'N',
    cplx_fri_open_time: '',
    cplx_fri_close_time: '',
    cplx_fri_closed: 'N',
    cplx_sat_open_time: '',
    cplx_sat_close_time: '',
    cplx_sat_closed: 'N',
    cplx_sun_open_time: '',
    cplx_sun_close_time: '',
    cplx_sun_closed: 'N',
    cplx_admin_office_phone: '',
    cplx_admin_office_email: '',
    cplx_active: 'Y',
    cplx_timezone: 'America/Puerto_Rico',
    cplx_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'cplx_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  public user_level = localStorage.getItem('user_level');
  public user_name = localStorage.getItem('user_name');
  private oldActiveStatus: string = ""








  // tslint:disable-next-line:variable-name
  public cplx_cust_id_payload = [
  ];









  public message = '';
  public logs = [];
  public browse = 'Browse';
  public login: any = { id: '-1' };


  constructor(public app: AppService, private router: Router, private location: Location, private addLogService: AddlogService) {
    this.login = this.app.getLogin();
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {
  }

  public payloadsChanged() {
  }

  public init() {
    this.complexes = [];
    this.reset();
    this.getAllComplexes();
  }

  public reset() {
    this.complex = {
      cplx_id: '-1',
      cplx_name: '',
      cplx_logo: '',
      cplx_layout: '',
      cplx_contact_name: '',
      cplx_cust_id: '-1',
      cplx_address: '',
      cplx_zip: '',
      cplx_phone1: '',
      cplx_phone2: '',
      cplx_type: 'Gated Community',
      cplx_camera1: '',
      cplx_camera2: '',
      cplx_camera1_apikey: '',
      cplx_camera2_apikey: '',
      cplx_max_authorized_users: '-1',
      cplx_gate_house_num: '',
      cplx_stripe_api: '-1',
      cplx_is_admin_office: 'N',
      cplx_mon_open_time: '',
      cplx_mon_close_time: '',
      cplx_mon_closed: 'N',
      cplx_tue_open_time: '',
      cplx_tue_close_time: '',
      cplx_tue_closed: 'N',
      cplx_wed_open_time: '',
      cplx_wed_close_time: '',
      cplx_wed_closed: 'N',
      cplx_thr_open_time: '',
      cplx_thr_close_time: '',
      cplx_thr_closed: 'N',
      cplx_fri_open_time: '',
      cplx_fri_close_time: '',
      cplx_fri_closed: 'N',
      cplx_sat_open_time: '',
      cplx_sat_close_time: '',
      cplx_sat_closed: 'N',
      cplx_sun_open_time: '',
      cplx_sun_close_time: '',
      cplx_sun_closed: 'N',
      cplx_admin_office_phone: '',
      cplx_admin_office_email: '',
      cplx_admin_phone: '',
      cplx_gate_phone: '',
      cplx_timezone: 'America/Puerto_Rico',
      cplx_active: 'Y',
      cplx_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }


  public onCustIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllComplexes() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('complexes/get_all_complexes', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.complexes = this.complexes.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.complexes.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllComplexes();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const newActiveStatus = this.complex.cplx_active; // store the old status
    const itemObj = JSON.parse(JSON.stringify(this.complex));




    this.app.getQuery('complexes/add_edit_complex', itemObj).then(result => {
      this.loadingText = 'Save';

      console.log('Result:', result);

      if (result.status === 'OK') {
        $('#myModal').modal('hide');

        // Handle activation/deactivation logging
        if (this.oldActiveStatus !== newActiveStatus) {
         
          // const statusChange = newActiveStatus ? 'activated' : 'deactivated';
          // const logMessage = `User ${this.user_name} (${this.user_level}) has ${statusChange} the complex "${this.complex.cplx_name}". Previous status: "${this.oldActiveStatus}", New status: "${newActiveStatus}".`;

          this.addLogService.addLogs(this.user_name, this.user_level, `User ${this.user_name} has changed the active status complex ${itemObj.cplx_name} from ${this.oldActiveStatus} to ${newActiveStatus}`)
            .then(res => {
              console.log('Log successfully added:', res);
            })
            .catch(e => {
              console.log('Error adding log:', e);
            });
        }


        if (result.message === "Complex entry updated") {
          console.log('Complex updated');

          this.addLogService.addLogs(this.user_name, this.user_level, `User "${this.user_name}" updated an entry for complex named "${itemObj.cplx_name}"`)
            .then(res => {
              console.log('Update log added:', res);
            })
            .catch(e => {
              console.error('Error adding update log:', e);
            });

        } else {
          console.log('Complex created');
          this.addLogService.addLogs(this.user_name, this.user_level, `User "${this.user_name}" created a complex named "${itemObj.cplx_name}"`)
            .then(res => {
              console.log('Creation log added:', res);
            })
            .catch(e => {
              console.error('Error adding creation log:', e);
            });
        }

        this.onClear();

      } else {
        // Handle error
        this.message = result.message;
        console.error('Error:', this.message);
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    }).catch(error => {
      console.error('Error during API call:', error);
      this.loadingText = 'Save';  // Ensure the UI resets
      this.message = 'An error occurred. Please try again.';
      setTimeout(() => {
        this.message = '';
      }, 4000);
    });
  }






  public add() {
    this.reset();
    $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.complex = tempItem;
    this.oldActiveStatus = this.complex.cplx_active

    $('#myModal').modal('show');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



