
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public loaded = 'N';
  public tickets = [];
  public isClosed = 'open';
  public isAdmin = localStorage.getItem('user_level');

  public ticket: any = {
    ticket_id: '-1',
    ticket_title: '',
    ticket_body: '',
    ticket_cplx_id: '-1',
    ticket_home_id: '-1',
    ticket_res_id: '-1',
    ticket_cu_id: '-1',
    ticket_photo: '',
    ticket_status: 'open',
    ticket_open_datetime: '',
    ticket_close_datetime: '',
    ticket_active: 'Y',
    ticket_log: '',
  };

  public ticketConversations = [];

  public ticketConversation: any = {
    ticketconversation_id: '-1',
    ticketconversation_ticket_id: '-1',
    ticketconversation_text: '',
    ticketconversation_res_id: '-1',
    ticketconversation_cu_id: '-1',
    ticketconversation_by: 'admin',
    ticketconversation_active: 'Y',
    ticketconversation_log: '',
  };
  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'ticket_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public ticket_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public ticket_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public ticket_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public ticket_home_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public ticket_res_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public ticket_res_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public ticket_cu_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public ticket_cu_id_payload_search = [
  ];






  // tslint:disable-next-line:variable-name
  public ticket_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public ticket_home_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public ticket_res_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public ticket_cu_id_payload = [
  ];






  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {




  }

  public payloadsChanged() {
  }

  public init() {
    this.tickets = [];
    this.reset();
    this.getAllTickets();
  }

  public reset() {
    this.ticket = {
      ticket_id: '-1',
      ticket_title: '',
      ticket_body: '',
      ticket_cplx_id: '-1',
      ticket_home_id: '-1',
      ticket_res_id: '-1',
      ticket_cu_id: '-1',
      ticket_photo: '',
      ticket_status: 'open',
      ticket_open_datetime: '',
      ticket_close_datetime: '',
      ticket_active: 'Y',
      ticket_log: '',
    };
    this.reset_conv();
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onResIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onCuIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.payloadsChanged();
  }
  public onResIdChanged(obj) {
    this.payloadsChanged();
  }
  public onCuIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllTickets() {
    this.loaded = 'N';
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      ticket_cplx_id_search: this.ticket_cplx_id_search,


      ticket_home_id_search: this.ticket_home_id_search,


      ticket_res_id_search: this.ticket_res_id_search,


      ticket_cu_id_search: this.ticket_cu_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('tickets/get_all_tickets', obj).then(result => {

      console.log(result);
      this.loaded = 'Y';
      if (result.status === 'OK') {
        this.tickets = this.tickets.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.tickets.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllTickets();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.ticket));
    this.app.getQuery('tickets/add_edit_ticket', itemObj).then(result => {
      // this.loadingText = 'Save';
      console.log(result);
      this.onSubmitConverstation();
      // if (result.status === 'OK') {
      //   $('#myModalticket_id').modal('hide');
      //   this.onClear();
      // } else {
      //   this.message = result.message;
      //   setTimeout(() => {
      //     this.message = '';
      //   }, 4000);
      // }
    });
  }



  public add() {
    this.reset();
    $('#myModalticket_id').modal('show');
    this.ticketConversations = [];
    // $('#myModalticket_id').appendTo('body');
  }

  public editItem(itemObj) {
    this.ticketConversations = [];
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.isClosed = tempItem.ticket_status;
    this.ticket = tempItem;
    this.getAllTicketConversations();
    $('#myModalticket_id').modal('show');
    // $('#myModalticket_id').appendTo('body');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }

  public reset_conv() {
    this.ticketConversation = {
      ticketconversation_id: '-1',
      ticketconversation_ticket_id: this.ticket.ticket_id,
      ticketconversation_text: '',
      ticketconversation_res_id: this.ticket.ticket_res_id,
      ticketconversation_cu_id: '-1',
      ticketconversation_by: 'admin',
      ticketconversation_active: 'Y',
      ticketconversation_log: '',
    };
    // this.baseForm.form.markAsPristine();
    // this.baseForm.form.markAsUntouched();
    // this.baseForm.form.updateValueAndValidity();
  }

  public getAllTicketConversations() {
    this.loaded = 'N';
    const obj = {
      num_per_page: 100,
      order_by: 'ticketconversation_log',
      order: 'ASC',
      start_from: 0,

      ticketconversation_ticket_id_search: this.ticket.ticket_id,


      ticketconversation_res_id_search: '-1',


      ticketconversation_cu_id_search: '-1',

      search: '',
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('ticketconversations/get_all_ticket_conversations', obj).then(result => {

      console.log(result);
      this.loaded = 'Y';
      if (result.status === 'OK') {
        this.ticketConversations = result.data;
        // this.count = result.total;
        // this.received = result.received;
        // this.dbPages = [];
        // // this.activePage = 1;
        // for (let index = 0; index < result.pages; index++) {
        //   this.dbPages.push(index);
        // }
        // this.displayCount = (this.ticketConversations.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSubmitConverstation() {
    // this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.ticketConversation));
    itemObj.ticketconversation_ticket_id = this.ticket.ticket_id;
    itemObj.ticketconversation_res_id = this.ticket.ticket_res_id;
    this.app.getQuery('ticketconversations/add_edit_ticketconversation', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModalticket_id').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
      // if (result.status === 'OK') {
      //   $('#myModalticketconversation_id').modal('hide');
      //   this.onClear();
      // } else {
      //   this.message = result.message;
      //   setTimeout(() => {
      //     this.message = '';
      //   }, 4000);
      // }
    });
  }


}



