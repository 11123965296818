
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-ws-logs',
  templateUrl: './ws-logs.component.html',
  styleUrls: ['./ws-logs.component.css']
})
export class WsLogsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public loaded = 'N';
  public wsLogs = [];

  public wsLog: any = {
    wsl_id: '-1',
    wsl_name: '',
    wsl_lastname: '',
    wsl_ws_id: '-1',
    wsl_cplx_id : '-1',
    wsl_note: '',
    wsl_photo1: '',
    wsl_photo2: '',
    wsl_active: 'Y',
    wsl_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '50';
  // tslint:disable-next-line:variable-name
  public order_by = 'wsl_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public wsl_ws_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public wsl_ws_id_payload_search = [
  ];






  // tslint:disable-next-line:variable-name
  public wsl_ws_id_payload = [
  ];





  public message = '';
  public logs = [];
  public browse = 'Browse';
  public wsses = [];



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

  }

  public payloadsChanged() {
  }

  public init() {
    this.wsLogs = [];
    this.reset();
    this.getAllWsLogs();
    this.getWsses();
  }

  private getWsses(){
    this.app.getQuery('wslogs/get_all_web_services_for_search',{}).then(result => {
      if (result.status === 'OK'){
        console.log(result);
        this.wsses = result.data;
      }
    });
  }

  public reset() {
    this.wsLog = {
      wsl_id: '-1',
      wsl_name: '',
      wsl_lastname: '',
      wsl_ws_id: '-1',
      wsl_cplx_id : '-1',
      wsl_note: '',
      wsl_photo1: '',
      wsl_photo2: '',
      wsl_active: 'Y',
      wsl_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onWsIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onWsIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllWsLogs() {
    this.loaded = 'N';
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      wsl_ws_id_search: this.wsl_ws_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('wslogs/get_all_ws_logs', obj).then(result => {

      console.log(result);
      this.loaded = 'Y';
      if (result.status === 'OK') {
        this.wsLogs = this.wsLogs.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.wsLogs.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllWsLogs();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.wsLog));
    this.app.getQuery('wslogs/add_edit_wslog', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModalwsl_id').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add(ws:any) {
    this.reset();
    setTimeout(() => {
      this.wsLog.wsl_cplx_id = ws.ws_cplx_id;
      this.wsLog.wsl_ws_id = ws.ws_id;
    }, 200);
    $('#myModalwsl_id').modal('show');
    // $('#myModalwsl_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.wsLog = tempItem;

    $('#myModalwsl_id').modal('show');
    // $('#myModalwsl_id').appendTo('body');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



