
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-web-registrations',
  templateUrl: './web-registrations.component.html',
  styleUrls: ['./web-registrations.component.css']
})
export class WebRegistrationsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public loaded = 'N';
  public webRegistrations = [];

  public webRegistration: any = {
         webregistration_id: '-1',
                   webregistration_name: '',
                            webregistration_lastname: '',
                            webregistration_cplx_id: '-1',
                   webregistration_building_id: '-1',
                   webregistration_home_id: '-1',
                   webregistration_phone: '',
                            webregistration_email: '',
                            webregistration_status: '',
                            webregistration_active: 'Y',
                   webregistration_log: '',
                   };

  
  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'webregistration_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
                    // tslint:disable-next-line:variable-name
  public webregistration_cplx_id_search = '-1';
    
  // tslint:disable-next-line:variable-name
  public webregistration_cplx_id_payload_search = [
      ];
              // tslint:disable-next-line:variable-name
  public webregistration_building_id_search = '-1';
    
  // tslint:disable-next-line:variable-name
  public webregistration_building_id_payload_search = [
        {id: 'webregistration_cplx_id', value: '-1'},
      ];
              // tslint:disable-next-line:variable-name
  public webregistration_home_id_search = '-1';
    
  // tslint:disable-next-line:variable-name
  public webregistration_home_id_payload_search = [
        {id: 'webregistration_cplx_id', value: '-1'},
        {id: 'webregistration_building_id', value: '-1'},
      ];
                                  
    
      
      
      
       
  // tslint:disable-next-line:variable-name
  public webregistration_cplx_id_payload = [
      ];    
        
       
  // tslint:disable-next-line:variable-name
  public webregistration_building_id_payload = [
        {id: 'webregistration_cplx_id', value: '-1'},
      ];    
        
       
  // tslint:disable-next-line:variable-name
  public webregistration_home_id_payload = [
        {id: 'webregistration_cplx_id', value: '-1'},
        {id: 'webregistration_building_id', value: '-1'},
      ];    
        
      
      
      
      
        public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {
       
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {
                                                     
                                        this.webregistration_building_id_payload_search = [
              {id: 'webregistration_cplx_id', value: this.webregistration_cplx_id_search},
             ];
           
                                        this.webregistration_home_id_payload_search = [
              {id: 'webregistration_cplx_id', value: this.webregistration_cplx_id_search},
             {id: 'webregistration_building_id', value: this.webregistration_building_id_search},
             ];
           
                                                              }

  public payloadsChanged() {
                                               this.webregistration_building_id_payload = [
          {id: 'webregistration_cplx_id', value: this.webRegistration.webregistration_cplx_id},
         ];
                         this.webregistration_home_id_payload = [
          {id: 'webregistration_cplx_id', value: this.webRegistration.webregistration_cplx_id},
         {id: 'webregistration_building_id', value: this.webRegistration.webregistration_building_id},
         ];
                                 }

  public init() {
    this.webRegistrations = [];
    this.reset();
    this.getAllWebRegistrations();
  }

  public reset() {
    this.webRegistration = {
              webregistration_id: '-1',
                   webregistration_name: '',
                            webregistration_lastname: '',
                            webregistration_cplx_id: '-1',
                   webregistration_building_id: '-1',
                   webregistration_home_id: '-1',
                   webregistration_phone: '',
                            webregistration_email: '',
                            webregistration_status: '',
                            webregistration_active: 'Y',
                   webregistration_log: '',
                       };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

                    public onCplxIdChangedOnSearch(obj) {
              this.webregistration_building_id_search = '-1';
                   this.webregistration_home_id_search = '-1';
             this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
            public onBuildingIdChangedOnSearch(obj) {
              this.webregistration_home_id_search = '-1';
             this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
            public onHomeIdChangedOnSearch(obj) {
        this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
                              
                  public onCplxIdChanged(obj) {
             this.webRegistration.webregistration_building_id = '-1';
                  this.webRegistration.webregistration_home_id = '-1';
              this.payloadsChanged();
  }
        public onBuildingIdChanged(obj) {
             this.webRegistration.webregistration_home_id = '-1';
              this.payloadsChanged();
  }
        public onHomeIdChanged(obj) {
         this.payloadsChanged();
  }
                          
                                                      
  public getAllWebRegistrations() {
    this.loaded = 'N';
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
                
        webregistration_cplx_id_search: this.webregistration_cplx_id_search,
  
          
        webregistration_building_id_search: this.webregistration_building_id_search,
  
          
        webregistration_home_id_search: this.webregistration_home_id_search,
  
                                    search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('webregistrations/get_all_web_registrations', obj).then(result => {

      console.log(result);
      this.loaded = 'Y';
      if (result.status === 'OK') {
                this.webRegistrations = this.webRegistrations.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.webRegistrations.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllWebRegistrations();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.webRegistration));
    this.app.getQuery('webregistrations/add_edit_webregistration', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModalwebregistration_id').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
                            this.payloadsChanged();    $('#myModalwebregistration_id').modal('show');
    // $('#myModalwebregistration_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
        this.webRegistration = tempItem;
                           
        this.payloadsChanged();
                                                    $('#myModalwebregistration_id').modal('show');
    // $('#myModalwebregistration_id').appendTo('body');
  }

                                                

  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



