
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import { AddlogService } from '../Services/addlog.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.css']
})
export class BannersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public loaded = 'N';
  public banners = [];
  public isAdmin = localStorage.getItem('user_level');
  public user_name = localStorage.getItem('user_name');
  public bannerOldStatus:string;
  public banner_oldName:string;

  public banner: any = {
    banner_id: '-1',
    banner_name: '',
    banner_photo: '',
    banner_linkorphone: 'link',
    banner_link: '',
    banner_phone: '',
    banners_complexes_map: [],
    banners_expiration_date: '',
    banner_active: 'Y',
    banner_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'banner_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';









  // tslint:disable-next-line:variable-name
  public banners_complexes_map_payload = [
  ];


  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location, private addLog:AddlogService) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {
  }

  public payloadsChanged() {
  }

  public init() {
    this.banners = [];
    this.reset();
    this.getAllBanners();
  }

  public reset() {
    this.banner = {
      banner_id: '-1',
      banner_name: '',
      banner_photo: '',
      banner_linkorphone: 'link',
      banner_link: '',
      banner_phone: '',
      banners_complexes_map: [],
      banners_expiration_date: '',
      banner_active: 'Y',
      banner_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }




  public getAllBanners() {
    this.loaded = 'N';
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('banners/get_all_banners', obj).then(result => {

      console.log(result);
      this.loaded = 'Y';
      if (result.status === 'OK') {
        this.banners = this.banners.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.banners.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllBanners();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.banner));
    const currentTimestamp = moment().format('YYYY-MM-DD HH:mm:ss'); // Get current timestamp

    this.app.getQuery('banners/add_edit_banner', itemObj).then(result => {
        this.loadingText = 'Save';
        console.log(result);

        if (result.status === 'OK') {
            $('#myModalbanner_id').modal('hide');
            console.log(result.message);

            // Check if banner was turned off
            if (itemObj.banner_active === 'N' && this.bannerOldStatus !== 'N') {
                const logMessage = `User "${this.user_name}" turned off banner "${itemObj.banner_name}" at ${currentTimestamp}`;
                this.addLog.addLogs(this.user_name, this.isAdmin, logMessage).then(result => {
                    console.log("Banner deactivation log added");
                }).catch(err => {
                    console.error("Error logging banner deactivation:", err);
                });
            }
            else if (itemObj.banner_name !== this.banner_oldName) {
              const logMessage = `User "${this.user_name}" changed banner name from "${this.banner_oldName}" to "${itemObj.banner_name}" at ${currentTimestamp}`;
              this.addLog.addLogs(this.user_name, this.isAdmin, logMessage).then(result => {
                  console.log("Banner name change log added");
              }).catch(err => {
                  console.error("Error logging banner name change:", err);
              });
            }
            
            // Check if banner was updated
            else if (result.message === "Banner entry updated") {
                this.addLog.addLogs(this.user_name, this.isAdmin, `User "${this.user_name}" updated entry Banner named "${itemObj.banner_name}"`).then(result => {
                    console.log("Banner update log added");
                }).catch(err => {
                    console.error("Error logging banner update:", err);
                });
            }
            
            // Check if banner was created
            else if (result.message === "Banner entry created") {
                this.addLog.addLogs(this.user_name, this.isAdmin, `User "${this.user_name}" created a Banner named "${itemObj.banner_name}"`).then(result => {
                    console.log("Banner creation log added");
                }).catch(err => {
                    console.error("Error logging banner creation:", err);
                });
            }

            this.onClear();
        } else {
            this.message = result.message;
            setTimeout(() => {
                this.message = '';
            }, 4000);
        }
    }).catch(err => {
        console.error("Error during banner save:", err);
    });
}




  public add() {
    this.reset();
    $('#myModalbanner_id').modal('show');
    // $('#myModalbanner_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.banner = tempItem;
    this.bannerOldStatus = tempItem.banner_active;
    this.banner_oldName = tempItem.banner_name;

    this.get_selected_banners_complexes_map();
    $('#myModalbanner_id').modal('show');
    // $('#myModalbanner_id').appendTo('body');
  }

  private get_selected_banners_complexes_map() {
    this.app.getQuery('banners/get_selected_complexes', { id: this.banner.banner_id }).then(result => {
      console.log(result);
      if (result.status === 'OK') {
        this.banner.banners_complexes_map = result.data;
      }
    });
  }


  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



