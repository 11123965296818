import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';


@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  user: {
    email: string;
    password: string;
    confirmPassword: string;
  } = { email: '', password: '', confirmPassword: '' };

  public message = '';

  public resident: any = {
    res_id: '-1',
    res_name: '',
    res_last_name: '',
    res_email: '',
    res_password: '',
    res_cplx_id: '-1',
    res_building_id: '-1',
    res_home_id: '-1',
    res_phone: '',
    res_type: 'owner',
    res_mobile: '',
    res_mms_id: '-1',
    res_start_living_on: '',
    res_stop_living_on: '',
    res_showon_register_screen: 'Y',
    res_active: 'Y',
    res_log: '',
  };

  constructor(public app: AppService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // Fetch resident_id from URL and load user data (e.g., email) from backend
      const residentId = params.resident_id;
      // Fetch user data using residentId and populate the user object
      // this.user.email = 'example@email.com'; // Replace with actual email
      this.getResident(residentId);
    });
  }

  getResident(residentId) {
    this.app.getQuery('emailcontroller/fetch_resident', {res_id: residentId}).then(result => {
      if (result.status === 'OK') {
        if (result.data.length > 0) {
          this.resident = result.data[0];
          this.resident.res_password = '';
          this.resident.res_password_confirm = '';
        }
      }
    });
  }

  resetPassword(): void {
    // Implement password reset logic here, including validation and API calls
    if (this.resident.res_password.length < 6) {
      alert('Password should be minimum 6 letters');
    } else if (this.resident.res_password !== this.resident.res_password_confirm) {
      alert('Password does not match');
    } else {
      this.app.getQuery('emailcontroller/finish', this.resident); {
        this.message = 'Password updated';
        setTimeout(() => {
          this.message = '';
          this.getResident(this.resident.res_id);

        }, 1000);
      }
    }
  }
}
