
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
// import {IDropdownSettings} from 'ng-multiselect-dropdown'

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-web-services',
  templateUrl: './web-services.component.html',
  styleUrls: ['./web-services.component.css']
})
export class WebServicesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public loaded = 'N';
  public webServices = [];
  complexHashMap: { [key: string]: string } = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};


  public webServic: any = {
    ws_id: '-1',
    ws_name: '',
    ws_cplx_id: '-1',
    ws_icon: '',
    ws_active: 'Y',
    ws_log: '',
    ws_cplx_ids: []
  };
  public selectedValueID = new Set<string>();

  public complex: any = {
    cplx_id: '-1',
    cplx_name: '',
    cplx_logo: '',
    cplx_layout: '',
    cplx_contact_name: '',
    cplx_cust_id: '-1',
    cplx_address: '',
    cplx_zip: '',
    cplx_phone1: '',
    cplx_phone2: '',
    cplx_type: 'Gated Community',
    cplx_camera1: '',
    cplx_camera2: '',
    cplx_camera1_apikey: '',
    cplx_camera2_apikey: '',
    cplx_admin_phone: '',
    cplx_gate_phone: '',
    cplx_max_authorized_users: '-1',
    cplx_gate_house_num: '',
    cplx_is_admin_office: 'N',
    cplx_stripe_api: '-1',
    cplx_mon_open_time: '',
    cplx_mon_close_time: '',
    cplx_mon_closed: 'N',
    cplx_tue_open_time: '',
    cplx_tue_close_time: '',
    cplx_tue_closed: 'N',
    cplx_wed_open_time: '',
    cplx_wed_close_time: '',
    cplx_wed_closed: 'N',
    cplx_thr_open_time: '',
    cplx_thr_close_time: '',
    cplx_thr_closed: 'N',
    cplx_fri_open_time: '',
    cplx_fri_close_time: '',
    cplx_fri_closed: 'N',
    cplx_sat_open_time: '',
    cplx_sat_close_time: '',
    cplx_sat_closed: 'N',
    cplx_sun_open_time: '',
    cplx_sun_close_time: '',
    cplx_sun_closed: 'N',
    cplx_admin_office_phone: '',
    cplx_admin_office_email: '',
    cplx_active: 'Y',
    cplx_timezone: 'America/Puerto_Rico',
    cplx_log: '',
  };



  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'ws_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public ws_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public ws_cplx_id_payload_search = [
  ];





  // tslint:disable-next-line:variable-name
  public ws_cplx_id_payload = [
  ];



  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {
    this.dropdownList = [
      { item_id: 1, item_text: 'Mumbai' },
      { item_id: 2, item_text: 'Bangaluru' },
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
      { item_id: 5, item_text: 'New Delhi' }
    ];
    this.selectedItems = [
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

  }

  public payloadsChanged() {
  }

  public init() {
    this.webServices = [];
    this.reset();
    this.getAllWebServices();
  }

  public reset() {
    this.webServic = {
      ws_id: '-1',
      ws_name: '',
      ws_cplx_id: '-1',
      ws_icon: '',
      ws_active: 'Y',
      ws_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllWebServices() {
    this.loaded = 'N';
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      ws_cplx_id_search: this.ws_cplx_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('webservices/get_all_web_services', obj).then(result => {

      console.log(result);
      this.loaded = 'Y';
      if (result.status === 'OK') {
        this.webServices = this.webServices.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.webServices.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllWebServices();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.webServic));
    this.app.getQuery('webservices/add_edit_webservic', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModalws_id').modal('hide');
        this.onClear();
      } else {
        
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }


  // private fetchEndusersSeclect2(initials = []) {
  //   //console.log(this.mUrl);
  //   //console.log(this.mPayloads);
  //   if (this.mUrl !== '') {
  //     console.log(this.mUrl);
  //     const selected = [];
  //     initials.forEach(element => {
  //       selected.push(element.id);
  //     });
  //     $('#' + this.cId).select2({
  //       data: initials,
  //       placeholder: ' ' + this.placeholder,
  //       ajax: {
  //         url: this.app.getSiteUrl() + this.mUrl,
  //         type: 'post',
  //         dataType: 'json',
  //         delay: 250,
  //         headers: {Authorization: this.app.getToken()},
  //         data: params => {
  //           const queryParameters = {
  //             term: params.term,
  //           };
            
            
  //           this.mPayloads.forEach(element => {
  //             console.log(queryParameters) 
  //             queryParameters[element.id] = element.value;
  //           });
  //           console.log(JSON.stringify(queryParameters));
  //           return queryParameters;
  //         },
  //         processResults: (data) => {
  //           console.log(data);
  //           const sel = { text: this.placeholder, id: '-1' };
  //           const sdata = [sel].concat(data.data);
  //           return {
  //             // tslint:disable-next-line:only-arrow-functions
  //             results: $.map(sdata, (item) => {
                
  //               return {
  //                 text: item.text,
  //                 id: item.id
  //               };
  //             })
  //           };
  //         },
  //         cache: true
  //       }




  //     });
  //     console.log(selected)

  //     $('#' + this.cId).val(selected).trigger('change');
  //     const selectedValue = $('#' + this.cId).val();
  //   }

 
  // }


  







  public add() {
    this.reset();
    $('#myModalws_id').modal('show');
    // $('#myModalws_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.webServic = tempItem;

    $('#myModalws_id').modal('show');
    // $('#myModalws_id').appendTo('body');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


  handleSelectChange(selectedValue: any) {
    console.log('Selected value:', selectedValue);
    this.selectedValueID.add(selectedValue)
    console.log(this.selectedValueID)
    // this.getAllComplexes();
    // Handle the selected value as needed
  }


  //TODO:FIx here i need to get the complexes name
   getAllComplexes() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('complexes/get_all_complexes', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        // this.complexes = this.complexes.concat(result.data);
        console.log(result.data)
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        // this.displayCount = (this.complexes.length) + ' of ' + this.count;
      } else {

      }
    });
  }


  // getBuildingsTEST(id: any, buildingID: any) {
  //   this.buildings = this.buildings || [];
  //   this.buildingsMap = this.buildingsMap || {};
  
  //   // Using arrow function to maintain `this` context
  //   this.app.getQuery('activitiescal/get_buildings', {
  //     cplx_id: id
  //   }).then((n) => {
  //     // console.log(n.data);
  //     if (n.status === 'OK') {
  //       // Extract building names from n.data
  //       // this.buildings = n.data.map((building: any) => building.building_name);
  
  //       // Print all building names for debugging
  //       // console.log("Building names:", this.buildings);
  
  //       // Filter buildings by buildingID if needed
  //       const selectedBuilding = n.data.find((building: any) => building.building_id === buildingID);
          
  //       if (selectedBuilding) {
  //         console.log(selectedBuilding);
  //         if (buildingID !== -1) {

  //         }
  //         // this.getAllActivities();
  
  //         // console.log("Selected building name:", selectedBuilding.building_name, " and buidling id: ", selectedBuilding.building_id);
  //         // Do something with selectedBuilding.name
  //       } else {
  //         // console.log("Building with ID", buildingID, "not found.");
  //       }
  //     } else {
  //       // console.log("Failed to fetch buildings.");
  //     }
  //   }).catch((error) => {
  //     // console.error("Error fetching buildings:", error);
  //   });
  // }
}



