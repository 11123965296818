import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class AddlogService {
  constructor(public app: AppService) { }

  // Service to add logs and call them in all the files
  public addLogs(name_of_user: string, user_level: string, what_changes: string): Promise<any> {
    const log = {
      log_datetime: new Date().toISOString(), // Current datetime
      log_usertype: `${user_level} - ${name_of_user}`, // Corresponds to user_level in your schema
      log_usertype_id: name_of_user, // The user's ID
      log_description: what_changes, // Describes what changes occurred
      log_active: 1, // Assuming log_active is required and default to true
      log_log: what_changes // Log mirrors what_changes
    };

    // Stringify the log object before sending it to the backend
    const logString = JSON.stringify(log);

    return this.app.getQuery('logs/add_edit_log', logString)
      .then(result => {
        if (result.status === 'OK') {
          console.log('Log added successfully');
          return result;
        } else {
          console.error('Failed to add log:', result.message);
          throw new Error(result.message);
        }
      })
      .catch(error => {
        console.error('An error occurred:', error);
        throw error;
      });
  }
}
