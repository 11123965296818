
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;
declare var videojs: any;

@Component({
  selector: 'app-registers',
  templateUrl: './registers.component.html',
  styleUrls: ['./registers.component.css']
})
export class RegistersComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(public app: AppService, private router: Router, private location: Location) {
    this.app.setRegisters(this);
  }

  @ViewChild('baseForm') baseForm: NgForm;
  // @ViewChild('vi_name') firstField: ElementRef; // Reference to the first input field


  public actfilter = true;
  public inactfilter = false;

  public registers = [];

  public register: any = {
    vi_id: '-1',
    vi_cplx_id: '-1',
    vi_building_id: '-1',
    vi_home_id: '-1',
    vi_off_id: '-1',
    vi_res_id: '-1',
    vi_emp_id: '-1',
    vi_av_id: '-1',
    vi_vehicle_id: '-1',
    vi_name: '',
    vi_last_name: '',
    vi_vehicle: '',
    vi_color: '',
    vi_plate: '',
    vi_approved_by: '',
    vi_photo_1: '',
    vi_photo_2: '',
    vi_auth_id: '-1',
    vi_active: 'Y',
    vi_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'vi_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public vi_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public vi_building_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_building_id_payload_search = [
    { id: 'vi_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public vi_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_home_id_payload_search = [
    { id: 'vi_cplx_id', value: '-1' },
    { id: 'vi_building_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public vi_off_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_off_id_payload_search = [
    { id: 'vi_cplx_id', value: '-1' },
    { id: 'vi_building_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public vi_res_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_res_id_payload_search = [
    { id: 'vi_cplx_id', value: '-1' },
    { id: 'vi_building_id', value: '-1' },
    { id: 'vi_home_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public vi_emp_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_emp_id_payload_search = [
    { id: 'vi_cplx_id', value: '-1' },
    { id: 'vi_building_id', value: '-1' },
    { id: 'vi_off_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public vi_av_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_av_id_payload_search = [
    { id: 'vi_cplx_id', value: '-1' },
    { id: 'vi_building_id', value: '-1' },
    { id: 'vi_home_id', value: '-1' },
    { id: 'vi_off_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public vi_vehicle_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_vehicle_id_payload_search = [
    { id: 'vi_cplx_id', value: '-1' },
    { id: 'vi_building_id', value: '-1' },
    { id: 'vi_home_id', value: '-1' },
    { id: 'vi_off_id', value: '-1' },
  ];




  // tslint:disable-next-line:variable-name
  public vi_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public vi_building_id_payload = [
    { id: 'vi_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vi_home_id_payload = [
    { id: 'vi_cplx_id', value: '-1' },
    { id: 'vi_building_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vi_off_id_payload = [
    { id: 'vi_cplx_id', value: '-1' },
    { id: 'vi_building_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vi_res_id_payload = [
    { id: 'vi_cplx_id', value: '-1' },
    { id: 'vi_building_id', value: '-1' },
    { id: 'vi_home_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vi_emp_id_payload = [
    { id: 'vi_cplx_id', value: '-1' },
    { id: 'vi_building_id', value: '-1' },
    { id: 'vi_off_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vi_av_id_payload = [
    { id: 'vi_cplx_id', value: '-1' },
    { id: 'vi_building_id', value: '-1' },
    { id: 'vi_home_id', value: '-1' },
    { id: 'vi_off_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vi_vehicle_id_payload = [
    { id: 'vi_cplx_id', value: '-1' },
    { id: 'vi_building_id', value: '-1' },
    { id: 'vi_home_id', value: '-1' },
    { id: 'vi_off_id', value: '-1' },
  ];










  // tslint:disable-next-line:variable-name
  public vi_auth_id_payload = [
  ];


  public message = '';
  public logs = [];
  public browse = 'Browse';

  public cplxTypeSearch = 'NA';
  public cplxType = 'NA';
  public selectedCplx: any = { cplx_id: '-1' };
  public snpToggle = {v: 'N'};
  // private canvas: any;
  private context: CanvasRenderingContext2D;
  video: HTMLVideoElement;
  ratio: number;
  h: number;
  w: number;
  canvas: any;
  public snapImg1 = '';
  playerReady = 'N';
  public residents = [];
  public activities = [];
  public participants = [];
  public img = new Image();

  ngOnInit() {
    //TODO: Fix the quick entry in here - Pedro 
    this.getCplexesOfUsers();
  }

  ngOnDestroy() {

  }

  public changeFormat(ph) {
    return ph.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  ngAfterViewInit() {
    this.playerReady = 'Y';


    //setTimeout(() => {
    // this.canvas = document.getElementById('myCanvas1');
    // this.context = this.canvas.getContext('2d');
    // this.img = new Image();
    // this.img.onload = () => {
    //   this.context.drawImage(this.img, 0, 0, this.canvas.width, this.canvas.height);
    // };
    // }, 1000*3);
    /*
    setTimeout(() => {
      const oldPlayer = document.querySelector('video');
      videojs(oldPlayer).dispose();
      this.playerReady = 'N';
    }, 1000 * 1);
    setTimeout(() => {
      this.playerReady = 'Y';
    }, 1000 * 2);
    setTimeout(() => {
      // const oldPlayer = document.querySelector('video');
      // videojs(oldPlayer).dispose();
      //  this.playerReady = 'Y';
      this.video = document.querySelector('video');
      // this.video.pause();
      // this.video.currentTime = 0;
      this.canvas = document.querySelector('canvas');
      this.context = this.canvas.getContext('2d');
      this.w = 0; this.h = 0; this.ratio = 0;

      // add loadedmetadata which will helps to identify video attributes

      this.video.addEventListener('loadedmetadata', () => {
        this.ratio = this.video.videoWidth / this.video.videoHeight;
        this.w = this.video.videoWidth - 100;
        this.h = this.w / this.ratio;
        this.canvas.width = this.w;
        this.canvas.height = this.h;
      }, false);

      const player = videojs('vid1');
      videojs(player).dispose();
      player.pause().currentTime(0).trigger('loadstart');
      player.play();
    }, 1000 * 3);
    */
    // this.init();
  }

  public drawFeed(frameData) {
    // requestAnimationFrame(drawframe);
    const uint8Array = new Uint8Array(frameData);

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array]);

    // Create a data URL from the Blob
    const base64String = URL.createObjectURL(blob);
    // const base64String = btoa(String.fromCharCode(...new Uint8Array(frameData)));
    // console.log(`data:image/jpeg;base64,${base64String}`);

    if (this.img) {
      console.log(base64String);
      this.img.src = base64String;
    }
  }

  public takeSnap() {
    this.snpToggle.v = 'Y';
  }

  public snapTaken1(photo){
    this.register.vi_photo_1 = photo;
  }

  public snapTaken2(photo){
    this.register.vi_photo_2 = photo;
  }

  public takeSnap1(fromSubmit = false) {
    const canvas: any = document.getElementById('canvas');
    const context = canvas.getContext('2d');
    const img = new Image();
    const d = this.canvas.toDataURL();
    img.src = d;

    // Once the image is loaded, draw it on the canvas
    img.onload = function () {
      context.drawImage(img, 0, 0);
    };
    // context.fillRect(0, 0, canvas.width, canvas.height);
    // context.drawImage(this.video, 0, 0, this.w, this.h);
    
    console.log(d);
    this.generatePhotoUrl(d, 'vi_photo_1', fromSubmit);
  }
  private generatePhotoUrl(base64, key: string, fromSubmit) {
    this.browse = 'Processing..';
    this.app.getQuery('clients/get_photo_save', { photo: base64 }).then(result => {
      console.log(result);
      this.browse = 'Browse';
      if (result.status === 'OK') {
        this.snapImg1 = result.file_name;
        this.register[key] = result.file_name;
        if (fromSubmit) {
          this.onSubmit();
        }
        // this.mPhotoSrc = result.file_name;
        // this.onChange(this.mPhotoSrc);
        // console.log
      }
    });
  }

  public searchPayloadsChanged() {

    this.vi_building_id_payload_search = [
      { id: 'vi_cplx_id', value: this.vi_cplx_id_search },
    ];

    this.vi_home_id_payload_search = [
      { id: 'vi_cplx_id', value: this.vi_cplx_id_search },
      { id: 'vi_building_id', value: this.vi_building_id_search },
    ];

    this.vi_off_id_payload_search = [
      { id: 'vi_cplx_id', value: this.vi_cplx_id_search },
      { id: 'vi_building_id', value: this.vi_building_id_search },
    ];

    this.vi_res_id_payload_search = [
      { id: 'vi_cplx_id', value: this.vi_cplx_id_search },
      { id: 'vi_building_id', value: this.vi_building_id_search },
      { id: 'vi_home_id', value: this.vi_home_id_search },
    ];

    this.vi_emp_id_payload_search = [
      { id: 'vi_cplx_id', value: this.vi_cplx_id_search },
      { id: 'vi_building_id', value: this.vi_building_id_search },
      { id: 'vi_off_id', value: this.vi_off_id_search },
    ];

    this.vi_av_id_payload_search = [
      { id: 'vi_cplx_id', value: this.vi_cplx_id_search },
      { id: 'vi_building_id', value: this.vi_building_id_search },
      { id: 'vi_home_id', value: this.vi_home_id_search },
      { id: 'vi_off_id', value: this.vi_off_id_search },
    ];

    this.vi_vehicle_id_payload_search = [
      { id: 'vi_cplx_id', value: this.vi_cplx_id_search },
      { id: 'vi_building_id', value: this.vi_building_id_search },
      { id: 'vi_home_id', value: this.vi_home_id_search },
      { id: 'vi_off_id', value: this.vi_off_id_search },
    ];

    if (this.vi_cplx_id_search === '-1') {
      this.cplxTypeSearch = 'NA';
    } else {
      this.app.getQuery('registers/get_about_complexes', { id: this.vi_cplx_id_search }).then(result => {
        if (result.status === 'OK') {
          if (result.data.length > 0) {
            this.cplxTypeSearch = result.data[0].cplx_type;
          } else {
            this.cplxTypeSearch = 'NA';
          }
        }
      });
    }
  }

  public payloadsChanged() {
    this.vi_building_id_payload = [
      { id: 'vi_cplx_id', value: this.register.vi_cplx_id },
    ];
    this.vi_home_id_payload = [
      { id: 'vi_cplx_id', value: this.register.vi_cplx_id },
      { id: 'vi_building_id', value: this.register.vi_building_id },
    ];
    this.vi_off_id_payload = [
      { id: 'vi_cplx_id', value: this.register.vi_cplx_id },
      { id: 'vi_building_id', value: this.register.vi_building_id },
    ];
    this.vi_res_id_payload = [
      { id: 'vi_cplx_id', value: this.register.vi_cplx_id },
      { id: 'vi_building_id', value: this.register.vi_building_id },
      { id: 'vi_home_id', value: this.register.vi_home_id },
    ];
    this.vi_emp_id_payload = [
      { id: 'vi_cplx_id', value: this.register.vi_cplx_id },
      { id: 'vi_building_id', value: this.register.vi_building_id },
      { id: 'vi_off_id', value: this.register.vi_off_id },
    ];
    this.vi_av_id_payload = [
      { id: 'vi_cplx_id', value: this.register.vi_cplx_id },
      { id: 'vi_building_id', value: this.register.vi_building_id },
      { id: 'vi_home_id', value: this.register.vi_home_id },
      { id: 'vi_off_id', value: this.register.vi_off_id },
    ];
    this.vi_vehicle_id_payload = [
      { id: 'vi_cplx_id', value: this.register.vi_cplx_id },
      { id: 'vi_building_id', value: this.register.vi_building_id },
      { id: 'vi_home_id', value: this.register.vi_home_id },
      { id: 'vi_off_id', value: this.register.vi_off_id },
    ];
    if (this.register.vi_cplx_id === '-1') {
      this.cplxType = 'NA';
    } else {
      this.app.getQuery('registers/get_about_complexes', { id: this.register.vi_cplx_id }).then(result => {
        if (result.status === 'OK') {
          if (result.data.length > 0) {
            this.cplxType = result.data[0].cplx_type;
            this.selectedCplx = result.data[0];
            console.log(this.selectedCplx);
          } else {
            this.cplxType = 'NA';
            this.selectedCplx = { cplx_id: '-1' };
          }
        }
      });
      this.registers = [];
      //  this.reset();
      this.getAllRegisters();
    }

    this.getResidents();
    this.getActivities();
    // this.app.getQuery('registers/get_residents_of_home', { id: this.register.vi_home_id }).then(result => {
    //   console.log(result);
    //   if (result.status === 'OK') {
    //     if (result.data.length > 0) {
    //       this.residents = result.data;
    //     } else {
    //       this.residents = [];
    //     }
    //   }
    // });
    this.app.getQuery('registers/get_about_av', { id: this.register.vi_av_id }).then(result => {
      console.log(result);
      if (result.status === 'OK') {
        if (result.data.length > 0) {
          // this.authuser = result.data[0];
          this.register.vi_name = result.data[0].av_name;
          this.register.vi_last_name = result.data[0].av_last_name;
        } else {
          // this.authuser = {};
        }
      }
    });
    this.app.getQuery('registers/get_about_vehicle', { id: this.register.vi_vehicle_id }).then(result => {
      console.log(result);
      if (result.status === 'OK') {
        if (result.data.length > 0) {
          // this.authuser = result.data[0];
          this.register.vi_vehicle = result.data[0].vehicle_brand + ' ' + result.data[0].vehicle_model;
          this.register.vi_plate = result.data[0].vehicle_license_plate;
          this.register.vi_color = result.data[0].vehicle_color;
        } else {
          // this.authuser = {};
        }
      }
    });
  }

  public getCplexesOfUsers() {
    this.app.getQuery('registers/get_all_cplx_complexes_one', this.vi_cplx_id_payload).then(result => {
      console.log(result);
      if (result.status === 'OK') {
        if (result.data.length === 1) {
          this.register.vi_cplx_id = result.data[0].id;
          this.onCplxIdChanged(result.data[0].id);
        } else {
          // this.authuser = {};
        }
      }
    });
  }

  public getResidents() {
    this.app.getQuery('registers/get_residents_of_home', { id: this.register.vi_home_id }).then(result => {
      console.log(result);
      if (result.status === 'OK') {
        if (result.data.length > 0) {
          this.residents = result.data;
        } else {
          this.residents = [];
        }
      }
    });
  }
  public getActivities() {
    this.app.getQuery('registers/get_activities', { cplx_id: this.register.vi_cplx_id, home_id: this.register.vi_home_id }).then(result => {
      console.log(result);
      if (result.status === 'OK') {
        if (result.data.length > 0) {
          this.activities = result.data;
        } else {
          this.activities = [];
        }
      }
    });
  }

  public getParticipants(act) {
    this.participants = [];
    $('#myModalAct').modal('show');
    this.participants = JSON.parse(act.act_list_of_participants);
    console.log(this.participants);
    // this.app.getQuery('registers/get_participants', { act_id: act.act_id}).then(result => {
    //   console.log(result);
    //   if (result.status === 'OK') {
    //     this.participants = result.data;
    //   }
    // });
  }

  public init() {
    this.registers = [];
    this.reset();
    this.getAllRegisters();
  }

  public reset() {
    const backup = JSON.parse(JSON.stringify(this.register));
    this.register = {
      vi_id: '-1',
      vi_cplx_id: backup.vi_cplx_id,
      vi_building_id: '-1',
      vi_home_id: '-1',
      vi_off_id: '-1',
      vi_res_id: '-1',
      vi_emp_id: '-1',
      vi_av_id: '-1',
      vi_vehicle_id: '-1',
      vi_name: '',
      vi_last_name: '',
      vi_vehicle: '',
      vi_color: '',
      vi_plate: '',
      vi_approved_by: '',
      vi_photo_1: '',
      vi_photo_2: '',
      vi_auth_id: '-1',
      vi_active: 'Y',
      vi_log: '',
    };
    this.snpToggle.v = 'N';
    this.onCplxIdChanged(backup.vi_cplx_id);
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public copyResident(res) {
    this.register.vi_name = res.res_name;
    this.register.vi_last_name = res.res_last_name;
    this.register.vi_approved_by = res.res_name + ' ' + res.res_last_name;
  }

  public copyParticipants(res) {
    this.register.vi_name = res.name;
    this.register.vi_last_name = res.last_name;
    $('#myModalAct').modal('hide');
  }

  public onCplxIdChangedOnSearch(obj) {
    this.vi_building_id_search = '-1';
    this.vi_home_id_search = '-1';
    this.vi_res_id_search = '-1';
    this.vi_off_id_search = '-1';
    this.vi_emp_id_search = '-1';
    this.vi_av_id_search = '-1';
    this.vi_vehicle_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onBuildingIdChangedOnSearch(obj) {
    this.vi_home_id_search = '-1';
    this.vi_off_id_search = '-1';
    this.vi_res_id_search = '-1';
    this.vi_emp_id_search = '-1';
    this.vi_av_id_search = '-1';
    this.vi_vehicle_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.vi_res_id_search = '-1';
    this.vi_av_id_search = '-1';
    this.vi_vehicle_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onOffIdChangedOnSearch(obj) {
    this.vi_emp_id_search = '-1';
    this.vi_av_id_search = '-1';
    this.vi_vehicle_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onResIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onEmpIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onAvIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
    
  }
  public onVehicleIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.register.vi_building_id = '-1';
    this.register.vi_home_id = '-1';
    this.register.vi_res_id = '-1';
    this.register.vi_off_id = '-1';
    this.register.vi_emp_id = '-1';
    this.register.vi_av_id = '-1';
    this.register.vi_vehicle_id = '-1';

    this.payloadsChanged();
  }
  public onBuildingIdChanged(obj) {
    this.register.vi_home_id = '-1';
    this.register.vi_off_id = '-1';
    this.register.vi_res_id = '-1';
    this.register.vi_emp_id = '-1';
    this.register.vi_av_id = '-1';
    this.register.vi_vehicle_id = '-1';
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.register.vi_res_id = '-1';
    this.register.vi_av_id = '-1';
    this.register.vi_vehicle_id = '-1';
    this.register.vi_approved_by = '';
    this.payloadsChanged();
  }
  public onOffIdChanged(obj) {
    this.register.vi_emp_id = '-1';
    this.register.vi_av_id = '-1';
    this.register.vi_vehicle_id = '-1';
    this.payloadsChanged();
  }
  public onResIdChanged(obj) {
    this.register.vi_approved_by = '';
    this.payloadsChanged();
    this.app.getQuery('registers/get_selected_residents_profile', { id: obj }).then(result => {
      console.log(result);
      if (result.status === 'OK') {
        if (result.data.length > 0) {
          // this.residents = result.data;
          // this.copyResident(result.data[0]);
          if (result.data.length > 0) {
            this.register.vi_home_id = result.data[0].home_id;
            this.getResidents();
            this.getActivities();
            this.payloadsChanged();
          }
        } else {
          // this.residents = [];
        }
      }
    });
  }
  public onEmpIdChanged(obj) {
    this.payloadsChanged();
  }
  public onAvIdChanged(obj) {
    this.payloadsChanged();
    
    console.log('av');
    console.log(obj);
    this.app.getQuery('registers/get_selected_res_home_by_av_id',{id: obj}).then(result => {
      if (result.status === 'OK'){
        if (result.data.length > 0){
          const s = result.data[0];
          console.log(s);
          if (s.res_name || s.res_last_name){
            this.register.vi_approved_by = s.res_name+' '+s.res_last_name;
          }
          // this.register.vi_home_id = s.av_home_id;
          // this.register.vi_building_id = s.av_building_id;
          // this.register.vi_res_id = s.av_res_id;
        }
      }
    });
  }
  public onVehicleIdChanged(obj) {
    this.payloadsChanged();
  }
  public onAuthIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllRegisters() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      vi_cplx_id_search: this.register.vi_cplx_id,


      vi_building_id_search: this.register.vi_building_id,


      vi_home_id_search: this.register.vi_home_id,


      vi_off_id_search: this.register.vi_off_id,


      vi_res_id_search: this.vi_res_id_search,


      vi_emp_id_search: this.vi_emp_id_search,


      vi_av_id_search: this.vi_av_id_search,


      vi_vehicle_id_search: this.vi_vehicle_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('registers/get_all_registers', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        // this.editItem(result.data[0]);
        this.registers = this.registers.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.registers.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllRegisters();
  }

  scrollToFirstFieldAndFocus() {
    // // Scroll to the top of the page
    // window.scrollTo(0, 0);
    // // @ViewChild('vi_name') firstField: ElementRef; // Reference to the first input field

    // // // Set focus to the first input field
    // setTimeout(() => {
    // //   firstField.nativeElement.focus();
    //   $('#vi_name').focus();
    // }, 3000);
    $('html, body').animate({scrollTop: 0}, 'slow', function(){
      // Set focus to the first input field
      $('#vi_name').focus();
    });
    
  }

  public onSubmit() {
    if (this.register.vi_name === '') {
      alert(this.app.text.vi_name_can_not_be_empty || 'vi_name_can_not_be_empty');
    } else if (this.register.vi_last_name === '') {
      alert(this.app.text.vi_last_name_can_not_be_empty || 'vi_last_name_can_not_be_empty');
    } else if (this.register.vi_res_id === '-1' && this.register.vi_home_id === '-1') {
      alert(this.app.text.vi_res_or_home_select || 'vi_res_or_home_select');
    } else {

      this.loadingText = 'Saving...';
      const itemObj = JSON.parse(JSON.stringify(this.register));
      this.app.getQuery('registers/add_edit_register', itemObj).then(result => {
        this.loadingText = 'Save';
        console.log(result);
        if (result.status === 'OK') {
          $('#myModalvi_id').modal('hide');
          this.onClear();
          this.scrollToFirstFieldAndFocus();
        } else {
          this.message = result.message;
          setTimeout(() => {
            this.message = '';
          }, 4000);
        }
      });

    }
    // console.log(this.register);

  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModalvi_id').modal('show');
    // $('#myModalvi_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    tempItem.vi_id = '-1';
    this.register = tempItem;

    this.payloadsChanged();
    $('#myModalvi_id').modal('show');
    $('html, body').animate({ scrollTop: 0 }, 'slow');
    // return false;
    // $('#myModalvi_id').appendTo('body');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



