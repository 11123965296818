
import { Component, EventEmitter, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

import jsPDF from 'jspdf';
import 'jspdf-autotable';


declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;
  @Input() isFromHome = 'N';
  @Output() back = new EventEmitter<any>();


  public actfilter = true;
  public inactfilter = false;

  public vehicles = [];
  public vehiclesPDf = []
  public isAdmin = localStorage.getItem('user_level');

  public vehicl: any = {
    vehicle_id: '-1',
    vehicle_brand: '',
    vehicle_model: '',
    vehicle_color: '',
    vehicle_license_plate: '',
    vehicle_access_titcketnum: '',
    vehicle_cplx_id: '-1',
    vehicle_building_id: '-1',
    vehicle_off_id: '-1',
    vehicle_home_id: '-1',
    vehicle_active: 'Y',
    vehicle_log: '',
  };


  public loadingText = 'Save';
  public isLoading = false
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'vehicle_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  @Input() public vehicle_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vehicle_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  @Input() public vehicle_building_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vehicle_building_id_payload_search = [
    { id: 'vehicle_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public vehicle_off_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vehicle_off_id_payload_search = [
    { id: 'vehicle_cplx_id', value: '-1' },
    { id: 'vehicle_building_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  @Input() public vehicle_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vehicle_home_id_payload_search = [
    { id: 'vehicle_cplx_id', value: '-1' },
    { id: 'vehicle_building_id', value: '-1' },
  ];







  // tslint:disable-next-line:variable-name
  public vehicle_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public vehicle_building_id_payload = [
    { id: 'vehicle_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vehicle_off_id_payload = [
    { id: 'vehicle_cplx_id', value: '-1' },
    { id: 'vehicle_building_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vehicle_home_id_payload = [
    { id: 'vehicle_cplx_id', value: '-1' },
    { id: 'vehicle_building_id', value: '-1' },
  ];


  public message = '';
  public logs = [];
  public browse = 'Browse';

  public cplxTypeSearch = 'NA';
  public cplxType = 'NA';
  public failedData = [];
  public adminSetting = {cust_csv: ''};


  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {
    // this.getAllVehiclesPDF();

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public onBackToHome() {
    this.back.emit('back');
  }

  public searchPayloadsChanged() {

    this.vehicle_building_id_payload_search = [
      { id: 'vehicle_cplx_id', value: this.vehicle_cplx_id_search },
    ];

    this.vehicle_off_id_payload_search = [
      { id: 'vehicle_cplx_id', value: this.vehicle_cplx_id_search },
      { id: 'vehicle_building_id', value: this.vehicle_building_id_search },
    ];

    this.vehicle_home_id_payload_search = [
      { id: 'vehicle_cplx_id', value: this.vehicle_cplx_id_search },
      { id: 'vehicle_building_id', value: this.vehicle_building_id_search },
    ];

    if (this.vehicle_cplx_id_search === '-1') {
      this.cplxTypeSearch = 'NA';
    } else {
      this.app.getQuery('vehicles/get_about_complexes', { id: this.vehicle_cplx_id_search }).then(result => {
        if (result.status === 'OK') {
          if (result.data.length > 0) {
            this.cplxTypeSearch = result.data[0].cplx_type;
          } else {
            this.cplxTypeSearch = 'NA';
          }
        }
      });
    }
  }

  public payloadsChanged() {
    this.vehicle_building_id_payload = [
      { id: 'vehicle_cplx_id', value: this.vehicl.vehicle_cplx_id },
    ];
    this.vehicle_off_id_payload = [
      { id: 'vehicle_cplx_id', value: this.vehicl.vehicle_cplx_id },
      { id: 'vehicle_building_id', value: this.vehicl.vehicle_building_id },
    ];
    this.vehicle_home_id_payload = [
      { id: 'vehicle_cplx_id', value: this.vehicl.vehicle_cplx_id },
      { id: 'vehicle_building_id', value: this.vehicl.vehicle_building_id },
    ];
    if (this.vehicl.vehicle_cplx_id === '-1') {
      this.cplxType = 'NA';
    } else {
      this.app.getQuery('vehicles/get_about_complexes', { id: this.vehicl.vehicle_cplx_id }).then(result => {
        if (result.status === 'OK') {
          if (result.data.length > 0) {
            this.cplxType = result.data[0].cplx_type;
          } else {
            this.cplxType = 'NA';
          }
        }
      });
    }
  }

  public init() {
    this.vehicles = [];
    this.reset();
    this.getAllVehicles();
  }

  public reset() {
    this.vehicl = {
      vehicle_id: '-1',
      vehicle_brand: '',
      vehicle_model: '',
      vehicle_color: '',
      vehicle_license_plate: '',
      vehicle_access_titcketnum: '',
      vehicle_cplx_id: '-1',
      vehicle_building_id: '-1',
      vehicle_off_id: '-1',
      vehicle_home_id: '-1',
      vehicle_active: 'Y',
      vehicle_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.vehicle_building_id_search = '-1';
    this.vehicle_home_id_search = '-1';
    this.vehicle_off_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onBuildingIdChangedOnSearch(obj) {
    this.vehicle_home_id_search = '-1';
    this.vehicle_off_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onOffIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.vehicl.vehicle_building_id = '-1';
    this.vehicl.vehicle_home_id = '-1';
    this.vehicl.vehicle_off_id = '-1';
    this.payloadsChanged();
  }
  public onBuildingIdChanged(obj) {
    this.vehicl.vehicle_home_id = '-1';
    this.vehicl.vehicle_off_id = '-1';
    this.payloadsChanged();
  }
  public onOffIdChanged(obj) {
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllVehicles() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      vehicle_cplx_id_search: this.vehicle_cplx_id_search,


      vehicle_building_id_search: this.vehicle_building_id_search,


      vehicle_off_id_search: this.vehicle_off_id_search,


      vehicle_home_id_search: this.vehicle_home_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('vehicles/get_all_vehicles', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.vehicles = this.vehicles.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.vehicles.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  // public getAllVehiclesPDF() {
  //   const obj = {
  //     num_per_page: this.num_per_page,
  //     order_by: this.order_by,
  //     order: this.order,
  //     start_from: this.start_from,
  //     vehicle_cplx_id_search: this.vehicle_cplx_id_search,
  //     vehicle_building_id_search: this.vehicle_building_id_search,
  //     vehicle_off_id_search: this.vehicle_off_id_search,
  //     vehicle_home_id_search: this.vehicle_home_id_search,
  //     search: this.search,
  //     active: this.actfilter,
  //     inactive: this.inactfilter
  //   };
  
  //   // Create a separate array to store the vehicles for PDF
  //   const vehiclesPDF: any[] = [];
  
  //   const fetchAllVehicles = (startFrom: number) => {
  //     obj.start_from = startFrom;
  
  //     this.app.getQuery('vehicles/get_all_vehicles', obj).then(result => {
  //       if (result.status === 'OK') {
  //         vehiclesPDF.push(...result.data); // Add the data to the separate array
  
  //         this.count = result.total;
  //         this.received = vehiclesPDF.length;
  
  //         if (vehiclesPDF.length < result.total) {
  //           // Fetch the next batch of vehicles
  //           fetchAllVehicles(vehiclesPDF.length);
  //         } else {
  //           // All vehicles are fetched
  //           this.displayCount = `${vehiclesPDF.length} of ${this.count}`;
  //           console.log('All vehicles have been retrieved for PDF.', vehiclesPDF);
  //         }
  //       } else {
  //         console.error('Error fetching vehicles:', result.message);
  //       }
  //     }).catch(error => {
  //       console.error('Request failed:', error);
  //     });
  //   };
  
  //   // Start fetching all vehicles from the beginning
  //   fetchAllVehicles(0);
  // }


  public getAllVehiclesPDF() {
    const obj = {
      num_per_page: '40',
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      vehicle_cplx_id_search: this.vehicle_cplx_id_search,
      vehicle_building_id_search: this.vehicle_building_id_search,
      vehicle_off_id_search: this.vehicle_off_id_search,
      vehicle_home_id_search: this.vehicle_home_id_search,
      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };

    const vehiclesPDF: any[] = [];

    // Show the loading indicator
    this.isLoading = true;

    const fetchAllVehicles = (startFrom: number) => {
      obj.start_from = startFrom;

      this.app.getQuery('vehicles/get_all_vehicles', obj).then(result => {
        if (result.status === 'OK') {
          vehiclesPDF.push(...result.data);

          this.count = result.total;
          this.received = vehiclesPDF.length;

          if (vehiclesPDF.length < result.total) {
            // Fetch the next batch of vehicles
            fetchAllVehicles(vehiclesPDF.length);
          } else {
            // All vehicles are fetched
            this.displayCount = `${vehiclesPDF.length} of ${this.count}`;
            console.log('All vehicles have been retrieved for PDF.', vehiclesPDF);

            // Hide the loading indicator
            this.isLoading = false;

            // Proceed to generate or save the PDF with the data
            this.savePDF(vehiclesPDF);
          }
        } else {
          console.error('Error fetching vehicles:', result.message);

          // Hide the loading indicator if there's an error
          this.isLoading = false;
        }
      }).catch(error => {
        console.error('Request failed:', error);

        // Hide the loading indicator if there's an error
        this.isLoading = false;
      });
    };

    // Start fetching all vehicles from the beginning
    fetchAllVehicles(0);
  }
 
  

private savePDF(vehiclesPDF: any[]) {
  try {
    if (!Array.isArray(vehiclesPDF)) {
      throw new Error('Invalid data: vehiclesPDF should be an array.');
    }

    const doc = new jsPDF();

    // Set document properties
    doc.setProperties({
      title: 'Vehicles Data',
      subject: 'List of Vehicles',
      author: 'AccesControlOne',
      keywords: 'vehicles, pdf',
      creator: 'AccessControlOne'
    });

    // Add title
    doc.setFontSize(18);
    doc.text('Vehicles Data', 14, 15);

    // Prepare the table headers and rows
    const headers = [
      ['Building Name', 'Complex Contact Name', 'Complex Name', 'Vehicle Brand', 'Home Phone 1', 'Home Phone 2', 'Vehicle Color', 'Vehicle Model', 'Vehicle Lisense Plate']
    ];

    const rows = vehiclesPDF.map(vehicle => [
      vehicle.building_name || 'N/A',
      vehicle.cplx_contact_name || 'N/A',
      vehicle.cplx_name || 'N/A',
      vehicle.vehicle_brand || 'N/A',
      vehicle.home_phone1 || 'N/A',
      vehicle.home_phone2 || 'N/A',
      vehicle.vehicle_color || 'N/A',
      vehicle.vehicle_model || 'N/A',
      vehicle.vehicle_license_plate || 'N/A'
    ]);

    // Add the table to the PDF
    (doc as any).autoTable({
      startY: 25,
      head: headers,
      body: rows,
    });

    
    // Save the PDF and trigger the download
    doc.save('vehicles_data.pdf');

    console.log('PDF generated successfully with the following vehicles data:', vehiclesPDF);

  } catch (error) {
    console.error('An error occurred while generating the PDF:', error.message);
    alert('An error occurred while generating the PDF. Please try again.');
  }
}

  



  
  
  

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllVehicles();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.vehicl));
    this.app.getQuery('vehicles/add_edit_vehicl', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModalvehicle_id').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModalvehicle_id').modal('show');
    // $('#myModalvehicle_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.vehicl = tempItem;

    this.payloadsChanged();
    $('#myModalvehicle_id').modal('show');
    // $('#myModalvehicle_id').appendTo('body');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }

  public csvSubmit(ox = '') {
    console.log(ox);
    this.failedData = [];
    // console.log(this.adminSetting);
    this.app.getQuery('vehicles/add_edit_vehicles_by_csv', { csv_file: ox })
      .then(result => {
        console.log(result);
        if (result.status === 'OK') {
          this.onChangePage();
          if (result.failed.length > 0) {
            // alert('Uploaded, Duplicates skipped');
            this.failedData = result.failed;
            $('#myModalFailedVehicles').modal('show');
          } else {
            alert('Uploaded');
          }
        } else {
          this.onChangePage();
          alert(result.message);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }


}



