
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import { AddlogService } from '../Services/addlog.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-customer-users',
  templateUrl: './customer-users.component.html',
  styleUrls: ['./customer-users.component.css']
})
export class CustomerUsersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;


  public customerUsers = [];
  public isAdmin = localStorage.getItem('user_level');
  private oldUserPassword:string = "";



  public customerUser: any = {
    cu_id: '-1',
    cu_email: '',
    cu_password: '',
    cu_cust_id: '-1',
    complexes: [],
    cu_name: '',
    cu_last_name: '',
    cu_type: 'Supervisor',
    cu_photo: '',
    cu_active: 'Y',
    cu_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'cu_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public cu_cust_id_search = '-1';
  // tslint:disable-next-line:variable-name
  public complexes_search = [];
  // tslint:disable-next-line:variable-name
  public cu_cust_id_payload_search = [
  ];

  private oldActiveStatus:string = "" 

  private user_name = localStorage.getItem('user_name');
  private user_level = localStorage.getItem("user_level");






  // tslint:disable-next-line:variable-name
  public cu_cust_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public complexes_payload = [
    { id: 'cu_cust_id', value: '-1' },
  ];






  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location, private addLogService:AddlogService) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

  }

  public payloadsChanged() {
    this.complexes_payload = [
      { id: 'cu_cust_id', value: this.customerUser.cu_cust_id },
    ];
  }

  public init() {
    this.customerUsers = [];
    this.reset();
    this.getAllCustomerUsers();
  }

  public reset() {
    this.customerUser = {
      cu_id: '-1',
      cu_email: '',
      cu_password: '',
      cu_cust_id: '-1',
      complexes: [],
      cu_name: '',
      cu_last_name: '',
      cu_type: 'Supervisor',
      cu_photo: '',
      cu_active: 'Y',
      cu_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCustIdChangedOnSearch(obj) {
    this.complexes_search = [];
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCustIdChanged(obj) {
    this.customerUser.complexes = [];
    this.payloadsChanged();
  }


  public getAllCustomerUsers() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      cu_cust_id_search: this.cu_cust_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('customerusers/get_all_customer_users', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.customerUsers = this.customerUsers.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.customerUsers.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllCustomerUsers();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.customerUser));
    
    this.app.getQuery('customerusers/add_edit_customeruser', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModal').modal('hide');
        console.log(result.message)
        
        let logMessage = '';

        // Check if the active status has changed
        if (this.oldActiveStatus !== itemObj.cu_active) {
          logMessage = `User ${this.user_name} has changed the User ${itemObj.cu_name}'s active status to ${itemObj.cu_active}.`;
        }
        
        // Check if the password has changed
        if (this.oldUserPassword !== itemObj.cu_password) {
          logMessage += ` User "${this.user_name}" changed the password for "${itemObj.cu_name}" to "${itemObj.cu_password}".`;
        }
        
        // Log customer user entry updated if there are no other logs for status or password
        if (result.message === "CustomerUser entry updated" && !logMessage) {
          logMessage = `User ${this.user_name} updated a customer user named ${itemObj.cu_name}.`;
        }
        
        // If no changes were detected, consider it a user creation
        if (!logMessage) {
          logMessage = `User ${this.user_name} created a customer user named ${itemObj.cu_name}.`;
        }
        
        // Add the logs if any changes occurred
        this.addLogService.addLogs(this.user_name, this.user_level, logMessage)
          .then(res => {
            console.log(res);
          })
          .catch(e => {
            console.log(e);
          });
        

        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    tempItem.cu_password = '';

    this.customerUser = tempItem;
    this.oldActiveStatus = this.customerUser.cu_active
    this.oldUserPassword = this.customerUser.cu_password
  

    this.payloadsChanged();
    this.get_selected_complexes();
    $('#myModal').modal('show');
  }

  private get_selected_complexes() {
    this.app.getQuery('customerusers/get_selected_complexes', { id: this.customerUser.cu_id }).then(result => {
      console.log(result);
      if (result.status === 'OK') {
        this.customerUser.complexes = result.data;
      }
    });
  }


  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



