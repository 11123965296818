
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public loaded = 'N';
  public documents = [];
  public isAdmin = localStorage.getItem('user_level');

  public document: any = {
    document_id: '-1',
    document_name: '',
    document_cplx_id: '-1',
    document_building_id: '-1',
    document_home_id: '-1',
    document_file: '',
    document_active: 'Y',
    document_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'document_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public document_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public document_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public document_building_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public document_building_id_payload_search = [
    { id: 'document_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public document_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public document_home_id_payload_search = [
    { id: 'document_cplx_id', value: '-1' },
  ];





  // tslint:disable-next-line:variable-name
  public document_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public document_building_id_payload = [
    { id: 'document_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public document_home_id_payload = [
    { id: 'document_cplx_id', value: '-1' },
  ];



  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

    this.document_building_id_payload_search = [
      { id: 'document_cplx_id', value: this.document_cplx_id_search },
    ];

    this.document_home_id_payload_search = [
      { id: 'document_cplx_id', value: this.document_cplx_id_search },
    ];

  }

  public payloadsChanged() {
    this.document_building_id_payload = [
      { id: 'document_cplx_id', value: this.document.document_cplx_id },
    ];
    this.document_home_id_payload = [
      { id: 'document_cplx_id', value: this.document.document_cplx_id },
    ];
  }

  public init() {
    this.documents = [];
    this.reset();
    this.getAllDocuments();
  }

  public reset() {
    this.document = {
      document_id: '-1',
      document_name: '',
      document_cplx_id: '-1',
      document_building_id: '-1',
      document_home_id: '-1',
      document_file: '',
      document_active: 'Y',
      document_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.document_building_id_search = '-1';
    this.document_home_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onBuildingIdChangedOnSearch(obj) {
    this.document_home_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.document.document_building_id = '-1';
    this.document.document_home_id = '-1';
    this.payloadsChanged();
  }
  public onBuildingIdChanged(obj) {
    this.document.document_home_id = '-1';
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllDocuments() {
    this.loaded = 'N';
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      document_cplx_id_search: this.document_cplx_id_search,


      document_building_id_search: this.document_building_id_search,


      document_home_id_search: this.document_home_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('documents/get_all_documents', obj).then(result => {

      console.log(result);
      this.loaded = 'Y';
      if (result.status === 'OK') {
        this.documents = this.documents.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.documents.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllDocuments();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.document));
    this.app.getQuery('documents/add_edit_document', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModaldocument_id').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModaldocument_id').modal('show');
    // $('#myModaldocument_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.document = tempItem;

    this.payloadsChanged();
    $('#myModaldocument_id').modal('show');
    // $('#myModaldocument_id').appendTo('body');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



