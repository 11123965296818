
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css']
})
export class EmployeesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  //TODO: if the role is employee then dont write data
  public isAdmin = localStorage.getItem('user_level');

  public employees = [];

  public employee: any = {
    emp_id: '-1',
    emp_email: '',
    emp_name: '',
    emp_last_name: '',
    emp_password: '',
    emp_photo: '',
    emp_mobile: '',
    emp_mms_id: '-1',
    emp_cplx_id: '-1',
    emp_building_id: '-1',
    emp_off_id: '-1',
    emp_workhr_mon_from: '',
    emp_workhr_mon_to: '',
    emp_workhr_mon_not: '',
    emp_workhr_tue_from: '',
    emp_workhr_tue_to: '',
    emp_workhr_tue_not: '',
    emp_workhr_wed_from: '',
    emp_workhr_wed_to: '',
    emp_workhr_wed_not: '',
    emp_workhr_thr_from: '',
    emp_workhr_thr_to: '',
    emp_workhr_thr_not: '',
    emp_workhr_fri_from: '',
    emp_workhr_fri_to: '',
    emp_workhr_fri_not: '',
    emp_workhr_sat_from: '',
    emp_workhr_sat_to: '',
    emp_workhr_sat_not: '',
    emp_workhr_sun_from: '',
    emp_workhr_sun_to: '',
    emp_workhr_sun_not: '',
    emp_active: 'Y',
    emp_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'emp_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public emp_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public emp_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public emp_building_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public emp_building_id_payload_search = [
    { id: 'emp_cplx_id', value: '-1' },
  ];










  // tslint:disable-next-line:variable-name
  public emp_mms_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public emp_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public emp_building_id_payload = [
    { id: 'emp_cplx_id', value: '-1' },
  ];

  // tslint:disable-next-line:variable-name
  public emp_off_id_payload = [
    { id: 'emp_building_id', value: '-1' },
  ];























  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {
    console.log(this.emp_cplx_id_search);
    this.emp_building_id_payload_search = [
      { id: 'emp_cplx_id', value: this.emp_cplx_id_search },
    ];

  }

  public payloadsChanged() {
    this.emp_building_id_payload = [
      { id: 'emp_cplx_id', value: this.employee.emp_cplx_id },
    ];
    this.emp_off_id_payload = [
      { id: 'emp_building_id', value: this.employee.emp_building_id },
    ];
  }

  public init() {
    this.employees = [];
    this.reset();
    this.getAllEmployees();
  }

  public reset() {
    this.employee = {
      emp_id: '-1',
      emp_email: '',
      emp_name: '',
      emp_last_name: '',
      emp_password: '',
      emp_photo: '',
      emp_mobile: '',
      emp_mms_id: '-1',
      emp_cplx_id: '-1',
      emp_building_id: '-1',
      emp_off_id: '-1',
      emp_workhr_mon_from: '',
      emp_workhr_mon_to: '',
      emp_workhr_mon_not: '',
      emp_workhr_tue_from: '',
      emp_workhr_tue_to: '',
      emp_workhr_tue_not: '',
      emp_workhr_wed_from: '',
      emp_workhr_wed_to: '',
      emp_workhr_wed_not: '',
      emp_workhr_thr_from: '',
      emp_workhr_thr_to: '',
      emp_workhr_thr_not: '',
      emp_workhr_fri_from: '',
      emp_workhr_fri_to: '',
      emp_workhr_fri_not: '',
      emp_workhr_sat_from: '',
      emp_workhr_sat_to: '',
      emp_workhr_sat_not: '',
      emp_workhr_sun_from: '',
      emp_workhr_sun_to: '',
      emp_workhr_sun_not: '',
      emp_active: 'Y',
      emp_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.emp_building_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onBuildingIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onMmsIdChanged(obj) {
    this.payloadsChanged();
  }
  public onCplxIdChanged(obj) {
    this.employee.emp_building_id = '-1';
    this.payloadsChanged();
  }
  public onBuildingIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllEmployees() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      emp_cplx_id_search: this.emp_cplx_id_search,


      emp_building_id_search: this.emp_building_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('employees/get_all_employees', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.employees = this.employees.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.employees.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllEmployees();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.employee));
    this.app.getQuery('employees/add_edit_employee', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModal').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    tempItem.emp_password = '';

    this.employee = tempItem;

    this.payloadsChanged();
    $('#myModal').modal('show');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



