import { Component } from '@angular/core';

@Component({
  selector: 'app-floating-chat',
  templateUrl: './floating-chat.component.html',
  styleUrls: ['./floating-chat.component.css']
})
export class FloatingChatComponent {
  isOpen: boolean = false;

  toggleChat(): void {
    this.isOpen = !this.isOpen;
  }
}
