import {
  Component, OnInit, OnDestroy, AfterViewInit, Input, Output, EventEmitter,
  ViewChild, ElementRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;


@Component({
  selector: 'app-client-password-recovery',
  templateUrl: './client-password-recovery.component.html',
  styleUrls: ['./client-password-recovery.component.css']
})
export class ClientPasswordRecoveryComponent implements OnInit, OnDestroy, AfterViewInit {
  public login: any = { id: '-1', user_password: '', user_password1: '', user_email: '', user_session: '' };
  public btnText = 'Login';
  public status = '';
  passwordStrength: string = '';
  public isLinkValid = 'NA';
  public linkMessage = '';
  private sessionId = '';
  constructor(public app: AppService, private route: ActivatedRoute, private location: Location) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      // Retrieve the parameter value
      this.sessionId = params.get('id');
      console.log(this.sessionId); // Use the parameter value as needed
      const user = JSON.parse(JSON.stringify(this.login));
      user.cur_session = this.sessionId;
      this.app.getQueryNoToken('clients/is_recover_password_link_valid', user).then(result => {
        console.log(result);
        if (result.status === 'OK') {
          this.isLinkValid = 'Y';
          this.login.user_email = result.data.clientuser_email;
        } else {
          this.isLinkValid = 'N';
          this.linkMessage = result.message;
        }
      });
    });
  }
  ngAfterViewInit(): void {

  }

  checkPasswordStrength(password: string): void {
    // Password strength criteria: min 6 characters, one uppercase, one number, one special character
    //const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{6,}$/;
    if (regex.test(password)) {
      this.passwordStrength = 'strong';
    } else if (password.length >= 6) {
      this.passwordStrength = 'medium';
    } else {
      this.passwordStrength = 'weak';
    }
  }

  public onSubmit() {
    if (this.login.user_password !== this.login.user_password1) {
      console.log('Passwords do not match');
      this.status = this.app.text.password_does_not_match || 'password_does_not_match';
      setTimeout(() => {
        this.status = '';
      }, 3000);
      return;
    }

    // Check if the password meets the criteria before submitting
    if (this.passwordStrength === 'strong') {
      // Submit password reset request
      console.log('Password reset request submitted');
      const user = JSON.parse(JSON.stringify(this.login));
      user.cur_session = this.sessionId;
      this.btnText = 'Loginng';
      this.app.getQueryNoToken('clients/recover_password_complete', user).then(result => {
        this.btnText = 'Login';
        if (result.status === 'OK') {
          this.status = this.app.text.password_updated || 'password_updated';
          setTimeout(() => {
            this.status = '';
          }, 3000);
        } else {
          this.status = result.message;
          setTimeout(() => {
            this.status = '';
          }, 3000);
        }
      });
    } else {
      console.log('Password does not meet the criteria');
      this.status = this.app.text.mins6char_onespl_oneupper_needed || 'mins6char_onespl_oneupper_needed';
      setTimeout(() => {
        this.status = '';
      }, 3000);
    }
  }

  ngOnDestroy(): void {

  }

}
