
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import { AddlogService } from '../Services/addlog.service';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';


declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-authorizedvistors',
  templateUrl: './authorizedvistors.component.html',
  styleUrls: ['./authorizedvistors.component.css']
})
export class AuthorizedvistorsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;
  public adminSetting = { authvisitor_csv_upload: '' };
  public actfilter = true;
  public inactfilter = false;
  public isLoading = false;

  public authorizedvistors = [];
  public authorizedVisitorsPDF: any[] = [];

  public isAdmin = localStorage.getItem('user_level');
  public user_name = localStorage.getItem('user_name');
  private oldActiveStatus:string =""

  

  public authorizedvistor: any = {
    av_id: '-1',
    av_name: '',
    av_last_name: '',
    av_photo: '',
    av_relationship: '',
    av_cplx_id: '-1',
    av_building_id: '-1',
    av_home_id: '-1',
    av_res_id: '-1',
    av_off_id: '-1',
    av_address: '',
    av_country_id: '-1',
    av_state_id: '-1',
    av_city_id: '-1',
    av_zip: '',
    av_phone: '',
    av_mobile: '',
    av_mon_open_time: '',
    av_mon_close_time: '',
    av_mon_closed: 'N',
    av_tue_open_time: '',
    av_tue_close_time: '',
    av_tue_closed: 'N',
    av_wed_open_time: '',
    av_wed_close_time: '',
    av_wed_closed: 'N',
    av_thr_open_time: '',
    av_thr_close_time: '',
    av_thr_closed: 'N',
    av_fri_open_time: '',
    av_fri_close_time: '',
    av_fri_closed: 'N',
    av_sat_open_time: '',
    av_sat_close_time: '',
    av_sat_closed: 'N',
    av_sun_open_time: '',
    av_sun_close_time: '',
    av_sun_closed: 'N',
    av_active: 'Y',
    av_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'av_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public av_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public av_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public av_building_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public av_building_id_payload_search = [
    { id: 'av_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public av_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public av_home_id_payload_search = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_building_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public av_off_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public av_off_id_payload_search = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_building_id', value: '-1' },
  ];








  // tslint:disable-next-line:variable-name
  public av_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public av_building_id_payload = [
    { id: 'av_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public av_home_id_payload = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_building_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public av_off_id_payload = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_building_id', value: '-1' },
  ];



  // tslint:disable-next-line:variable-name
  public av_country_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public av_state_id_payload = [
    { id: 'av_country_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public av_city_id_payload = [
    { id: 'av_state_id', value: '-1' },
  ];

  // tslint:disable-next-line:variable-name
  public av_res_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public av_res_id_payload_search = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_home_id', value: '-1' },
  ];
  
  // tslint:disable-next-line:variable-name
  public av_res_id_payload = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_home_id', value: '-1' },
  ];

  public cplxTypeSearch = 'NA';
  public cplxType = 'NA';



  public message = '';
  public logs = [];
  public browse = 'Browse';
  public failedData = [];


  constructor(public app: AppService, private router: Router, private location: Location, private addLogService: AddlogService) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

    this.av_building_id_payload_search = [
      { id: 'av_cplx_id', value: this.av_cplx_id_search },
    ];

    this.av_home_id_payload_search = [
      { id: 'av_cplx_id', value: this.av_cplx_id_search },
      { id: 'av_building_id', value: this.av_building_id_search },
    ];

    this.av_res_id_payload_search = [
      { id: 'av_cplx_id', value: this.av_cplx_id_search },
      { id: 'av_home_id', value: this.av_home_id_search },
    ];

    this.av_off_id_payload_search = [
      { id: 'av_cplx_id', value: this.av_cplx_id_search },
      { id: 'av_building_id', value: this.av_building_id_search },
    ];

    if (this.authorizedvistor.av_cplx_id_search === '-1') {
      this.cplxTypeSearch = 'NA';
    } else {
      this.app.getQuery('authorizedvistors/get_about_complexes', { id: this.av_cplx_id_search }).then(result => {
        if (result.status === 'OK') {
          if (result.data.length > 0) {
            this.cplxTypeSearch = result.data[0].cplx_type;
          } else {
            this.cplxTypeSearch = 'NA';
          }
        }
      });
    }

  }

  public payloadsChanged() {
    this.av_building_id_payload = [
      { id: 'av_cplx_id', value: this.authorizedvistor.av_cplx_id },
    ];
    this.av_home_id_payload = [
      { id: 'av_cplx_id', value: this.authorizedvistor.av_cplx_id },
      { id: 'av_building_id', value: this.authorizedvistor.av_building_id },
    ];
    this.av_res_id_payload = [
      { id: 'av_cplx_id', value: this.authorizedvistor.av_cplx_id },
      { id: 'av_home_id', value: this.authorizedvistor.av_home_id },
    ];
    this.av_off_id_payload = [
      { id: 'av_cplx_id', value: this.authorizedvistor.av_cplx_id },
      { id: 'av_building_id', value: this.authorizedvistor.av_building_id },
    ];
    this.av_state_id_payload = [
      { id: 'av_country_id', value: this.authorizedvistor.av_country_id },
    ];
    this.av_city_id_payload = [
      { id: 'av_state_id', value: this.authorizedvistor.av_state_id },
    ];
    if (this.authorizedvistor.av_cplx_id === '-1') {
      this.cplxType = 'NA';
    } else {
      this.app.getQuery('authorizedvistors/get_about_complexes', { id: this.authorizedvistor.av_cplx_id }).then(result => {
        if (result.status === 'OK') {
          if (result.data.length > 0) {
            this.cplxType = result.data[0].cplx_type;
          } else {
            this.cplxType = 'NA';
          }
        }
      });
    }
  }

  public init() {
    this.authorizedvistors = [];
    this.reset();
    this.getAllAuthorizedvistors();
  }

  public reset() {
    this.authorizedvistor = {
      av_id: '-1',
      av_name: '',
      av_last_name: '',
      av_photo: '',
      av_relationship: '',
      av_cplx_id: '-1',
      av_building_id: '-1',
      av_home_id: '-1',
      av_res_id: '-1',
      av_off_id: '-1',
      av_address: '',
      av_country_id: '-1',
      av_state_id: '-1',
      av_city_id: '-1',
      av_zip: '',
      av_phone: '',
      av_mobile: '',
      av_mon_open_time: '',
      av_mon_close_time: '',
      av_mon_closed: 'N',
      av_tue_open_time: '',
      av_tue_close_time: '',
      av_tue_closed: 'N',
      av_wed_open_time: '',
      av_wed_close_time: '',
      av_wed_closed: 'N',
      av_thr_open_time: '',
      av_thr_close_time: '',
      av_thr_closed: 'N',
      av_fri_open_time: '',
      av_fri_close_time: '',
      av_fri_closed: 'N',
      av_sat_open_time: '',
      av_sat_close_time: '',
      av_sat_closed: 'N',
      av_sun_open_time: '',
      av_sun_close_time: '',
      av_sun_closed: 'N',
      av_active: 'Y',
      av_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.av_building_id_search = '-1';
    this.av_home_id_search = '-1';
    this.av_res_id_search = '-1';

    this.av_off_id_search = '-1';
    // if (obj === '-1') {
    //   this.cplxTypeSearch = 'NA';
    // } else {
    //   this.app.getQuery('authorizedvistors/get_about_complexes', {id: obj}).then(result => {
    //     if (result.status === 'OK') {
    //       if (result.data.length > 0) {
    //         this.cplxTypeSearch = result.data[0].cplx_type;
    //       } else {
    //         this.cplxTypeSearch = 'NA';
    //       }
    //     }
    //   });
    // }
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onBuildingIdChangedOnSearch(obj) {
    this.av_home_id_search = '-1';
    this.av_res_id_search = '-1';
    this.av_off_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.av_res_id_search = '-1';
    this.av_off_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onResIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onOffIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.authorizedvistor.av_building_id = '-1';
    this.authorizedvistor.av_home_id = '-1';
    this.authorizedvistor.av_res_id = '-1';
    this.authorizedvistor.av_off_id = '-1';
    // if (obj === '-1') {
    //   this.cplxType = 'NA';
    // } else {
    //   this.app.getQuery('authorizedvistors/get_about_complexes', {id: obj}).then(result => {
    //     if (result.status === 'OK') {
    //       if (result.data.length > 0) {
    //         this.cplxType = result.data[0].cplx_type;
    //       } else {
    //         this.cplxType = 'NA';
    //       }
    //     }
    //   });
    // }
    this.payloadsChanged();
  }
  public onBuildingIdChanged(obj) {
    this.authorizedvistor.av_home_id = '-1';
    this.authorizedvistor.av_res_id = '-1';
    this.authorizedvistor.av_off_id = '-1';
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.authorizedvistor.av_res_id = '-1';
    this.authorizedvistor.av_off_id = '-1';
    this.payloadsChanged();
  }
  public onResIdChanged(obj) {
    this.payloadsChanged();
  }
  public onOffIdChanged(obj) {
    this.payloadsChanged();
  }
  public onCountryIdChanged(obj) {
    this.authorizedvistor.av_state_id = '-1';
    this.authorizedvistor.av_city_id = '-1';
    this.payloadsChanged();
  }
  public onStateIdChanged(obj) {
    this.authorizedvistor.av_city_id = '-1';
    this.payloadsChanged();
  }
  public onCityIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllAuthorizedvistors() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      av_cplx_id_search: this.av_cplx_id_search,


      av_building_id_search: this.av_building_id_search,


      av_home_id_search: this.av_home_id_search,


      av_off_id_search: this.av_off_id_search,

      av_res_id_search: this.av_res_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('authorizedvistors/get_all_authorizedvistors', obj).then(result => {

      
      if (result.status === 'OK') {
        //TODO: debugging - Pedro 
        // console.log(result.data)
        this.authorizedvistors = this.authorizedvistors.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.authorizedvistors.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllAuthorizedvistors();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.authorizedvistor));
    if (itemObj.av_mon_closed === 'NA') {
      itemObj.av_mon_open_time = '00:00:00';
      itemObj.av_mon_close_time = '23:59:00';
      itemObj.av_mon_closed = 'N';
    }
    if (itemObj.av_tue_closed === 'NA') {
      itemObj.av_tue_open_time = '00:00:00';
      itemObj.av_tue_close_time = '23:59:00';
      itemObj.av_tue_closed = 'N';
    }
    if (itemObj.av_wed_closed === 'NA') {
      itemObj.av_wed_open_time = '00:00:00';
      itemObj.av_wed_close_time = '23:59:00';
      itemObj.av_wed_closed = 'N';
    }
    if (itemObj.av_thr_closed === 'NA') {
      itemObj.av_thr_open_time = '00:00:00';
      itemObj.av_thr_close_time = '23:59:00';
      itemObj.av_thr_closed = 'N';
    }
    if (itemObj.av_fri_closed === 'NA') {
      itemObj.av_fri_open_time = '00:00:00';
      itemObj.av_fri_close_time = '23:59:00';
      itemObj.av_fri_closed = 'N';
    }
    if (itemObj.av_sat_closed === 'NA') {
      itemObj.av_sat_open_time = '00:00:00';
      itemObj.av_sat_close_time = '23:59:00';
      itemObj.av_sat_closed = 'N';
    }
    if (itemObj.av_sun_closed === 'NA') {
      itemObj.av_sun_open_time = '00:00:00';
      itemObj.av_sun_close_time = '23:59:00';
      itemObj.av_sun_closed = 'N';
    }
    this.app.getQuery('authorizedvistors/add_edit_authorizedvistor', itemObj).then(result => {
      this.loadingText = 'Save';
      console.table(itemObj);
      this.addLog(itemObj);
      if (result.status === 'OK') {

        $('#myModalav_id').modal('hide');
        this.onClear();

        if(this.oldActiveStatus !== itemObj.av_active) {
          this.addLogService.addLogs(this.user_name, this.isAdmin, `User "${this.user_name}" changed the active status from Authorized visitor named "${itemObj.av_name}" change the active status from "${this.oldActiveStatus}" to "${itemObj.av_active}"`).then(res => {
            console.log(res)
          }).catch(e => {
            console.log(e)
          })
        }

        

        if(result.message === "Authorizedvistor entry updated" && this.oldActiveStatus === itemObj.av_active) {
          this.addLogService.addLogs(this.user_name, this.isAdmin, `User "${this.user_name}"  updated a Authorized vistor named "${itemObj.av_name}"`).then(res => {
            console.log(res);
          })

        }  if(result.message !== "Authorizedvistor entry updated" && this.oldActiveStatus === itemObj.av_active) {
          let location = '';
          if (itemObj.av_building_id && itemObj.av_building_id !== '-1') {
            location = `Building ${itemObj.av_building_id}`;
          } else if (itemObj.av_home_id && itemObj.av_home_id !== '-1') {
            location = `Home ${itemObj.av_home_id}`;
          } else {
            location = 'an unknown location';
          }

          this.addLogService.addLogs(this.user_name, this.isAdmin, `User "${this.user_name}" created an Authorized visitor named "${itemObj.av_name}" at ${location}`)
        }
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }

  


  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModalav_id').modal('show');
    // $('#myModalav_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    if (tempItem.av_mon_open_time === '00:00:00' && tempItem.av_mon_close_time === '23:59:00') {
      tempItem.av_mon_closed = 'NA';
    }
    if (tempItem.av_tue_open_time === '00:00:00' && tempItem.av_tue_close_time === '23:59:00') {
      tempItem.av_tue_closed = 'NA';
    }
    if (tempItem.av_wed_open_time === '00:00:00' && tempItem.av_wed_close_time === '23:59:00') {
      tempItem.av_wed_closed = 'NA';
    }
    if (tempItem.av_thr_open_time === '00:00:00' && tempItem.av_thr_close_time === '23:59:00') {
      tempItem.av_thr_closed = 'NA';
    }
    if (tempItem.av_fri_open_time === '00:00:00' && tempItem.av_fri_close_time === '23:59:00') {
      tempItem.av_fri_closed = 'NA';
    }
    if (tempItem.av_sat_open_time === '00:00:00' && tempItem.av_sat_close_time === '23:59:00') {
      tempItem.av_sat_closed = 'NA';
    }
    if (tempItem.av_sun_open_time === '00:00:00' && tempItem.av_sun_close_time === '23:59:00') {
      tempItem.av_sun_closed = 'NA';
    }
    this.authorizedvistor = tempItem;
    this.oldActiveStatus = this.authorizedvistor.av_active

    this.payloadsChanged();
    $('#myModalav_id').modal('show');
    // $('#myModalav_id').appendTo('body');
  }

  public csvSubmit(ox = '') {
    console.log(ox);
    this.failedData = [];
    // console.log(this.adminSetting);
    this.app.getQuery('authorizedvistors/add_edit_authorizedvistor_by_csv', { csv_file: ox })
      .then(result => {
        console.log(result);
        
        if (result.status === 'OK') {
          this.onChangePage();
          if (result.failed.length > 0) {
            // alert('Uploaded, Duplicates skipped');
            this.failedData = result.failed;
            $('#myModalFailedAuths').modal('show');
          } else {
            alert('Uploaded');
          }
        } else {
          this.onChangePage();
          alert(result.message);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }



  //TODO:Added this to add it to the log
  private addLog(itemObj: any) {
    //TODO: then i need to save this to logs
    let userName = localStorage.getItem("user_name");
    let message = `Administrador "${userName}" added a new Authorized Visitors named "${itemObj.av_name}" for complex "${itemObj.cplx_name}" and home "${itemObj.home_name}"`
    // alert(message);
  }


  public fetchAllVisitsForAuthVisitsPDF() {
    const obj = {
      num_per_page: 40,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      av_cplx_id_search: this.av_cplx_id_search,


      av_building_id_search: this.av_building_id_search,


      av_home_id_search: this.av_home_id_search,


      av_off_id_search: this.av_off_id_search,

      av_res_id_search: this.av_res_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };

    const visitsPDF: any[] = [];
  
    // Show the loading indicator
    this.isLoading = true;
  
    const fetchAllVisitsForAuthVisitsPDF = (startFrom: number) => {
      obj.start_from = startFrom;
  
      this.app.getQuery('authorizedvistors/get_all_authorizedvistors', obj).then(result => {
        if (result.status === 'OK') {
          visitsPDF.push(...result.data);
  
          this.count = result.total;
          this.received = visitsPDF.length;
  
          if (visitsPDF.length < result.total) {
            // Fetch the next batch of visits
            fetchAllVisitsForAuthVisitsPDF(visitsPDF.length);
          } else {
            // All visits are fetched
            this.displayCount = `${visitsPDF.length} of ${this.count}`;
            console.log('All visits have been retrieved for PDF.', visitsPDF);
  
            // Hide the loading indicator
            this.isLoading = false;
  
            // Proceed to generate or save the PDF with the data
            this.generateVisitsPDF(visitsPDF);
          }
        } else {
          console.error('Error fetching visits:', result.message);
  
          // Hide the loading indicator if there's an error
          this.isLoading = false;
        }
      }).catch(error => {
        console.error('Request failed:', error);
  
        // Hide the loading indicator if there's an error
        this.isLoading = false;
      });
    };
  
    // Start fetching all visits from the beginning
    fetchAllVisitsForAuthVisitsPDF(0);
  }
  
  
  private generateVisitsPDF(visits: any[]) {
    console.log("Visits:", visits);
  
    try {
      if (!Array.isArray(visits)) {
        throw new Error("Invalid data: Visits should be an array.");
      }
  
      if (visits.length === 0) {
        throw new Error("No data to generate PDF.");
      }
  
      const doc = new jsPDF({
        orientation: 'landscape', // Set orientation to landscape
        unit: 'mm',
        format: 'a4'
      });
  
      doc.setProperties({
        title: "Visit Data",
        subject: "List of Visits",
        author: "Access Control One",
        keywords: "Visits, pdf",
        creator: "Access Control One"
      });
  
      doc.setFontSize(18);
      doc.text("Visits Data", 14, 15);
  
      const headers = [
        ["Authorized Visitors ID", "Authorized Visitors Name", "Authorized Visitors Date", "Authorized Visitors Home Name", "Authorized Visitors Complex Name", " Authorized Visitors phone Number", "Authorized Visitors Relationship"]
      ];
  
      const rows = visits.map(visit => [
        visit.av_id || 'N/A',
        visit.av_name || 'N/A',
        visit.visit_date || 'N/A',
        visit.home_name || 'N/A',
        visit.cplx_name || 'N/A',
        visit.av_mobile || 'N/A',
        visit.av_relationship || 'N/A'
      ]);
  
      (doc as any).autoTable({
        startY: 25,
        head: headers,
        body: rows,
        columnStyles: {
          0: { cellWidth: 25, halign: 'center' }, // Visit ID
          1: { cellWidth: 35 },                  // Visitor Name
          2: { cellWidth: 35 },                  // Visit Date
          3: { cellWidth: 35 },                  // Home Name
          4: { cellWidth: 55 },                  // Complex Name
          5: { cellWidth: 30 },                  // Purpose
          6: { cellWidth: 40 }                   // Contact Person
        },
        styles: {
          overflow: 'linebreak',
          cellPadding: 2,
          fontSize: 10,
          valign: 'middle'
        },
        margin: { top: 20, left: 10, right: 10 },
        theme: 'striped',
        tableWidth: 'auto', // Ensure the table width adjusts to fit the page
        pageBreak: 'auto', // Automatically handle page breaks
      });
  
      doc.save("Visits_data.pdf");
      alert("PDF for Visits generated successfully");
  
    } catch (error) {
      console.error("Error generating PDF:", error.message);
      alert("Error generating PDF. Please check the console for details.");
    }
  }
}
  
  //TODO: Fix the av pdf 


  // this.authorizedvistor = {
  //   av_id: '-1',
  //   av_name: '',
  //   av_last_name: '',
  //   av_photo: '',
  //   av_relationship: '',
  //   av_cplx_id: '-1',
  //   av_building_id: '-1',
  //   av_home_id: '-1',
  //   av_res_id: '-1',
  //   av_off_id: '-1',
  //   av_address: '',
  //   av_country_id: '-1',
  //   av_state_id: '-1',
  //   av_city_id: '-1',
  //   av_zip: '',
  //   av_phone: '',
  //   av_mobile: '',
  //   av_mon_open_time: '',
  //   av_mon_close_time: '',
  //   av_mon_closed: 'N',
  //   av_tue_open_time: '',
  //   av_tue_close_time: '',
  //   av_tue_closed: 'N',
  //   av_wed_open_time: '',
  //   av_wed_close_time: '',
  //   av_wed_closed: 'N',
  //   av_thr_open_time: '',
  //   av_thr_close_time: '',
  //   av_thr_closed: 'N',
  //   av_fri_open_time: '',
  //   av_fri_close_time: '',
  //   av_fri_closed: 'N',
  //   av_sat_open_time: '',
  //   av_sat_close_time: '',
  //   av_sat_closed: 'N',
  //   av_sun_open_time: '',
  //   av_sun_close_time: '',
  //   av_sun_closed: 'N',
  //   av_active: 'Y',
  //   av_log: '',
  // };