import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-canvas-feed',
  templateUrl: './canvas-feed.component.html',
  styleUrls: ['./canvas-feed.component.css']
})
export class CanvasFeedComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() width = 400;
  @Input() height = 300;
  public imgsrc = '';
  @Output() snap = new EventEmitter<any>();
  private mApiKey = '';
  private mInitSnap = 'N';

  @ViewChild('canvasd') canvasRef!: ElementRef;
  private ctx!: CanvasRenderingContext2D;
  private image = new Image();
  private frameData: any;

  get apiKey(): any{
    return this.mApiKey;
  }

  @Input()
  set apiKey(v: any){
    if (v === ''){
      return
    }
    this.mApiKey = v;
    console.log(this.mApiKey);
    this.socket.on(this.mApiKey, (msg: any) => {
      // console.log(msg);
      this.frameData = msg;
      this.drawframe();
    });

  }

  get initSnap(): any{
    return this.mInitSnap;
  }

  @Input()
  set initSnap(v: any){
    this.mInitSnap = v;
    if (this.mInitSnap === 'Y'){
      this.takeSnap();
    }else if (this.mInitSnap === 'N'){
      this.imgsrc = '';
    }
  }

  constructor(public app: AppService, private socket: Socket) { 
    console.log('canvas feed loaded');
  }

  ngOnInit(): void {
    
    // this.drawframe();
  }

  ngAfterViewInit(): void {
    
    this.ctx = this.canvasRef.nativeElement.getContext('2d');
    this.image.onload = () => {
      this.ctx.drawImage(this.image, 0, 0, this.width, this.height);
    };
    
  }

  drawframe() {
    // requestAnimationFrame(drawframe);
    const uint8Array = new Uint8Array(this.frameData);

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array]);

    // Create a data URL from the Blob
    const base64String = URL.createObjectURL(blob);
    // requestAnimationFrame(this.drawframe);
    // const base64String = btoa(String.fromCharCode(...new Uint8Array(this.frameData)));
    // console.log(`data:image/jpeg;base64,${base64String}`);
    this.image.src = base64String;
  }

  public takeSnap(){
    this.generatePhotoUrl(this.canvasRef.nativeElement.toDataURL());
  }

  private generatePhotoUrl(base64) {
    this.imgsrc = base64;
    this.app.getQuery('clients/get_photo_save', { photo: base64 }).then(result => {
      console.log(result);
      if (result.status === 'OK') {
        this.snap.emit(result.file_name);
      }
    });
  }

  ngOnDestroy(): void {
    
  }

}
