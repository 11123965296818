import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConsoleComponent } from './console/console.component';
import { LoginComponent } from './login/login.component';
import { ActivitiesComponent } from './activities/activities.component';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { AuthorizedvistorsComponent } from './authorizedvistors/authorizedvistors.component';
import { BillingPlansComponent } from './billing-plans/billing-plans.component';
import { BuildingsComponent } from './buildings/buildings.component';
import { ComplexesComponent } from './complexes/complexes.component';
import { CustomerUsersComponent } from './customer-users/customer-users.component';
import { CustomersComponent } from './customers/customers.component';
import { EmailTemplatesComponent } from './email-templates/email-templates.component';
import { EmergencyAgenciesComponent } from './emergency-agencies/emergency-agencies.component';
import { EmployeesComponent } from './employees/employees.component';
import { HelpCategoriesComponent } from './help-categories/help-categories.component';
import { HelpsComponent } from './helps/helps.component';
import { HomesComponent } from './homes/homes.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { LocationsComponent } from './locations/locations.component';
import { MmsProvidersComponent } from './mms-providers/mms-providers.component';
import { OfficesComponent } from './offices/offices.component';
import { ResidentsComponent } from './residents/residents.component';
import { SalesComponent } from './sales/sales.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SupplierUsersComponent } from './supplier-users/supplier-users.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { TranslationsComponent } from './translations/translations.component';
import { TranslationsAppComponent } from './translations-app/translations-app.component';
import { TwiliosComponent } from './twilios/twilios.component';
import { UserLevelsComponent } from './user-levels/user-levels.component';
import { UsersComponent } from './users/users.component';
import { VisitsComponent } from './visits/visits.component';
import { RegisterComponent } from './register/register.component';
import { InformationComponent } from './information/information.component';
import { TranslationMgrComponent } from './translation-mgr/translation-mgr.component';
import { TranslationMgrAppComponent } from './translation-mgr-app/translation-mgr-app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ActivitesCalendarComponent } from './activites-calendar/activites-calendar.component';
import { SmtpComponent } from './smtp/smtp.component';
import { InfoGenComponent } from './info-gen/info-gen.component';
import { InfoSuppliersComponent } from './info-suppliers/info-suppliers.component';
import { InfoEmgyAgenciesComponent } from './info-emgy-agencies/info-emgy-agencies.component';
import { ShortCodesComponent } from './short-codes/short-codes.component';
import { StripesComponent } from './stripes/stripes.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { RegistersComponent } from './registers/registers.component';
import { RedictLoginComponent } from './redict-login/redict-login.component';
import { ArchivalRecordsComponent } from './archival-records/archival-records.component';
import { ShippingServicesComponent } from './shipping-services/shipping-services.component';
import { BulletinBoardsComponent } from './bulletin-boards/bulletin-boards.component';
import { CommunitySuppliersComponent } from './community-suppliers/community-suppliers.component';
import { CommunitySuppliersCategoriesComponent } from './community-suppliers-categories/community-suppliers-categories.component';
import { BannersComponent } from './banners/banners.component';
import { TicketConversationsComponent } from './ticket-conversations/ticket-conversations.component';
import { TicketsComponent } from './tickets/tickets.component';
import { EmergenciesComponent } from './emergencies/emergencies.component';
import { TicketsCategoriesComponent } from './tickets-categories/tickets-categories.component';
import { WebRegistrationsComponent } from './web-registrations/web-registrations.component';
import { BulletinBoardsreqsComponent } from './bulletin-boardsreqs/bulletin-boardsreqs.component';
import { CommunitySuppliersreqsComponent } from './community-suppliersreqs/community-suppliersreqs.component';
import { RegistrationsComponent } from './registrations/registrations.component';
import { RegistrationsEmailComponent } from './registrations-email/registrations-email.component';
import { LicensePlateReportsComponent } from './license-plate-reports/license-plate-reports.component';
import { MasterResetsComponent } from './master-resets/master-resets.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AppFeedbacksComponent } from './app-feedbacks/app-feedbacks.component';
import { DocumentsComponent } from './documents/documents.component';
import { IncidentsComponent } from './incidents/incidents.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { LogsComponent } from './logs/logs.component';
import { PackageLogsComponent } from './package-logs/package-logs.component';
import { WebServicesComponent } from './web-services/web-services.component';
import { WsLogsComponent } from './ws-logs/ws-logs.component';
import { ClientPasswordRecoveryComponent } from './client-password-recovery/client-password-recovery.component';
import { AuthGuard } from './Guards/auth.guard';
/**loopimportshere*/

/*
const routes: Routes = [
  { path: '', redirectTo: 'console', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'console', component: ConsoleComponent,
    children: [
      { path: '', redirectTo: 'activities', pathMatch: 'full' },
            { path: 'activities', component: ActivitiesComponent },
            { path: 'adminsettings', component: AdminSettingsComponent },
            { path: 'authorizedvistors', component: AuthorizedvistorsComponent },
            { path: 'billingplans', component: BillingPlansComponent },
            { path: 'buildings', component: BuildingsComponent },
            { path: 'complexes', component: ComplexesComponent },
            { path: 'customerusers', component: CustomerUsersComponent },
            { path: 'customers', component: CustomersComponent },
            { path: 'emailtemplates', component: EmailTemplatesComponent },
            { path: 'emergencyagencies', component: EmergencyAgenciesComponent },
            { path: 'employees', component: EmployeesComponent },
            { path: 'helpcategories', component: HelpCategoriesComponent },
            { path: 'helps', component: HelpsComponent },
            { path: 'homes', component: HomesComponent },
            { path: 'invoices', component: InvoicesComponent },
            { path: 'locations', component: LocationsComponent },
            { path: 'mmsproviders', component: MmsProvidersComponent },
            { path: 'offices', component: OfficesComponent },
            { path: 'residents', component: ResidentsComponent },
            { path: 'sales', component: SalesComponent },
            { path: 'subscriptions', component: SubscriptionsComponent },
            { path: 'supplierusers', component: SupplierUsersComponent },
            { path: 'suppliers', component: SuppliersComponent },
            { path: 'translations', component: TranslationsComponent },
            { path: 'translationsapp', component: TranslationsAppComponent },
            { path: 'twilios', component: TwiliosComponent },
            { path: 'userlevels', component: UserLevelsComponent },
            { path: 'users', component: UsersComponent },
            { path: 'visits', component: VisitsComponent },
            { path: '**', redirectTo: 'activities' }
    ]
  },
  { path: '**', redirectTo: 'console' }
];
*/
const routes: Routes = [
  { path: '', redirectTo: 'console', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  { path: 'password-reset/:resident_id', component: PasswordResetComponent },
  { path: 'registrations/:id', component: RegistrationsEmailComponent },
  { path: 'clientpwd/:id', component: ClientPasswordRecoveryComponent },

  {
    path: 'redirect', component: RedictLoginComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: '**', redirectTo: 'login' }
    ]
  },
  {
    path: 'console', component: ConsoleComponent,
    children: [
      { path: '', redirectTo: 'information', pathMatch: 'full' },
      { path: 'register', component: RegistersComponent },
      {
        path: 'information', component: InformationComponent,
        children: [
          { path: '', redirectTo: 'gen', pathMatch: 'full' },
          { path: 'gen', component: InfoGenComponent },
          { path: 'suppliers', component: InfoSuppliersComponent },
          { path: 'emergency_agencies', component: InfoEmgyAgenciesComponent },
          { path: 'activity_calendar', component: ActivitesCalendarComponent },
          { path: 'translationmgr', component: TranslationMgrComponent },
          { path: 'translationmgrapp', component: TranslationMgrAppComponent },
          { path: '**', redirectTo: 'general' },
        ]
      },
      {
        path: 'settings', component: InformationComponent,
        children: [
          { path: '', redirectTo: 'translationmgr', pathMatch: 'full' },
          { path: 'residents', component: ResidentsComponent },
          { path: 'translationmgr', component: TranslationMgrComponent },
          { path: 'translationmgrapp', component: TranslationMgrAppComponent },
          { path: 'gen', component: AdminSettingsComponent },
          { path: 'customers', component: CustomersComponent },
          { path: 'customerusers', component: CustomerUsersComponent },
          { path: 'authorized-visitors', component: AuthorizedvistorsComponent },
          { path: 'employees', component: EmployeesComponent },
          // { path: 'employee_vehicles', component: Emplo },
          { path: 'short_codes', component: ShortCodesComponent },
          { path: 'buildings', component: BuildingsComponent },
          { path: 'smtp_settings', component: SmtpComponent },
          { path: 'offices', component: OfficesComponent },
          { path: 'homes', component: HomesComponent },
          { path: 'invoices', component: InvoicesComponent },
          { path: 'locations', component: LocationsComponent },
          { path: 'mmsproviders', component: MmsProvidersComponent },
          { path: 'vehicles', component: VehiclesComponent },
          { path: 'residents', component: ResidentsComponent },
          { path: 'sales', component: SalesComponent },
          { path: 'subscriptions', component: SubscriptionsComponent },
          // { path: 'shipping_services', component: ShippingServicesComponent },
          { path: 'supplierusers', component: SupplierUsersComponent },
          { path: 'suppliers', component: SuppliersComponent },
          { path: 'templates', component: EmailTemplatesComponent },
          { path: 'smtp', component: AdminSettingsComponent },
          { path: 'mms', component: MmsProvidersComponent },
          { path: 'users', component: UsersComponent },
          { path: 'suppliers', component: SuppliersComponent },
          { path: 'emergencyagencies', component: EmergencyAgenciesComponent },
          { path: 'supplier_users', component: SupplierUsersComponent },
          { path: 'billing_plans', component: BillingPlansComponent },
          { path: 'twilio', component: TwiliosComponent },
          { path: 'activities', component: ActivitiesComponent },
          { path: 'subscriptions', component: SubscriptionsComponent },
          { path: 'complexes', component: ComplexesComponent },
          { path: 'stripe', component: StripesComponent },
          { path: 'visits', component: VisitsComponent },
          { path: 'archival_records', component: ArchivalRecordsComponent },
          { path: 'bulletin_boards', component: BulletinBoardsComponent },
          { path: 'community_suppliers', component: CommunitySuppliersComponent },
          { path: 'communitysupplierscategories', component: CommunitySuppliersCategoriesComponent },
          { path: 'banners', component: BannersComponent },
          { path: 'ticketconversations', component: TicketConversationsComponent },
          { path: 'tickets', component: TicketsComponent },
          { path: 'emergency_req', component: EmergenciesComponent },
          { path: 'emergency_handle/:id', component: EmergenciesComponent },
          { path: 'ticketscategories', component: TicketsCategoriesComponent },
          { path: 'webregistrations', component: WebRegistrationsComponent },
          { path: 'bulletinboardsreqs', component: BulletinBoardsreqsComponent },
          { path: 'communitysuppliersreqs', component: CommunitySuppliersreqsComponent },
          { path: 'registrations', component: RegistrationsComponent },
          { path: 'licenseplatesearch', component: LicensePlateReportsComponent },
          { path: 'master_reset', component: MasterResetsComponent },
          { path: 'app_feedbacks', component: AppFeedbacksComponent },
          { path: 'documents', component: DocumentsComponent },
          { path: 'incidents', component: IncidentsComponent },
          { path: 'appointments', component: AppointmentsComponent },
          { path: 'logs', component: LogsComponent },
          { path: 'packagelogs', component: PackageLogsComponent },
          { path: 'webservices', component: WebServicesComponent },
          { path: 'wslogs', component: WsLogsComponent },
          /**looppathshere*/
          { path: '**', redirectTo: 'translationmgr' },
        ]
      },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'help', component: HelpsComponent },
      { path: '**', redirectTo: 'information' }
    ]
  },
  { path: '**', redirectTo: 'console' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
/*
*/



