
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { AddlogService } from '../Services/addlog.service';
import { constructDependencies } from '@angular/core/src/di/reflective_provider';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-homes',
  templateUrl: './homes.component.html',
  styleUrls: ['./homes.component.css']
})
export class HomesComponent implements OnInit, OnDestroy, AfterViewInit {

  public adminSetting = {cust_csv: ''};
  public failedData = [];
  vehicles = [];
  public editVehicle = 'N';
  public isAdmin = localStorage.getItem('user_level');

  constructor(public app: AppService, private router: Router, private location: Location, private addLogService: AddlogService) {

  }

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public homes = [];
  public isLoading = false
  public user_level = localStorage.getItem('user_level');
  public user_name = localStorage.getItem('user_name');
  private oldActiveStatus: string = ""


  public home: any = {
    home_id: '-1',
    home_name: '',
    home_cplx_id: '-1',
    home_building_id: '-1',
    home_phone1: '',
    home_phone2: '',
    home_tele_entry_code: '',
    home_active: 'Y',
    home_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'home_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public home_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public home_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public home_building_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public home_building_id_payload_search = [
    { id: 'home_cplx_id', value: '-1' },
  ];





  // tslint:disable-next-line:variable-name
  public home_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public home_building_id_payload = [
    { id: 'home_cplx_id', value: '-1' },
  ];




  public message = '';
  public logs = [];
  public browse = 'Browse';
  public isGatedSearch = 'N';
  public isGated = 'N';

  ngOnInit() {
    // this.getAllHomesPDF()
    //TODO:FUnction here
    // this.fetchHomesForPDFv2()
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

    this.home_building_id_payload_search = [
      { id: 'home_cplx_id', value: this.home_cplx_id_search },
    ];

  }

  public payloadsChanged() {
    this.home_building_id_payload = [
      { id: 'home_cplx_id', value: this.home.home_cplx_id },
    ];
  }

  public init() {
    this.homes = [];
    this.reset();
    this.getAllHomes();
  }

  public reset() {
    this.home = {
      home_id: '-1',
      home_name: '',
      home_cplx_id: '-1',
      home_building_id: '-1',
      home_phone1: '',
      home_phone2: '',
      home_tele_entry_code: '',
      home_active: 'Y',
      home_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }
  public onCplxIdChangedOnSearch(obj) {
    this.home_building_id_search = '-1';
    this.app.getQuery('homes/get_complex_type', { home_cplx_id: obj }).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.isGatedSearch = 'N';
      } else {
        this.isGatedSearch = 'Y';
      }
    });
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onBuildingIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.home.home_building_id = '-1';
    this.app.getQuery('homes/get_complex_type', { home_cplx_id: obj }).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.isGated = 'N';
      } else {
        this.isGated = 'Y';
      }
    });
    this.payloadsChanged();
  }
  public onBuildingIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllHomes() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      home_cplx_id_search: this.home_cplx_id_search,


      home_building_id_search: this.home_building_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('homes/get_all_homes', obj).then(result => {

      console.log(result.data);
      if (result.status === 'OK') {
        this.homes = this.homes.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.homes.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  // private fetchHomesForPDFv2() {
  //   let homesForPDF = [];
  
  //   // Make a request to get all homes
  //   this.app.getQuery('homes/get_all_homes', {
  //     num_per_page: '1000', 
  //     order_by: this.order_by,
  //     order: this.order,
  //     start_from: 0,
  //     home_cplx_id_search: this.home_cplx_id_search,
  //     home_building_id_search: this.home_building_id_search,
  //     search: this.search,
  //     active: this.actfilter,
  //     inactive: this.inactfilter
  //   }).then(result => {
  //     if (result.status === 'OK') {
  //       homesForPDF = result.data;
  //       console.log(homesForPDF);
  
  //       let obj = {};
  //       for (let i in result.data) {
  //         obj = {
  //           "Home Name": result.data[i].home_name,
  //           "Address": result.data[i].home_address,
  //           // Add other home properties you want to include
  //         };
  //         console.log(result.data[i].home_name);
  //       }
  
  //       // You can now use homesForPDF to generate the PDF or for further processing
  //     } else {
  //       // Handle error
  //       console.error('Error fetching homes:', result.message);
  //     }
  //   }).catch(error => {
  //     console.error('Request failed:', error);
  //   });
  // }
  


  
  //TODO:this way is recursive it loops to the end of the list of homes, but it takes some time how can i do it faster 
  public getAllHomesPDF() {
    const obj = {
      num_per_page: '40',
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      home_cplx_id_search: this.home_cplx_id_search,
      home_building_id_search: this.home_building_id_search,
      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
  
    const homesPDF: any[] = [];
  
    // Show the loading indicator
    this.isLoading = true;
  
    const fetchAllHomes = (startFrom: number) => {
      obj.start_from = startFrom;
  
      this.app.getQuery('homes/get_all_homes', obj).then(result => {
        if (result.status === 'OK') {
          homesPDF.push(...result.data);
  
          this.count = result.total;
          this.received = homesPDF.length;
  
          if (homesPDF.length < result.total) {
            // Fetch the next batch of homes
            fetchAllHomes(homesPDF.length);
          } else {
            // All homes are fetched
            this.displayCount = `${homesPDF.length} of ${this.count}`;
            console.log('All homes have been retrieved for PDF.', homesPDF);
  
            // Hide the loading indicator
            this.isLoading = false;
  
            // Proceed to generate or save the PDF with the data
            this.savePDF(homesPDF);
          }
        } else {
          console.error('Error fetching homes:', result.message);
  
          // Hide the loading indicator if there's an error
          this.isLoading = false;
        }
      }).catch(error => {
        console.error('Request failed:', error);
  
        // Hide the loading indicator if there's an error
        this.isLoading = false;
      });
    };
  
    // Start fetching all homes from the beginning
    fetchAllHomes(0);
  }
  
  

  private savePDF(homeArray: any[]) {
    console.log("Homes:", homeArray);
  
    try {
      if (!Array.isArray(homeArray)) {
        throw new Error("Invalid data: HomePDF should be an array.");
      }
  
      if (homeArray.length === 0) {
        throw new Error("No data to generate PDF.");
      }
  
      const doc = new jsPDF();
  
      doc.setProperties({
        title: "Home Data",
        subject: "List of Homes",
        author: "Access Control One",
        keywords: "Homes, pdf",
        creator: "Access Control One"
      });
  
      doc.setFontSize(18);
      doc.text("Homes Data", 14, 15);
  
      const headers = [
        ["Complex Address", "Home Name", "Contact Name", "Complex Name", "Complex ID"]
      ];
  
      const rows = homeArray.map(home => [
        home.cplx_address || 'N/A',
        home.home_name || 'N/A',
        home.cplx_contact_name || 'N/A',
        home.cplx_name || 'N/A',
        home.cplx_id || 'N/A'
      ]);
  
      (doc as any).autoTable({
        startY: 25,
        head: headers,
        body: rows,
      });
  
      // doc.save("Home_data.pdf");
      // alert("PDF for Homes generated successfully");
  
    } catch (error) {
      console.error("Error generating PDF:", error.message);
      alert("Error generating PDF. Please check the console for details.");
    }
  }
  
  

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllHomes();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.home));
  
    // Track previous values
    const previousHomeName = this.home.home_name;
    const previousActiveStatus = this.home.home_active;
  
    this.app.getQuery('homes/add_edit_home', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
  
      if (result.status === 'OK') {
        $('#myModal').modal('hide');

        if(this.oldActiveStatus !== itemObj.home_active) {
          this.addLogService.addLogs(this.user_name, this.user_level, `User ${this.user_name} changed active status for residence "${itemObj.home_name}" from ${this.oldActiveStatus} to ${itemObj.home_active}`).then(res => {
            console.log(res)
          }).catch(e => {
            console.log(e)
          })
        }
  
        // Log creation or update
        if (result.message === "Home entry updated") {
          this.addLogService.addLogs(this.user_name, this.user_level, `User "${this.user_name}" updated the home with ID "${itemObj.home_id}" and name "${itemObj.home_name}".`).then(logResult => {
            console.log(logResult);
          });
        } else {
          this.addLogService.addLogs(this.user_name, this.user_level, `User "${this.user_name}" created a new home with ID "${itemObj.home_id}" and name "${itemObj.home_name}".`).then(logResult => {
            console.log(logResult);
          });
        }
  
        // // Check if both home_name and home_active status have changed first
        // if (previousHomeName !== itemObj.home_name && previousActiveStatus !== itemObj.home_active) {
        //   const bothChangesMessage = `Home with ID "${itemObj.home_id}" has been updated: Name changed from "${previousHomeName}" to "${itemObj.home_name}" and status changed to "${itemObj.home_active}".`;
        //   this.addLogService.addLogs(this.user_name, this.user_level, bothChangesMessage).then(logResult => {
        //     console.log(logResult);
        //   });
        // } 
        // else {
        //   // Check if home_name has changed
        //   if (previousHomeName !== itemObj.home_name) {
        //     const nameChangeMessage = `Home name has been changed from "${previousHomeName}" to "${itemObj.home_name}".`;
        //     this.addLogService.addLogs(this.user_name, this.user_level, nameChangeMessage).then(logResult => {
        //       console.log(logResult);
        //     });
        //   }
  
        //   // Check if home_active status has changed
        //   if (previousActiveStatus !== itemObj.home_active) {
        //     const statusChangeMessage = itemObj.home_active === 'Y'
        //       ? `Home with ID "${itemObj.home_id}" has been activated.`
        //       : `Home with ID "${itemObj.home_id}" has been deactivated.`;
        //     this.addLogService.addLogs(this.user_name, this.user_level, statusChangeMessage).then(logResult => {
        //       console.log(logResult);
        //     });
        //   }
        // }
  
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }
  
  


  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.home = tempItem;
    this.oldActiveStatus = this.home.home_active;

    this.payloadsChanged();
    $('#myModal').modal('show');
  }

  public editItemVehicles(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.home = tempItem;

    this.payloadsChanged();
    this.editVehicle = 'Y';
    // $('#myModalVehiclesView').modal('show');
  }

  public onEditVehicleBack() {
    this.editVehicle = 'N';
  }


  public csvSubmit(ox = '') {
    console.log(ox);
    this.failedData = [];
    // console.log(this.adminSetting);
    this.app.getQuery('homes/add_edit_homes_by_csv', { csv_file: ox })
      .then(result => {
        console.log(result);
        if (result.status === 'OK') {
          this.onChangePage();
          if (result.failed.length > 0) {
            // alert('Uploaded, Duplicates skipped');
            this.failedData = result.failed;
            $('#myModalFailedHomes').modal('show');
          } else {
            alert('Uploaded');
          }
        } else {
          this.onChangePage();
          alert(result.message);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }

  public getAllVehicles(item) {
    const obj = {
      num_per_page: 500,
      order_by: 'vehicle_id',
      order: 'ASC',
      start_from: 0,

      vehicle_cplx_id_search: item.home_cplx_id,


      vehicle_building_id_search: item.home_building_id,


      vehicle_off_id_search: '-1',


      vehicle_home_id_search: item.home_id,

      search: '',
      active: '',
      inactive: ''
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('vehicles/get_all_vehicles', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.vehicles = result.data;

      } else {

      }
    });
  }


}



