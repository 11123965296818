import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;
@Component({
  selector: 'app-authorised-visitors-bkp',
  templateUrl: './authorised-visitors-bkp.component.html',
  styleUrls: ['./authorised-visitors-bkp.component.css']
})
export class AuthorisedVisitorsBkpComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public authorizedvistors = [];

  public authorizedvistor: any = {
    av_id: '-1',
    av_name: '',
    av_last_name: '',
    av_photo: '',
    av_relationship: '',
    av_cplx_id: '-1',
    av_building_id: '-1',
    av_home_id: '-1',
    av_off_id: '-1',
    av_address: '',
    av_country_id: '-1',
    av_state_id: '-1',
    av_city_id: '-1',
    av_zip: '',
    av_phone: '',
    av_mobile: '',
    av_mon_open_time: '',
    av_mon_close_time: '',
    av_mon_closed: 'N',
    av_tue_open_time: '',
    av_tue_close_time: '',
    av_tue_closed: 'N',
    av_wed_open_time: '',
    av_wed_close_time: '',
    av_wed_closed: 'N',
    av_thr_open_time: '',
    av_thr_close_time: '',
    av_thr_closed: 'N',
    av_fri_open_time: '',
    av_fri_close_time: '',
    av_fri_closed: 'N',
    av_sat_open_time: '',
    av_sat_close_time: '',
    av_sat_closed: 'N',
    av_sun_open_time: '',
    av_sun_close_time: '',
    av_sun_closed: 'N',
    av_active: 'Y',
    av_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'av_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public av_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public av_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public av_building_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public av_building_id_payload_search = [
    { id: 'av_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public av_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public av_home_id_payload_search = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_building_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public av_off_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public av_off_id_payload_search = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_building_id', value: '-1' },
  ];








  // tslint:disable-next-line:variable-name
  public av_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public av_building_id_payload = [
    { id: 'av_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public av_home_id_payload = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_building_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public av_off_id_payload = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_building_id', value: '-1' },
  ];



  // tslint:disable-next-line:variable-name
  public av_country_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public av_state_id_payload = [
    { id: 'av_country_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public av_city_id_payload = [
    { id: 'av_state_id', value: '-1' },
  ];


  public cplxTypeSearch = 'NA';
  public cplxType = 'NA';



  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

    this.av_building_id_payload_search = [
      { id: 'av_cplx_id', value: this.av_cplx_id_search },
    ];

    this.av_home_id_payload_search = [
      { id: 'av_cplx_id', value: this.av_cplx_id_search },
      { id: 'av_building_id', value: this.av_building_id_search },
    ];

    this.av_off_id_payload_search = [
      { id: 'av_cplx_id', value: this.av_cplx_id_search },
      { id: 'av_building_id', value: this.av_building_id_search },
    ];

    if (this.authorizedvistor.av_cplx_id_search === '-1') {
      this.cplxTypeSearch = 'NA';
    } else {
      this.app.getQuery('authorizedvistors/get_about_complexes', { id: this.av_cplx_id_search }).then(result => {
        if (result.status === 'OK') {
          if (result.data.length > 0) {
            this.cplxTypeSearch = result.data[0].cplx_type;
          } else {
            this.cplxTypeSearch = 'NA';
          }
        }
      });
    }

  }

  public payloadsChanged() {
    this.av_building_id_payload = [
      { id: 'av_cplx_id', value: this.authorizedvistor.av_cplx_id },
    ];
    this.av_home_id_payload = [
      { id: 'av_cplx_id', value: this.authorizedvistor.av_cplx_id },
      { id: 'av_building_id', value: this.authorizedvistor.av_building_id },
    ];
    this.av_off_id_payload = [
      { id: 'av_cplx_id', value: this.authorizedvistor.av_cplx_id },
      { id: 'av_building_id', value: this.authorizedvistor.av_building_id },
    ];
    this.av_state_id_payload = [
      { id: 'av_country_id', value: this.authorizedvistor.av_country_id },
    ];
    this.av_city_id_payload = [
      { id: 'av_state_id', value: this.authorizedvistor.av_state_id },
    ];
    if (this.authorizedvistor.av_cplx_id === '-1') {
      this.cplxType = 'NA';
    } else {
      this.app.getQuery('authorizedvistors/get_about_complexes', { id: this.authorizedvistor.av_cplx_id }).then(result => {
        if (result.status === 'OK') {
          if (result.data.length > 0) {
            this.cplxType = result.data[0].cplx_type;
          } else {
            this.cplxType = 'NA';
          }
        }
      });
    }
  }

  public init() {
    this.authorizedvistors = [];
    this.reset();
    this.getAllAuthorizedvistors();
  }

  public reset() {
    this.authorizedvistor = {
      av_id: '-1',
      av_name: '',
      av_last_name: '',
      av_photo: '',
      av_relationship: '',
      av_cplx_id: '-1',
      av_building_id: '-1',
      av_home_id: '-1',
      av_off_id: '-1',
      av_address: '',
      av_country_id: '-1',
      av_state_id: '-1',
      av_city_id: '-1',
      av_zip: '',
      av_phone: '',
      av_mobile: '',
      av_mon_open_time: '',
      av_mon_close_time: '',
      av_mon_closed: 'N',
      av_tue_open_time: '',
      av_tue_close_time: '',
      av_tue_closed: 'N',
      av_wed_open_time: '',
      av_wed_close_time: '',
      av_wed_closed: 'N',
      av_thr_open_time: '',
      av_thr_close_time: '',
      av_thr_closed: 'N',
      av_fri_open_time: '',
      av_fri_close_time: '',
      av_fri_closed: 'N',
      av_sat_open_time: '',
      av_sat_close_time: '',
      av_sat_closed: 'N',
      av_sun_open_time: '',
      av_sun_close_time: '',
      av_sun_closed: 'N',
      av_active: 'Y',
      av_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.av_building_id_search = '-1';
    this.av_home_id_search = '-1';
    this.av_off_id_search = '-1';
    // if (obj === '-1') {
    //   this.cplxTypeSearch = 'NA';
    // } else {
    //   this.app.getQuery('authorizedvistors/get_about_complexes', {id: obj}).then(result => {
    //     if (result.status === 'OK') {
    //       if (result.data.length > 0) {
    //         this.cplxTypeSearch = result.data[0].cplx_type;
    //       } else {
    //         this.cplxTypeSearch = 'NA';
    //       }
    //     }
    //   });
    // }
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onBuildingIdChangedOnSearch(obj) {
    this.av_home_id_search = '-1';
    this.av_off_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onOffIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.authorizedvistor.av_building_id = '-1';
    this.authorizedvistor.av_home_id = '-1';
    this.authorizedvistor.av_off_id = '-1';
    // if (obj === '-1') {
    //   this.cplxType = 'NA';
    // } else {
    //   this.app.getQuery('authorizedvistors/get_about_complexes', {id: obj}).then(result => {
    //     if (result.status === 'OK') {
    //       if (result.data.length > 0) {
    //         this.cplxType = result.data[0].cplx_type;
    //       } else {
    //         this.cplxType = 'NA';
    //       }
    //     }
    //   });
    // }
    this.payloadsChanged();
  }
  public onBuildingIdChanged(obj) {
    this.authorizedvistor.av_home_id = '-1';
    this.authorizedvistor.av_off_id = '-1';
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.payloadsChanged();
  }
  public onOffIdChanged(obj) {
    this.payloadsChanged();
  }
  public onCountryIdChanged(obj) {
    this.authorizedvistor.av_state_id = '-1';
    this.authorizedvistor.av_city_id = '-1';
    this.payloadsChanged();
  }
  public onStateIdChanged(obj) {
    this.authorizedvistor.av_city_id = '-1';
    this.payloadsChanged();
  }
  public onCityIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllAuthorizedvistors() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      av_cplx_id_search: this.av_cplx_id_search,


      av_building_id_search: this.av_building_id_search,


      av_home_id_search: this.av_home_id_search,


      av_off_id_search: this.av_off_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('authorizedvistors/get_all_authorizedvistors', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.authorizedvistors = this.authorizedvistors.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.authorizedvistors.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllAuthorizedvistors();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.authorizedvistor));
    this.app.getQuery('authorizedvistors/add_edit_authorizedvistor', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModalav_id').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModalav_id').modal('show');
    // $('#myModalav_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.authorizedvistor = tempItem;

    this.payloadsChanged();
    $('#myModalav_id').modal('show');
    // $('#myModalav_id').appendTo('body');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



