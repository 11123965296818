import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;
@Component({
  selector: 'app-registrations-email',
  templateUrl: './registrations-email.component.html',
  styleUrls: ['./registrations-email.component.css']
})
export class RegistrationsEmailComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public loaded = 'N';
  public registrations = [];

  public registration: any = {
    registration_id: '-1',
    registration_cplx_id: '-1',
    registration_building_id: '-1',
    registration_home_id: '-1',
    registration_firstname: '',
    registration_lastname: '',
    registration_email: '',
    registration_password: '',
    registration_status: '',
    registration_active: 'Y',
    registration_log: '',
    registration_emailstatus: '',
    registration_approvestatus: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'registration_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public registration_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public registration_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public registration_building_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public registration_building_id_payload_search = [
    { id: 'registration_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public registration_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public registration_home_id_payload_search = [
    { id: 'registration_cplx_id', value: '-1' },
    { id: 'registration_building_id', value: '-1' },
  ];




  // tslint:disable-next-line:variable-name
  public registration_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public registration_building_id_payload = [
    { id: 'registration_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public registration_home_id_payload = [
    { id: 'registration_cplx_id', value: '-1' },
    { id: 'registration_building_id', value: '-1' },
  ];









  public message = '';
  public logs = [];
  public browse = 'Browse';
  public cplxType = 'N';


  constructor(public app: AppService, private route: ActivatedRoute, private location: Location) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      console.log(params.id);
      if (params) {
        this.getAllRegistrations(params.id);


      }
    });
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {

  }

  public searchPayloadsChanged() {

    this.registration_building_id_payload_search = [
      { id: 'registration_cplx_id', value: this.registration_cplx_id_search },
    ];

    this.registration_home_id_payload_search = [
      { id: 'registration_cplx_id', value: this.registration_cplx_id_search },
      { id: 'registration_building_id', value: this.registration_building_id_search },
    ];

  }

  public payloadsChanged() {
    this.registration_building_id_payload = [
      { id: 'registration_cplx_id', value: this.registration.registration_cplx_id },
    ];
    this.registration_home_id_payload = [
      { id: 'registration_cplx_id', value: this.registration.registration_cplx_id },
      { id: 'registration_building_id', value: this.registration.registration_building_id },
    ];
  }

  public init() {
    this.registrations = [];
    this.reset();
    this.getAllRegistrations();
  }

  public reset() {
    this.registration = {
      registration_id: '-1',
      registration_cplx_id: '-1',
      registration_building_id: '-1',
      registration_home_id: '-1',
      registration_firstname: '',
      registration_lastname: '',
      registration_email: '',
      registration_password: '',
      registration_status: 'registered',
      registration_active: 'Y',
      registration_log: '',
      registration_emailstatus: '',
      registration_approvestatus: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.registration_building_id_search = '-1';
    this.registration_home_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onBuildingIdChangedOnSearch(obj) {
    this.registration_home_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.registration.registration_building_id = '-1';
    this.registration.registration_home_id = '-1';
    this.payloadsChanged();
  }
  public onBuildingIdChanged(obj) {
    this.registration.registration_home_id = '-1';
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllRegistrations(registrationId = '-1') {

    this.loaded = 'N';
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      registration_id: registrationId,

      registration_cplx_id_search: this.registration_cplx_id_search,


      registration_building_id_search: this.registration_building_id_search,


      registration_home_id_search: this.registration_home_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('registrationsemail/get_all_registrations', obj).then(result => {

      console.log(result);
      this.loaded = 'Y';
      if (result.data.length > 0) {
        if (result.status === 'OK') {
          // tslint:disable-next-line:max-line-length
          this.app.getQuery('registrationsemail/get_selected_complexes_for_init', { id: result.data[0].registration_cplx_id }).then(resultC => {
            console.log(resultC);
            if (resultC.status === 'OK') {
              if (resultC.data.length > 0) {
                this.cplxType = resultC.data[0].cplx_type;
                setTimeout(() => {
                  this.editItem(result.data[0]);
                }, 100);

              }
            }
          });


          this.registrations = this.registrations.concat(result.data);
          this.count = result.total;
          this.received = result.received;
          this.dbPages = [];
          // this.activePage = 1;
          for (let index = 0; index < result.pages; index++) {
            this.dbPages.push(index);
          }
          this.displayCount = (this.registrations.length) + ' of ' + this.count;
        }
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllRegistrations();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.registration));
    this.app.getQuery('registrationsemail/add_edit_registration', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModalregistration_id').modal('hide');
        this.onClear();
        this.message = 'Sent for approval';
        // setTimeout(() => {
        //   this.message = '';
        // }, 4000);
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModalregistration_id').modal('show');
    // $('#myModalregistration_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    tempItem.registration_password = '';
    tempItem.registration_password_rep = '';

    this.registration = tempItem;

    this.payloadsChanged();
    $('#myModalregistration_id').modal('show');
    // $('#myModalregistration_id').appendTo('body');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



