
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';


import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { AddlogService } from '../Services/addlog.service';



declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.css']
})
export class VisitsComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public isAdmin = localStorage.getItem("user_level");
  public user_name = localStorage.getItem('user_name');

  public visits = [];

  public visit: any = {
    vi_id: '-1',
    vi_cplx_id: '-1',
    vi_home_id: '-1',
    vi_res_id: '-1',
    vi_av_id: '-1',
    vi_name: '',
    vi_last_name: '',
    vi_vehicle: '',
    vi_color: '',
    vi_plate: '',
    vi_approved_by: '',
    vi_photo_1: '',
    vi_photo_2: '',
    vi_auth_id: '',
    vi_auth_type: 'users',
    vi_active: 'Y',
    vi_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'vi_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  public isLoading = false;
  // tslint:disable-next-line:variable-name
  public vi_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public vi_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_home_id_payload_search = [
    { id: 'vi_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public vi_res_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_res_id_payload_search = [
    { id: 'vi_home_id', value: '-1' },
  ];




  // tslint:disable-next-line:variable-name
  public vi_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public vi_home_id_payload = [
    { id: 'vi_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vi_res_id_payload = [
    { id: 'vi_home_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vi_av_id_payload = [
  ];










  public authUser = '';
  public message = '';
  public logs = [];
  public browse = 'Browse';

  public fromDateValue = '';
  public toDateValue = '';

  public dateRange = { from: moment().subtract(60, 'days').format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') };



  constructor(public app: AppService, private router: Router, private location: Location, private addLogService:AddlogService) {
    const currentDate = moment();
    let futureMonth = moment(currentDate).add(1, 'M');
    const futureMonthEnd = moment(futureMonth).endOf('month');

    if (currentDate.date() !== futureMonth.date() && futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
    futureMonth = futureMonth.add(1, 'd');
    }

    this.fromDateValue = currentDate.format('YYYY-MM-DD');
    this.toDateValue = futureMonth.format('YYYY-MM-DD');

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public toDateChanged(ob) {
    console.log(ob);
    this.dateRange = ob;
    this.init();
    // console.log(this.dateRange);
  }

  public searchPayloadsChanged() {

    this.vi_home_id_payload_search = [
      { id: 'vi_cplx_id', value: this.vi_cplx_id_search },
    ];

    this.vi_res_id_payload_search = [
      { id: 'vi_home_id', value: this.vi_home_id_search },
    ];

  }

  public payloadsChanged() {
    this.vi_home_id_payload = [
      { id: 'vi_cplx_id', value: this.visit.vi_cplx_id },
    ];
    this.vi_res_id_payload = [
      { id: 'vi_home_id', value: this.visit.vi_home_id },
    ];
  }

  public init() {
    this.visits = [];
    this.reset();
    this.getAllVisits();
  }

  public reset() {
    this.visit = {
      vi_id: '-1',
      vi_cplx_id: '-1',
      vi_home_id: '-1',
      vi_res_id: '-1',
      vi_av_id: '-1',
      vi_name: '',
      vi_last_name: '',
      vi_vehicle: '',
      vi_color: '',
      vi_plate: '',
      vi_approved_by: '',
      vi_auth_type: 'users',
      vi_photo_1: '',
      vi_photo_2: '',
      vi_auth_id: '',
      vi_active: 'Y',
      vi_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.vi_home_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onResIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.visit.vi_home_id = '-1';
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.payloadsChanged();
  }
  public onResIdChanged(obj) {
    this.payloadsChanged();
  }
  public onAvIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllVisits() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      vi_cplx_id_search: this.vi_cplx_id_search,


      vi_home_id_search: this.vi_home_id_search,


      vi_res_id_search: this.vi_res_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter,
      from: this.dateRange.from,
      to: this.dateRange.to
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('visits/get_all_visits', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.visits = this.visits.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.visits.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public fetchAllVisitsForPDF() {
    const obj = {
      num_per_page: 40, // Adjust the page size if needed
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      vi_cplx_id_search: this.vi_cplx_id_search,
      vi_home_id_search: this.vi_home_id_search,
      vi_res_id_search: this.vi_res_id_search,
      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter,
      from: this.dateRange.from,
      to: this.dateRange.to
    };
  
    const visitsPDF: any[] = [];
  
    // Show the loading indicator
    this.isLoading = true;
  
    const fetchAllVisitsForPDF = (startFrom: number) => {
      obj.start_from = startFrom;
  
      this.app.getQuery('visits/get_all_visits', obj).then(result => {
        if (result.status === 'OK') {
          visitsPDF.push(...result.data);
  
          this.count = result.total;
          this.received = visitsPDF.length;
  
          if (visitsPDF.length < result.total) {
            // Fetch the next batch of visits
            fetchAllVisitsForPDF(visitsPDF.length);
          } else {
            // All visits are fetched
            this.displayCount = `${visitsPDF.length} of ${this.count}`;
            console.log('All visits have been retrieved for PDF.', visitsPDF);
  
            // Hide the loading indicator
            this.isLoading = false;
  
            // Proceed to generate or save the PDF with the data
            this.generateVisitsPDF(visitsPDF);
          }
        } else {
          console.error('Error fetching visits:', result.message);
  
          // Hide the loading indicator if there's an error
          this.isLoading = false;
        }
      }).catch(error => {
        console.error('Request failed:', error);
  
        // Hide the loading indicator if there's an error
        this.isLoading = false;
      });
    };
  
    // Start fetching all visits from the beginning
    fetchAllVisitsForPDF(0);
  }
  
  
  private generateVisitsPDF(visits: any[]) {
    console.log("Visits:", visits);
  
    try {
      if (!Array.isArray(visits)) {
        throw new Error("Invalid data: Visits should be an array.");
      }
  
      if (visits.length === 0) {
        throw new Error("No data to generate PDF.");
      }
  
      const doc = new jsPDF({
        orientation: 'landscape', // Set orientation to landscape
        unit: 'mm',
        format: 'a4'
      });
  
      doc.setProperties({
        title: "Visit Data",
        subject: "List of Visits",
        author: "Access Control One",
        keywords: "Visits, pdf",
        creator: "Access Control One"
      });
  
      doc.setFontSize(18);
      doc.text("Visits Data", 14, 15);
  
      const headers = [
        ["Visit ID", "Visitor Name", "Visit Date", "Home Name", "Complex Name", "Purpose", "Contact Person"]
      ];
  
      const rows = visits.map(visit => [
        visit.visit_id || 'N/A',
        visit.visitor_name || 'N/A',
        visit.visit_date || 'N/A',
        visit.home_name || 'N/A',
        visit.cplx_name || 'N/A',
        visit.purpose || 'N/A',
        visit.contact_person || 'N/A'
      ]);
  
      (doc as any).autoTable({
        startY: 25,
        head: headers,
        body: rows,
        columnStyles: {
          0: { cellWidth: 25, halign: 'center' }, // Visit ID
          1: { cellWidth: 35 },                  // Visitor Name
          2: { cellWidth: 35 },                  // Visit Date
          3: { cellWidth: 35 },                  // Home Name
          4: { cellWidth: 55 },                  // Complex Name
          5: { cellWidth: 30 },                  // Purpose
          6: { cellWidth: 40 }                   // Contact Person
        },
        styles: {
          overflow: 'linebreak',
          cellPadding: 2,
          fontSize: 10,
          valign: 'middle'
        },
        margin: { top: 20, left: 10, right: 10 },
        theme: 'striped',
        tableWidth: 'auto', // Ensure the table width adjusts to fit the page
        pageBreak: 'auto', // Automatically handle page breaks
      });
  
      doc.save("Visits_data.pdf");
      alert("PDF for Visits generated successfully");
  
    } catch (error) {
      console.error("Error generating PDF:", error.message);
      alert("Error generating PDF. Please check the console for details.");
    }
  }
  
  

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllVisits();
  }

  public get_auth_id() {
    this.authUser = '';
    this.app.getQuery('registers/get_selected_auth_id', {id: this.visit.vi_auth_id, type: this.visit.vi_auth_type}).then(result => {
      if (result.status === 'OK') {
        if (result.data.length > 0) {
          this.authUser = result.data[0].text;
        }

      }
    });
  }

  public get_auth_id_tbl(vst) {
    this.authUser = '';
    this.app.getQuery('registers/get_selected_auth_id', {id: vst.vi_auth_id, type: vst.vi_auth_type}).then(result => {
      if (result.status === 'OK') {
        if (result.data.length > 0) {
          return result.data[0].text;
        }

      }
    });
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.visit));
    this.app.getQuery('visits/add_edit_visit', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModal').modal('hide');
        if(result.message === "Visit entry updated") {
            this.addLogService.addLogs(this.user_name, this.isAdmin, `User "${this.user_name}" updated a Visitant named "${itemObj.vi_name}" last name "${itemObj.vi_last_name}"`).then(res => {
              console.log(res);
            })

        } else {
          this.addLogService.addLogs(this.user_name, this.isAdmin, `User "${this.user_name}" Added a Visitant named  "${itemObj.vi_name}" last name "${itemObj.vi_last_name}"`).then(res => {
            console.log(res);
          })

        }
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.visit = tempItem;
    console.log(this.visit);
    this.get_auth_id();
    this.payloadsChanged();
    $('#myModal').modal('show');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



