
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef, inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import { AddlogService } from '../Services/addlog.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public activities = [];
  public buildings = [];
  public partName: any = { name: '', last_name: '', email: '' };
  public isAdmin = localStorage.getItem('user_level');
  public userName = localStorage.getItem('user_name');



  public activity: any = {
    act_id: '-1',
    act_name: '',
    act_location: '',
    act_cplx_id: '-1',
    act_building_id: '-1',
    act_home_id: '-1',
    act_from_date_time: '',
    act_to_date_time: '',
    act_active: 'Y',
    act_list_of_participants: [],
    act_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'act_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public act_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public act_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public act_building_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public act_building_id_payload_search = [
    { id: 'act_cplx_id', value: '-1' },
  ];






  // tslint:disable-next-line:variable-name
  public act_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public act_building_id_payload = [
    { id: 'act_cplx_id', value: '-1' },
  ];

  // tslint:disable-next-line:variable-name
  public act_home_id_payload = [
    { id: 'act_cplx_id', value: '-1' },
    { id: 'act_building_id', value: '-1' },
  ];


  public message = '';
  public logs = [];
  public browse = 'Browse';
  // addLogService: any;



  constructor(public app: AppService, private router: Router, private location: Location, private addlogService: AddlogService) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

    this.act_building_id_payload_search = [
      { id: 'act_cplx_id', value: this.act_cplx_id_search },
    ];

  }

  public payloadsChanged() {
    this.act_building_id_payload = [
      { id: 'act_cplx_id', value: this.activity.act_cplx_id },
    ];

    this.act_home_id_payload = [
      { id: 'act_cplx_id', value: this.activity.act_cplx_id },
      { id: 'act_building_id', value: this.activity.act_building_id },
    ];
  }

  public init() {
    this.activities = [];
    this.reset();
    this.getAllActivities();
  }

  public reset() {
    this.activity = {
      act_id: '-1',
      act_name: '',
      //TODO: i need to add act_location to save it to the database so it shows in the calendar - Pedro
      act_location: '',
      act_cplx_id: '-1',
      act_building_id: '-1',
      act_home_id: '-1',
      act_from_date_time: '',
      act_to_date_time: '',
      act_list_of_participants: [],
      act_active: 'Y',
      act_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  //TODO: Change this code to work with email - pedro

  public addPart() {
    if (this.partName.name !== '') {
      const pb = JSON.parse(JSON.stringify(this.partName));

      //TODO: i added the email here - pedro
      console.log("this is the pb variable - ", pb);
      this.activity.act_list_of_participants.push({ name: pb.name, last_name: pb.last_name, email: pb.email });
      this.partName.name = '';
      this.partName.last_name = '';
      this.partName.email = '';
    }
  }

  public removePart(oc) {
    this.activity.act_list_of_participants.splice(oc, 1);
    // TODO:adding this to debug list
    console.log("this is remove part function - ", this.activity.act_list_of_participants.splice(oc, 1));
  }

  public onCplxIdChangedOnSearch(obj) {
    this.act_building_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onBuildingIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.activity.act_building_id = '-1';
    this.activity.act_home_id = '-1';
    this.payloadsChanged();
  }
  public onBuildingIdChanged(obj) {
    this.activity.act_home_id = '-1';
    this.payloadsChanged();
  }

  //TODO: TESTING IF THE FUNCTION GET BUILDING WORKS HERE  - PEDRO
  getBuildings() {
    const l = this;
    // this.buildings = [],
    this.app.getQuery('activitiescal/get_buildings', {
      cplx_id: this.activity.act_cplx_id
    }).then(function (n) {
      // 'OK' === n.status && (this.buildings = n.data);
      //TODO: Checking if its works
      console.table(n.data)

    });
  }


  //TODO:Need to fix the calendar activities i dont get the info in the ui - Pedro 

  public getAllActivities() {
    // this.getBuildings()
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      act_cplx_id_search: this.act_cplx_id_search,


      act_building_id_search: this.act_building_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('activities/get_all_activities', obj).then(result => {




      console.log(`result here ${result}`);
      if (result.status === 'OK') {

        this.activities = this.activities.concat(result.data);

        //TODO: checking the result to debug - Pedro
        console.table(this.activity)
        // this.getBuildings()
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        this.buildings = []
        this.getBuildings();
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {

          this.dbPages.push(index);
        }
        this.displayCount = (this.activities.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllActivities();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.activity));
    console.log(itemObj);


    this.app.getQuery('activities/add_edit_activity', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModal').modal('hide');
        if (result.message === "Activity entry updated") {
          this.addlogService.addLogs(this.userName, this.isAdmin, `User "${this.userName}" updated Activity named "${itemObj.act_name}" from "${itemObj.act_from_date_time}" to "${itemObj.act_to_date_time}"`).then(result => {
            console.log('Log added successfully:', result);
          }).catch(error => {
            console.error('An error occurred:', error);
          })
        } else {
          //TODO:ADDed service to add logs
          this.addlogService.addLogs(this.userName, this.isAdmin, `Added an activity named ${itemObj.act_name} from ${itemObj.act_from_date_time} to ${itemObj.act_to_date_time}`).then(result => {
            console.log('Log added successfully:', result);
          }).catch(error => {
            console.error('An error occurred:', error);
          })
        }



        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });

  }


  // public activity: any = {
  //   act_id: '-1',
  //   act_name: '',
  //   act_location: '',
  //   act_cplx_id: '-1',
  //   act_building_id: '-1',
  //   act_home_id: '-1',
  //   act_from_date_time: '',
  //   act_to_date_time: '',
  //   act_active: 'Y',
  //   act_list_of_participants: [],
  //   act_log: '',
  // };



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    // console.log("this is the pb variable - ", this.partName);
    if (tempItem.act_list_of_participants === '') {
      tempItem.act_list_of_participants = [];
    } else {
      tempItem.act_list_of_participants = JSON.parse(tempItem.act_list_of_participants);

    }
    this.activity = tempItem;

    this.payloadsChanged();
    $('#myModal').modal('show');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }
}



