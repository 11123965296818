
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.css']


})
export class ConsoleComponent implements OnInit, OnDestroy, AfterViewInit {

  //TODO:Here i will make the time log so if user is in the console more than 10 min withut doing nothing 

  constructor(public app: AppService,
              private router: Router, private location: Location
  ) {
    this.lang = this.app.getLang();
    this.login = { id: '-1' };
    this.app.setConsoleComponent(this);
  }
  public lang;
  public login: any;
  public customerUser: any;
  public user: any;
  public message = '';
  public browse = 'Browse';
  public isAdmin = localStorage.getItem('user_level')

  public helps = [];
  public counts = {actCount: 0};
  public viewHidden = true;
  public helpObject: any = {};
  public search = '';
  public adminPop = 'terms';
  public adminSetting: any = {
    as_id: '-1',
    as_company_name: '',
    as_email: '',
    as_address_txt: '',
    as_country_id: '-1',
    as_state_id: '-1',
    as_city_id: '-1',
    as_over_hrs_after: '',
    as_wover_hrs_after: '',
    as_zip: '',
    as_banner_type: '',
    as_terms: '',
    as_privacy: '',
    as_phone: '',
    as_fb: '',
    as_twitter: '',
    as_yt: '',
    as_insta: '',
    as_smtp_host: '',
    as_smtp_port: '',
    as_smtp_user_name: '',
    as_smtp_pass: '',
    as_smtp_from: '',
    as_smtp_extra: '',
    as_time_zone: '',
    as_log: '',
  };
  public messageProfile = '';
  public bodyMessage: any = {resident: {}};

  private inactivityTimeLimit: number = 8 * 60 * 60 * 1000; // 28,800,000 ms for 8 hours

  //TODO:Added a timer for inactivity
  // private readonly inactivityTimeLimit: number = 5000;
  private timeoutHandle: any;
  private alertTimeout: any;



  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  @HostListener('document:click')
  @HostListener('document:touchstart')
  resetInactivityTimer() {
    clearTimeout(this.timeoutHandle);
    this.timeoutHandle = setTimeout(() => this.handleInactivity(), this.inactivityTimeLimit);
  }

  


  private handleInactivity() {
    //TODO: Just for dev only work if user is Employee
    if(this.isAdmin !== "Administrator") {
      // alert('You have been inactive for 10 minutes. Logging out...');
      this.logout();
    } else {

    }
  }





  onChangeLang() {
    this.app.setLang(this.lang);
    location.reload();
  }

  ngOnInit() {
    // window.location.reload();
    // this.isAdmin = localStorage.getItem('user_level')

    this.check();
    
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {

    //  $.getScript('assets/js/ResizeSensor.js'),
    $.getScript('assets/js/dashboard.js'),
    $.getScript('assets/js/chart.chartjs.js');

    Notification.requestPermission().then((result) => {
      console.log(result);
    });
  }

  public check() {
    
    this.isAdmin = localStorage.getItem("user_level");
    const tempItem = JSON.parse(JSON.stringify(this.app.getLogin()));
    if (!tempItem.user_password) {
      tempItem.user_password = '';
    }
    if (tempItem.id !== '-1') {
      this.login = tempItem;
      //here add it here 
      //TODO:
      console.log(tempItem);
      this.getPrivacy();
      this.getActivities();
      // console.log(this.login.obj.user_level);


    } else {
      this.location.replaceState('/');
      this.router.navigate(['redirect/login']);
      // location.replace('https://accesscontrolone.com/login/');
      // tslint:disable-next-line:max-line-length
      // this.app.setLogin('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMiIsInVzZXJfZW1haWwiOiJ0ZWNoM2luQGxpdmUuY29tIiwidXNlcl9maXJzdF9uYW1lIjoiVmFzdWRldiIsInVzZXJfbGFzdF9uYW1lIjoiS3VtYXJhbiIsInVzZXJfbGV2ZWwiOiJBZG1pbmlzdHJhdG9yIiwidXNlcl9waG90byI6ImRlZmF1bHQucG5nIiwidXNlcl9jb25maXJtZWQiOiJZIiwidXNlcl9hY3RpdmUiOiJZIiwidXNlcl9sb2ciOiIyMDE5LTA0LTAxIDEyOjI2OjQzIiwiaWQiOiIyIiwidHlwZSI6IkJhY2tlbmQiLCJ0aW1lU3RhbXAiOiIyMDIwLTA5LTAxIDA0OjE2OjMxIiwic3RhdHVzIjoiT0sifQ.ic_3PL-kuU10tuRfgWxKSKdPIE0xWDMRTYIM2nJ0css');
    }
  }

  public onSubmitProfile() {
    this.app.getQuery('users/edit_profile', this.login).then(result => {
      console.log(result);
      if (result.status === 'OK') {
        $('#myModalProfile').modal('hide');
        this.check();
      } else {
        this.messageProfile = result.message;
        setTimeout(() => {
          this.messageProfile = '';
        }, 4000);
      }
    });

  }

  public profileClick() {
    this.messageProfile = '';
    $('#myModalProfile').modal('show');
  }

  public initEmgyAlertBox(message) {
    const resident = message.resident;
    if (resident.length > 0) {
      message.resident = resident[0];
    } else {
      message.resident = {};
    }
    this.bodyMessage = message;

    $('#myModalEmgyAlert').modal('show');
  }

  public getActivities() {
    this.app.getQuery('activitiescal/get_all_activities', {
      search_cplx_id: '-1',
        search_from_date_time: moment().format('YYYY-MM-DD'),
        // from_date: this.fromDate,
        // to_date: this.toDate,
        num_per_page: 1000,
        order_by: 'act_id',
        order: 'ASC',
        start_from: 0,
        search: ''
    }).then(result => {
      console.log(result);
      if (result.status === 'OK'){
        this.counts.actCount = result.data.length;
      }
    });
  }

  public takeAction(message) {
    const emergency_id = message.emergency_id;
    this.router.navigate(['/console/settings/emergency_handle', emergency_id]);

  }

  public viewHelpDetail(obj) {
    this.helpObject = obj;
    this.viewHidden = false;
  }

  public closeDetail() {
    this.helpObject = {};
    this.viewHidden = true;
  }

  public viewHelp() {
    this.search = '';
    this.helps = [];
    this.getHelps();
    $('#myModalHelp').modal('show');
  }

  public onClear() {
    this.search = '';
    this.helps = [];
    this.getHelps();
  }

  public onSearchSubmitHelp() {
    this.helps = [];
    this.getHelps();
  }

  public showTerms() {
    this.adminPop = 'terms';
    $('#myModalTerms').modal('show');
  }

  public showPrivacy() {
    this.adminPop = 'privacy';
    $('#myModalTerms').modal('show');
  }

  public getPrivacy() {
    const objAdmin = {
      num_per_page: 10,
      order_by: 'as_id',
      order: 'DESC',
      start_from: 0,
      search: '',
      active: true,
      inactive: false
    };
    this.app.getQuery('adminsettings/get_all_admin_settings', objAdmin).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.adminSetting = result.data[0];
      }
    });
  }
  public getHelps() {


    const obj = { user_type: 1, search: this.search };
    // if (parseInt(this.login.user_mlc_id, 10) > 0) {
    //   obj.user_type = 2;
    // } else if (parseInt(this.login.user_do_id, 10) > 0) {
    //   obj.user_type = 3;
    //   // this.app.getQuery('doctor_offices/get_dnr_by_do', this.login).then(result => {

    //   //   console.log(result);
    //   //   if (result.status === 'OK') {
    //   //     this.dnrMenus = result.data;
    //   //   }
    //   // });
    // } else if (parseInt(this.login.user_pharm_id, 10) > 0) {
    //   obj.user_type = 4;
    // } else if (parseInt(this.login.user_osrv_id, 10) > 0) {
    //   obj.user_type = 6;
    // }
    console.log(obj);
    this.app.getQuery('helps/get_all_helps_by_cat', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.helps = result.data;
      }
    });

  }

  public logout() {
    this.app.setLogout();
    //TODO:remove the variable localstorage user_level - Pedro
    // localStorage.removeItem('user_level');

    this.location.replaceState('/');
    this.router.navigate(['redirect/login']);
    window.location.reload()
  }

}



