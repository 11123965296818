import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanvasFeedComponent } from './canvas-feed.component';


@NgModule({
  declarations: [
    CanvasFeedComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CanvasFeedComponent
  ]
})
export class CanvasFeedModule { }
