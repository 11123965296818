// src/app/activites-calendar/activites-calendar.component.ts

import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  AfterViewChecked,
} from '@angular/core';
import { NgForm, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timeLinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput, Calendar } from '@fullcalendar/core';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-activites-calendar',
  templateUrl: './activites-calendar.component.html',
  styleUrls: ['./activites-calendar.component.css'],
})
export class ActivitesCalendarComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  constructor(
    public app: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
    // Removed AddLogService from constructor
  ) {}

  @ViewChild('baseForm') baseForm!: NgForm;
  @ViewChild('visitorForm') visitorForm!: NgForm;
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;

  public activities: any[] = [];
  public search_cplx_id: string = '-1';
  public search_from_date_time: string = '';
  public search_complexes: any[] = [];
  public complexes: any[] = [];
  public buildings: any[] = [];
  public buildingsMap: { [key: string]: string } = {};
  public loadingText: string = 'Save';
  public num_per_page: string = '10000';
  public order_by: string = 'act_id';
  public order: string = 'DESC';
  public start_from: number = 0;
  public count: number = 0;
  public received: number = -1;
  public displayCount: string = '';
  public dbPages: number[] = [];
  public activePage: number = 0;
  public search: string = '';
  public errorText: string = '';
  public message: string = '';
  public logs: any[] = [];
  public browse: string = 'Browse';
  public activity: any = {
    act_id: '-1',
    act_name: '',
    act_location: '-1',
    act_cplx_id: '-1',
    act_building_id: '-1',
    act_from_date_time: '',
    act_to_date_time: '',
    act_active: 'Y',
    act_list_of_participants: [],
    act_off_id: '-1',
    act_log: '',
  };

  public av_home_id_payload_search = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_building_id', value: '-1' },
  ];

  public fromDate: string = '';
  public toDate: string = '';
  public isAdmin: string | null = localStorage.getItem('user_level');
  public user_name: string = localStorage.getItem('user_name') || ''; // Get user_name
  public calendarPlugins = [
    dayGridPlugin,
    timeGridPlugin,
    timeLinePlugin,
    interactionPlugin,
  ];
  public calendarWeekends: boolean = true;
  public calendarEvents: EventInput[] = [];
  public calendarApi!: Calendar;

  public cplxType: string = '';
  public offices: any[] = [];
  public locations: any[] = [];

  // Updated properties for Add Authorized Visitor
  public visitor: any = {
    name: '',
    lastName: '',
    av_relationship: '', // Relationship field
    email: '',           // Email field
    phone: '',           // Phone Number field
  };

  public authorizedvistor: any = {
    // Initialize properties for each day
    av_mon_closed: '',
    av_mon_open_time: '',
    av_mon_close_time: '',
    av_tue_closed: '',
    av_tue_open_time: '',
    av_tue_close_time: '',
    av_wed_closed: '',
    av_wed_open_time: '',
    av_wed_close_time: '',
    av_thr_closed: '',
    av_thr_open_time: '',
    av_thr_close_time: '',
    av_fri_closed: '',
    av_fri_open_time: '',
    av_fri_close_time: '',
    av_sat_closed: '',
    av_sat_open_time: '',
    av_sat_close_time: '',
    av_sun_closed: '',
    av_sun_open_time: '',
    av_sun_close_time: '',
    av_photo: '',
    av_active: 'Y', // Add av_active property
  };

  public currentParticipantIndex: number = -1;
  public participantNamePlaceholder: string = '';
  public participantLastNamePlaceholder: string = '';
  public oldActiveStatus: string = ''; // Add oldActiveStatus property

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewInit() {
    this.getComplexes();
    this.initializeDatePickers();
    this.initializeDateTimePickers();
  }

  ngAfterViewChecked() {
    if (this.calendarComponent) {
      this.calendarApi = this.calendarComponent.getApi();
    }
  }

  datesRender(model: any) {
    this.fromDate = moment(model.view.activeStart).format('YYYY-MM-DD') + ' 00:00:00';
    this.toDate = moment(model.view.activeEnd).format('YYYY-MM-DD') + ' 23:59:59';
    this.init();
  }

  public handleEventClick(model: any) {
    this.editItem(model.event._def.extendedProps);
  }

  getComplexes() {
    this.app.getQuery('activitiescal/get_complexes', {}).then((result: any) => {
      if (result.status === 'OK') {
        this.complexes = result.data;
      }
    }).catch((error: any) => {
      console.error('Error fetching complexes:', error);
      this.message = 'An error occurred while fetching complexes.';
      setTimeout(() => {
        this.message = '';
      }, 4000);
    });
  }

  onChangeComplexes() {
    this.activity.act_building_id = '-1';
    const index = this.complexes.findIndex((obx) => {
      return obx.cplx_id === this.activity.act_cplx_id;
    });
    if (index !== -1) {
      this.cplxType = this.complexes[index].cplx_type;
      this.getBuildings();
      this.getLocations();
    } else {
      this.cplxType = '';
      this.buildings = [];
      this.locations = [];
    }
  }

  getBuildings() {
    this.buildings = [];
    this.app
      .getQuery('activitiescal/get_buildings', {
        cplx_id: this.activity.act_cplx_id,
      })
      .then((n: any) => {
        if (n.status === 'OK') {
          this.buildings = n.data;
        }
      })
      .catch((error: any) => {
        console.error('Error fetching buildings:', error);
        this.message = 'An error occurred while fetching buildings.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      });
  }

  getLocations() {
    this.locations = [];
    this.app
      .getQuery('activitiescal/get_locations', {
        cplx_id: this.activity.act_cplx_id,
      })
      .then((n: any) => {
        if (n.status === 'OK') {
          this.locations = n.data;
        }
      })
      .catch((error: any) => {
        console.error('Error fetching locations:', error);
        this.message = 'An error occurred while fetching locations.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      });
  }

  getOffices() {
    this.offices = [];
    this.app
      .getQuery('activitiescal/get_offices', {
        building_id: this.activity.act_building_id,
      })
      .then((n: any) => {
        if (n.status === 'OK') {
          this.offices = n.data;
        }
      })
      .catch((error: any) => {
        console.error('Error fetching offices:', error);
        this.message = 'An error occurred while fetching offices.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      });
  }

  onChangeBuildings() {
    this.activity.act_off_id = '-1';
    this.getOffices();
  }

  init() {
    this.activities = [];
    this.reset();
    this.getAllActivities();
  }

  reset() {
    this.activity = {
      act_id: '-1',
      act_name: '',
      act_location: '-1',
      act_cplx_id: '-1',
      act_building_id: '-1',
      act_from_date_time: moment(
        $('#act_from_date_time').val(),
        'dddd, D MMMM, YYYY h:mm A'
      ).format('YYYY-MM-DD HH:mm:ss'),
      act_to_date_time: moment(
        $('#act_to_date_time').val(),
        'dddd, D MMMM, YYYY h:mm A'
      ).format('YYYY-MM-DD HH:mm:ss'),
      act_active: 'Y',
      act_list_of_participants: [],
      act_off_id: '-1',
      act_log: '',
    };
    this.buildings = [];
    this.offices = [];
    this.locations = [];
    if (this.baseForm && this.baseForm.form) {
      this.baseForm.form.markAsPristine();
      this.baseForm.form.markAsUntouched();
      this.baseForm.form.updateValueAndValidity();
    }
  }

  async getAllActivities() {
    const n = {
      search_cplx_id: this.search_cplx_id,
      search_from_date_time: this.search_from_date_time,
      from_date: this.fromDate,
      to_date: this.toDate,
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      search: this.search,
    };

    try {
      const response: any = await this.app.getQuery('activitiescal/get_all_activities', n);

      if (response.status === 'OK') {
        this.activities = this.activities.concat(response.data);
        this.count = response.total;
        this.received = response.received;

        this.dbPages = [];
        for (let u = 0; u < response.pages; u++) {
          this.dbPages.push(u);
        }
        this.displayCount = this.activities.length + ' of ' + this.count;
        const calendarEvents: EventInput[] = [];

        for (const element of response.data) {
          element.id = element.act_id;
          element.start = element.act_from_date_time;
          element.end = element.act_to_date_time;
          element.title = `${element.act_name} - ${element.cplx_name}`;
          calendarEvents.push(element);
        }

        this.calendarEvents = calendarEvents;
        this.calendarApi.removeAllEventSources();
        this.calendarApi.addEventSource(this.calendarEvents);
      }
    } catch (error) {
      console.error('Error fetching activities:', error);
      this.message = 'An error occurred while fetching activities.';
      setTimeout(() => {
        this.message = '';
      }, 4000);
    }
  }

  onSearchSubmit() {
    this.onChangePage();
  }

  onClear() {
    this.search = '';
    this.onChangePage();
  }

  onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllActivities();
  }

  onSubmit() {
    this.loadingText = 'Saving...';

    // Clone the activity object to avoid unintended side effects
    const n = JSON.parse(JSON.stringify(this.activity));

    // Serialize act_list_of_participants
    n.act_list_of_participants = JSON.stringify(n.act_list_of_participants);

    // Log the data being sent
    console.log('Data sent to backend:', n);

    this.app.getQuery('activitiescal/add_edit_activity', n).then((response: any) => {
      this.loadingText = 'Save';
      if (response.status === 'OK') {
        $('#myModal').modal('hide');
        this.onClear();
        this.message = 'Activity saved successfully!';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      } else {
        this.message = response.message || 'An error occurred while saving the activity.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    }).catch((error: any) => {
      console.error('Error saving activity:', error);
      this.loadingText = 'Save';
      this.message = 'An error occurred while saving the activity.';
      setTimeout(() => {
        this.message = '';
      }, 4000);
    });
  }

  add() {
    this.reset();
    $('#myModal').modal('show');
  }

  editItem(l: any) {
    const n = JSON.parse(JSON.stringify(l));
    if (!n.act_list_of_participants || n.act_list_of_participants === '') {
      n.act_list_of_participants = [];
    } else {
      n.act_list_of_participants = JSON.parse(n.act_list_of_participants);
    }
    this.activity = n;
    const index = this.complexes.findIndex((obx) => {
      return obx.cplx_id === this.activity.act_cplx_id;
    });
    if (index !== -1) {
      this.cplxType = this.complexes[index].cplx_type;
      this.getBuildings();
      this.getOffices();
      this.getLocations();
    } else {
      this.cplxType = '';
      this.buildings = [];
      this.offices = [];
      this.locations = [];
    }
    $('#act_from_date_time').datetimepicker(
      'update',
      moment(n.act_from_date_time, 'YYYY-MM-DD HH:mm:ss').toDate()
    );
    $('#act_to_date_time').datetimepicker(
      'update',
      moment(n.act_to_date_time, 'YYYY-MM-DD HH:mm:ss').toDate()
    );
    $('#myModal').modal('show');
  }

  // Open Add Authorized Visitor Modal
  openAddVisitorModal(participant: any, index: number) {
    this.currentParticipantIndex = index;

    // Log participant data to check last_name
    console.log('Participant data:', participant);

    // Set placeholders with participant's info using the correct property names
    this.participantNamePlaceholder = participant.name || '';
    this.participantLastNamePlaceholder = participant.last_name || '';

    // Pre-fill visitor's name and last name if desired
    this.visitor = {
      name: this.participantNamePlaceholder,
      lastName: this.participantLastNamePlaceholder,
      av_relationship: '',
      email: '',
      phone: '',
    };

    this.authorizedvistor = {
      // Initialize properties for each day
      av_mon_closed: '',
      av_mon_open_time: '',
      av_mon_close_time: '',
      av_tue_closed: '',
      av_tue_open_time: '',
      av_tue_close_time: '',
      av_wed_closed: '',
      av_wed_open_time: '',
      av_wed_close_time: '',
      av_thr_closed: '',
      av_thr_open_time: '',
      av_thr_close_time: '',
      av_fri_closed: '',
      av_fri_open_time: '',
      av_fri_close_time: '',
      av_sat_closed: '',
      av_sat_open_time: '',
      av_sat_close_time: '',
      av_sun_closed: '',
      av_sun_open_time: '',
      av_sun_close_time: '',
      av_photo: '',
      av_active: 'Y',
    };

    // Open modal for adding visitor
    $('#addVisitorModal').modal('show');
  }

  // Close Add Authorized Visitor Modal
  closeVisitorModal() {
    $('#addVisitorModal').modal('hide');
    // Reset visitor and authorizedvistor objects
    this.visitor = {
      name: '',
      lastName: '',
      av_relationship: '',
      email: '',
      phone: '',
    };
    this.authorizedvistor = {
      av_mon_closed: '',
      av_mon_open_time: '',
      av_mon_close_time: '',
      av_tue_closed: '',
      av_tue_open_time: '',
      av_tue_close_time: '',
      av_wed_closed: '',
      av_wed_open_time: '',
      av_wed_close_time: '',
      av_thr_closed: '',
      av_thr_open_time: '',
      av_thr_close_time: '',
      av_fri_closed: '',
      av_fri_open_time: '',
      av_fri_close_time: '',
      av_sat_closed: '',
      av_sat_open_time: '',
      av_sat_close_time: '',
      av_sun_closed: '',
      av_sun_open_time: '',
      av_sun_close_time: '',
      av_active: 'Y',
    };
    this.participantNamePlaceholder = '';
    this.participantLastNamePlaceholder = '';
  }

  // Submit Add Authorized Visitor Form
  onAddVisitorSubmit() {
    if (this.visitorForm.valid) {
      this.loadingText = 'Saving...';

      // Combine visitor info with authorized visitor availability
      const visitorData = {
        ...this.visitor, // Basic visitor info (name, last name, relationship, email, phone)
        ...this.authorizedvistor, // Authorized visitor schedule (days, availability, image)
      };

      // Set av_name to visitor's full name
      visitorData.av_name = `${visitorData.name} ${visitorData.lastName}`;

      // Set default av_active if not set
      if (!visitorData.av_active) {
        visitorData.av_active = 'Y';
      }

      const itemObj = JSON.parse(JSON.stringify(visitorData));

      // Adjust days closed and times, similar to the original code
      if (itemObj.av_mon_closed === 'NA') {
        itemObj.av_mon_open_time = '00:00:00';
        itemObj.av_mon_close_time = '23:59:00';
        itemObj.av_mon_closed = 'N';
      }
      if (itemObj.av_tue_closed === 'NA') {
        itemObj.av_tue_open_time = '00:00:00';
        itemObj.av_tue_close_time = '23:59:00';
        itemObj.av_tue_closed = 'N';
      }
      if (itemObj.av_wed_closed === 'NA') {
        itemObj.av_wed_open_time = '00:00:00';
        itemObj.av_wed_close_time = '23:59:00';
        itemObj.av_wed_closed = 'N';
      }
      if (itemObj.av_thr_closed === 'NA') {
        itemObj.av_thr_open_time = '00:00:00';
        itemObj.av_thr_close_time = '23:59:00';
        itemObj.av_thr_closed = 'N';
      }
      if (itemObj.av_fri_closed === 'NA') {
        itemObj.av_fri_open_time = '00:00:00';
        itemObj.av_fri_close_time = '23:59:00';
        itemObj.av_fri_closed = 'N';
      }
      if (itemObj.av_sat_closed === 'NA') {
        itemObj.av_sat_open_time = '00:00:00';
        itemObj.av_sat_close_time = '23:59:00';
        itemObj.av_sat_closed = 'N';
      }
      if (itemObj.av_sun_closed === 'NA') {
        itemObj.av_sun_open_time = '00:00:00';
        itemObj.av_sun_close_time = '23:59:00';
        itemObj.av_sun_closed = 'N';
      }

      // Now, make the API call to save the authorized visitor
      this.app.getQuery('authorizedvistors/add_edit_authorizedvistor', itemObj).then(result => {
        this.loadingText = 'Save';
        console.table(itemObj);

        if (result.status === 'OK') {
          // Close the modal
          this.closeVisitorModal();

          // Also, add the visitor to the participant's authorizedVisitors array, as before
          // Get the current participant based on index
          const participant = this.activity.act_list_of_participants[this.currentParticipantIndex];

          // Initialize authorizedVisitors array if it doesn't exist
          if (!participant.authorizedVisitors) {
            participant.authorizedVisitors = [];
          }

          // Add the newly created visitor data to the participant's authorized visitors array
          participant.authorizedVisitors.push(visitorData);

          // Show a success message (optional)
          this.message = 'Authorized Visitor added successfully!';
          setTimeout(() => {
            this.message = '';
          }, 4000);

        } else {
          this.message = result.message;
          setTimeout(() => {
            this.message = '';
          }, 4000);
        }
      }).catch((error: any) => {
        console.error('Error saving authorized visitor:', error);
        this.loadingText = 'Save';
        this.message = 'An error occurred while saving the authorized visitor.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      });

    } else {
      // If form is invalid, mark all controls as touched to display validation errors
      this.markFormGroupTouched(this.visitorForm.form);
    }
  }

  /**
   * Recursively marks all controls in a FormGroup as touched.
   * This triggers validation messages in the template.
   * @param formGroup The FormGroup to mark.
   */
  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched({ onlySelf: true });
      if ((control as any).controls) {
        this.markFormGroupTouched(control as FormGroup);
      }
    });
  }

  /**
   * Initialize Date Pickers using jQuery.
   */
  private initializeDatePickers() {
    $('#search_from_date_time')
      .datepicker({
        autoclose: true,
        todayHighlight: true,
        format: 'DD, d MM, yyyy',
        orientation: 'bottom',
      })
      .on('changeDate', (e: any) => {
        const u = moment(e.date, 'dddd, D MMMM, YYYY');
        this.search_from_date_time = u.format('YYYY-MM-DD');
      });
    $('#search_from_date_time').datepicker('setDate', moment().toDate());
  }

  /**
   * Initialize DateTime Pickers using jQuery.
   */
  private initializeDateTimePickers() {
    $('#act_from_date_time')
      .datetimepicker({
        format: 'DD, d MM, yyyy hh:mm A',
        showMeridian: true,
        autoclose: true,
        todayHighlight: true,
        orientation: 'top',
      })
      .on('changeDate', (e: any) => {
        this.activity.act_from_date_time = moment(
          e.date,
          'dddd, D MMMM, YYYY h:mm A'
        ).format('YYYY-MM-DD HH:mm:ss');
      });
    $('#act_from_date_time').datetimepicker('update', moment().toDate());
    this.activity.act_from_date_time = moment(
      $('#act_from_date_time').val(),
      'dddd, D MMMM, YYYY h:mm A'
    ).format('YYYY-MM-DD HH:mm:ss');

    $('#act_to_date_time')
      .datetimepicker({
        format: 'DD, d MM, yyyy hh:mm A',
        showMeridian: true,
        autoclose: true,
        todayHighlight: true,
        orientation: 'top',
      })
      .on('changeDate', (e: any) => {
        this.activity.act_to_date_time = moment(
          e.date,
          'dddd, D MMMM, YYYY h:mm A'
        ).format('YYYY-MM-DD HH:mm:ss');
      });
    $('#act_to_date_time').datetimepicker('update', moment().toDate());
    this.activity.act_to_date_time = moment(
      $('#act_to_date_time').val(),
      'dddd, D MMMM, YYYY h:mm A'
    ).format('YYYY-MM-DD HH:mm:ss');
  }
}
