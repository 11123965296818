
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';



declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-residents',
  templateUrl: './residents.component.html',
  styleUrls: ['./residents.component.css']
})
export class ResidentsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public adminSetting = {cust_csv: ''};
  public failedData = [];
  public isLoading = false

  public residents = [];
  //-Pedro Lorenzo 
  public isAdmin = localStorage.getItem('user_level');

  public resident: any = {
    res_id: '-1',
    res_name: '',
    res_last_name: '',
    res_email: '',
    res_password: '',
    res_cplx_id: '-1',
    res_building_id: '-1',
    res_home_id: '-1',
    res_phone: '',
    res_type: 'owner',
    res_mobile: '',
    res_mms_id: '-1',
    res_start_living_on: '',
    res_stop_living_on: '',
    res_showon_register_screen: 'Y',
    res_active: 'Y',
    res_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'res_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  public resemail = '';
  // tslint:disable-next-line:variable-name
  public res_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public res_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public res_building_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public res_building_id_payload_search = [
    { id: 'res_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public res_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public res_home_id_payload_search = [
    { id: 'res_cplx_id', value: '-1' },
  ];








  // tslint:disable-next-line:variable-name
  public res_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public res_building_id_payload = [
    { id: 'res_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public res_home_id_payload = [
    { id: 'res_cplx_id', value: '-1' },
  ];




  // tslint:disable-next-line:variable-name
  public res_mms_id_payload = [
  ];




  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {
    console.log("Testing new function i made")
    // this.fetchResidentsForPDF();
    // this.generatePDF()

  }
 

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

    this.res_building_id_payload_search = [
      { id: 'res_cplx_id', value: this.res_cplx_id_search },
    ];

    this.res_home_id_payload_search = [
      { id: 'res_cplx_id', value: this.res_cplx_id_search },
    ];

  }

  public payloadsChanged() {
    this.res_building_id_payload = [
      { id: 'res_cplx_id', value: this.resident.res_cplx_id },
    ];
    this.res_home_id_payload = [
      { id: 'res_cplx_id', value: this.resident.res_cplx_id },
    ];
  }

  public init() {
    this.residents = [];
    this.reset();
    this.getAllResidents();
  }

  public reset() {
    this.resident = {
      res_id: '-1',
      res_name: '',
      res_last_name: '',
      res_email: '',
      res_password: '',
      res_cplx_id: '-1',
      res_building_id: '-1',
      res_home_id: '-1',
      res_phone: '',
      res_mobile: '',
      res_type: 'owner',
      res_mms_id: '-1',
      res_start_living_on: '',
      res_stop_living_on: '',
      res_showon_register_screen: 'Y',
      res_active: 'Y',
      res_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.res_home_id_search = '-1';
    this.res_building_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onBuildingIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onChangeStillLiving(obj) {
    if (obj.target.checked) {
      this.resident.res_stop_living_on = null;
    } else {
      this.resident.res_stop_living_on = '';
    }
    console.log(this.resident.res_stop_living_on);
  }

  public onCplxIdChanged(obj) {
    this.resident.res_home_id = '-1';
    this.resident.res_building_id = '-1';
    this.payloadsChanged();
  }
  public onBuildingIdChanged(obj) {
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.payloadsChanged();
  }
  public onMmsIdChanged(obj) {
    this.payloadsChanged();
  }

  public emailFilterChanged(obj) {
    console.log(obj.checked + ' ' + obj.value);
    this.resemail = obj.value;
    this.onSearchSubmit();
  }


  public getAllResidents() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      res_cplx_id_search: this.res_cplx_id_search,


      res_building_id_search: this.res_building_id_search,


      res_home_id_search: this.res_home_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter,
      email_filter: this.resemail
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('residents/get_all_residents', obj).then(result => {
      console.log("TESTING")
      if (result.status === 'OK') {
        this.residents = this.residents.concat(result.data);
        console.log(result.data)
        // this.generatePDF();
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.residents.length) + ' of ' + this.count;
      } else {

      }
    });
  }


  TODO:"The probel is that there is alot of residents and getting all of them and put it in a pdf requires alot of resources and throws error"
  public fetchAllResidentsForPDF() {
    const obj = {
      num_per_page: 40,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      res_cplx_id_search: this.res_cplx_id_search,
      res_building_id_search: this.res_building_id_search,
      res_home_id_search: this.res_home_id_search,
      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter,
      email_filter: this.resemail
    };
  
    const residentsForPDF: any[] = [];
  
    // Show the loading indicator
    this.isLoading = true;
  
    const fetchAllResidents = (startFrom: number) => {
      obj.start_from = startFrom;
  
      this.app.getQuery('residents/get_all_residents', obj).then(result => {
        if (result.status === 'OK') {
          residentsForPDF.push(...result.data);
          this.count = result.total;
          this.received = residentsForPDF.length;
  
          if (residentsForPDF.length < result.total) {
            // Fetch the next batch of residents
            fetchAllResidents(residentsForPDF.length);
          } else {
            // All residents are fetched
            this.displayCount = `${residentsForPDF.length} of ${this.count}`;
            console.log('All residents have been retrieved for PDF.', residentsForPDF);
  
            // Hide the loading indicator
            this.isLoading = false;
  
            // Proceed to generate or save the PDF with the data
            this.savePDF(residentsForPDF);
          }
        } else {
          console.error('Error fetching residents:', result.message);
  
          // Hide the loading indicator if there's an error
          this.isLoading = false;
        }
      }).catch(error => {
        console.error('Request failed:', error);
  
        // Hide the loading indicator if there's an error
        this.isLoading = false;
      });
    };
  
    // Start fetching all residents from the beginning
    fetchAllResidents(0);
  }
  

  
  //TODO:Adding a pdf
  private savePDF(residents: any[]) {
    console.log("Residents:", residents);
  
    try {
      if (!Array.isArray(residents)) {
        throw new Error("Invalid data: Residents should be an array.");
      }
  
      if (residents.length === 0) {
        throw new Error("No data to generate PDF.");
      }
  
      const doc = new jsPDF({
        orientation: 'landscape', // Set orientation to landscape
        unit: 'mm',
        format: 'a4'
      });
  
      doc.setProperties({
        title: "Resident Data",
        subject: "List of Residents",
        author: "Access Control One",
        keywords: "Residents, pdf",
        creator: "Access Control One"
      });
  
      doc.setFontSize(18);
      doc.text("Residents Data", 14, 15);
  
      const headers = [
        ["ID", "First Name", "Last Name", "Email", "Home Name", "Home Phone", "Complex Name"]
      ];
  
      const rows = residents.map(resident => [
        resident.res_id || 'N/A',
        resident.res_name || 'N/A',
        resident.res_last_name || 'N/A',
        resident.res_email || 'N/A',
        resident.home_name || 'N/A',
        resident.home_phone1 || 'N/A',
        resident.cplx_name || 'N/A'
      ]);
  
      // Adjust column styles and table width
      (doc as any).autoTable({
        startY: 25,
        head: headers,
        body: rows,
        columnStyles: {
          0: { cellWidth: 20, halign: 'center' }, // ID
          1: { cellWidth: 30 },                  // First Name
          2: { cellWidth: 30 },                  // Last Name
          3: { cellWidth: 40 },                  // Email
          4: { cellWidth: 30 },                  // Home Name
          5: { cellWidth: 25 },                  // Home Phone
          6: { cellWidth: 50 }                   // Complex Name
        },
        styles: {
          overflow: 'linebreak',
          cellPadding: 2,
          fontSize: 10,
          valign: 'middle'
        },
        margin: { top: 20, left: 10, right: 10 },
        theme: 'striped',
        tableWidth: 'auto', // Ensure the table width adjusts to fit the page
        pageBreak: 'auto', // Automatically handle page breaks
      });
  
      doc.save("Residents_data.pdf");
      alert("PDF for Residents generated successfully");
  
    } catch (error) {
      console.error("Error generating PDF:", error.message);
      alert("Error generating PDF. Please check the console for details.");
    }
  }
  
  
  
  
  
  
  // private fetchResidentsForPDF() {
  //   let residetnsForPDF = []
  //   // Make a request to get all residents
  //   this.app.getQuery('residents/get_all_residents', {
  //     num_per_page: '22', // or a sufficiently large number to fetch all records
  //     order_by: this.order_by,
  //     order: this.order,
  //     start_from: 0,
  //     res_cplx_id_search: this.res_cplx_id_search,
  //     res_building_id_search: this.res_building_id_search,
  //     res_home_id_search: this.res_home_id_search,
  //     search: this.search,
  //     active: this.actfilter,
  //     inactive: this.inactfilter,
  //     email_filter: this.resemail
  //   }).then(result => {
  //     if (result.status === 'OK') {
  //       // this.generatePDF(result.data);
  //       residetnsForPDF = result.data
  //       console.log(result.data)
  //       let obj = {}
  //       for(let i in result.data){
  //         obj = {
  //           "Name":result.data[i].res_name
  //         }
  //         console.log(result.data[i].res_name) 

  //       }
  //     } else {
  //       // Handle error
  //     }
  //   });
  // }



 

  
  // generatePDF() {
  //   const doc = new jsPDF();
    
  //   // Attach autoTable manually
  //   autoTable(doc, {
  //     head: [['Column 1', 'Column 2']],
  //     body: [
  //       ['Data 1', 'Data 2'],
  //       ['Data 3', 'Data 4']
  //     ],
  //     startY: 20
  //   });

  //   doc.save('sample.pdf');
  // }

  

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllResidents();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.resident));
    this.app.getQuery('residents/add_edit_resident', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModal').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    tempItem.res_password = '';

    if (tempItem.res_stop_living_on === '0000-00-00') {
      tempItem.res_stop_living_on = null;
    } 

    this.resident = tempItem;

    this.payloadsChanged();
    $('#myModal').modal('show');
  }

  public csvSubmit(ox = '') {
    console.log(ox);
    this.failedData = [];
    // console.log(this.adminSetting);
    this.app.getQuery('residents/add_edit_resident_by_csv', { csv_file: ox })
      .then(result => {
        console.log(result);
        if (result.status === 'OK') {
          this.onChangePage();
          if (result.failed.length > 0) {
            // alert('Uploaded, Duplicates skipped');
            this.failedData = result.failed;
            $('#myModalResidents').modal('show');
          } else {
            alert('Uploaded');
          }
        } else {
          this.onChangePage();
          alert(result.message);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }


  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



