
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-community-suppliersreqs',
  templateUrl: './community-suppliersreqs.component.html',
  styleUrls: ['./community-suppliersreqs.component.css']
})
export class CommunitySuppliersreqsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public loaded = 'N';
  public communitySuppliersreqs = [];

  public communitySuppliersreq: any = {
    csup_id: '-1',
    csup_company_name: '',
    csup_cplx_id: '-1',
    csup_home_id: '-1',
    csup_res_id: '-1',
    csup_full_name: '',
    csup_description: '',
    csup_csupcat_id: '-1',
    csup_phone: '',
    csup_email: '',
    csup_active: 'Y',
    csup_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'csup_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public csup_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public csup_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public csup_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public csup_home_id_payload_search = [
    { id: 'csup_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public csup_res_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public csup_res_id_payload_search = [
    { id: 'csup_cplx_id', value: '-1' },
    { id: 'csup_home_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public csup_csupcat_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public csup_csupcat_id_payload_search = [
  ];





  // tslint:disable-next-line:variable-name
  public csup_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public csup_home_id_payload = [
    { id: 'csup_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public csup_res_id_payload = [
    { id: 'csup_cplx_id', value: '-1' },
    { id: 'csup_home_id', value: '-1' },
  ];




  // tslint:disable-next-line:variable-name
  public csup_csupcat_id_payload = [
  ];




  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {

    this.csup_home_id_payload_search = [
      { id: 'csup_cplx_id', value: this.csup_cplx_id_search },
    ];

    this.csup_res_id_payload_search = [
      { id: 'csup_cplx_id', value: this.csup_cplx_id_search },
      { id: 'csup_home_id', value: this.csup_home_id_search },
    ];


  }

  public payloadsChanged() {
    this.csup_home_id_payload = [
      { id: 'csup_cplx_id', value: this.communitySuppliersreq.csup_cplx_id },
    ];
    this.csup_res_id_payload = [
      { id: 'csup_cplx_id', value: this.communitySuppliersreq.csup_cplx_id },
      { id: 'csup_home_id', value: this.communitySuppliersreq.csup_home_id },
    ];
  }

  public init() {
    this.communitySuppliersreqs = [];
    this.reset();
    this.getAllCommunitySuppliersreqs();
  }

  public reset() {
    this.communitySuppliersreq = {
      csup_id: '-1',
      csup_company_name: '',
      csup_cplx_id: '-1',
      csup_home_id: '-1',
      csup_res_id: '-1',
      csup_full_name: '',
      csup_description: '',
      csup_csupcat_id: '-1',
      csup_phone: '',
      csup_email: '',
      csup_active: 'Y',
      csup_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.csup_home_id_search = '-1';
    this.csup_res_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.csup_res_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onResIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onCsupcatIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.communitySuppliersreq.csup_home_id = '-1';
    this.communitySuppliersreq.csup_res_id = '-1';
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.communitySuppliersreq.csup_res_id = '-1';
    this.payloadsChanged();
  }
  public onResIdChanged(obj) {
    this.payloadsChanged();
  }
  public onCsupcatIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllCommunitySuppliersreqs() {
    this.loaded = 'N';
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      csup_cplx_id_search: this.csup_cplx_id_search,


      csup_home_id_search: this.csup_home_id_search,


      csup_res_id_search: this.csup_res_id_search,


      csup_csupcat_id_search: this.csup_csupcat_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('communitysuppliersreqs/get_all_community_suppliersreqs', obj).then(result => {

      console.log(result);
      this.loaded = 'Y';
      if (result.status === 'OK') {
        this.communitySuppliersreqs = this.communitySuppliersreqs.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.communitySuppliersreqs.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllCommunitySuppliersreqs();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.communitySuppliersreq));
    this.app.getQuery('communitysuppliersreqs/add_edit_communitysuppliersreq', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModalcsup_id').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModalcsup_id').modal('show');
    // $('#myModalcsup_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.communitySuppliersreq = tempItem;

    this.payloadsChanged();
    $('#myModalcsup_id').modal('show');
    // $('#myModalcsup_id').appendTo('body');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



