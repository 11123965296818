import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  public chartReady = false;
  public pieReady = false;
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Visits' },
    // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];

  constructor(public app: AppService,
              private router: Router, private location: Location
  ) {
    this.lang = this.app.getLang();
    this.login = { id: '-1' };
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }
  public lang;
  public login: any;
  public user: any;
  public message = '';

  ngOnInit() {
    // window.location.reload()
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
  //   $('.chart.regular').easyPieChart({
  //     barColor: '#F83F37',
  //     lineWidth: 10,
  //     trackColor: '#ddd'
  // });
  //   $('.chart.authorized').easyPieChart({
  //     barColor: '#FFBF36',
  //     lineWidth: 10,
  //     trackColor: '#ddd'
  // });
  //   $('.chart.suppliers').easyPieChart({
  //     barColor: '#22AF47',
  //     lineWidth: 10,
  //     trackColor: '#ddd'
  // });
  //   console.log('View init called');
  //   $.getScript('assets/js/ResizeSensor.js');
    $.getScript('assets/js/dashboard.js');
    // $.getScript('assets/js/chart.chartjs.js');
    this.app.getQueryOld('visits/get_dashboard_data', {}).then(result => {
      if (result.status === 'OK') {
        console.log(result);
        this.barChartLabels = result.xAxis;
        this.barChartData = [
          { data: result.yAxis, label: 'Visits' },
          // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
        ];
        this.chartReady = true;
      }
    });

    this.app.getQueryOld('visits/get_pie_data', {}).then(result => {
      if (result.status === 'OK') {
        console.log(result);
        this.pieChartLabels = ['New Visitors', 'PreAuthorized', 'Suppliers'];
        this.pieChartData = [result.regular, result.auth, result.sup];
        this.pieReady = true;

      }
    });
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public randomize(): void {
    // Only Change 3 values
    const data = [
      Math.round(Math.random() * 100),
      59,
      80,
      (Math.random() * 100),
      56,
      (Math.random() * 100),
      40];
    this.barChartData[0].data = data;
  }

}
