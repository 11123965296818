import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartsModule } from 'ng2-charts';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { SelectSingleComponent } from './components/select-single/select-single.component';
import { SelectMulitpleComponent } from './components/select-mulitple/select-mulitple.component';
import { SelectMultipleWithTagsComponent } from './components/select-multiple-with-tags/select-multiple-with-tags.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SelectComponent } from './components/select/select.component';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { FilterRadioComponent } from './components/filter-radio/filter-radio.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { MapViewRetroComponent } from './components/map-view-retro/map-view-retro.component';
import { ConsoleComponent } from './console/console.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ActivitiesComponent } from './activities/activities.component';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { AuthorizedvistorsComponent } from './authorizedvistors/authorizedvistors.component';
import { BillingPlansComponent } from './billing-plans/billing-plans.component';
import { BuildingsComponent } from './buildings/buildings.component';
import { ComplexesComponent } from './complexes/complexes.component';
import { CustomerUsersComponent } from './customer-users/customer-users.component';
import { CustomersComponent } from './customers/customers.component';
import { EmailTemplatesComponent } from './email-templates/email-templates.component';
import { EmergencyAgenciesComponent } from './emergency-agencies/emergency-agencies.component';
import { EmployeesComponent } from './employees/employees.component';
import { HelpCategoriesComponent } from './help-categories/help-categories.component';
import { HelpsComponent } from './helps/helps.component';
import { HomesComponent } from './homes/homes.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { LocationsComponent } from './locations/locations.component';
import { MmsProvidersComponent } from './mms-providers/mms-providers.component';
import { OfficesComponent } from './offices/offices.component';
import { ResidentsComponent } from './residents/residents.component';
import { SalesComponent } from './sales/sales.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SupplierUsersComponent } from './supplier-users/supplier-users.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { TranslationsComponent } from './translations/translations.component';
import { TranslationsAppComponent } from './translations-app/translations-app.component';
import { TwiliosComponent } from './twilios/twilios.component';
import { UserLevelsComponent } from './user-levels/user-levels.component';
import { UsersComponent } from './users/users.component';
import { VisitsComponent } from './visits/visits.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppService } from './app.service';
import { TranslationMgrAppComponent } from './translation-mgr-app/translation-mgr-app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SettingsComponent } from './settings/settings.component';
import { InformationComponent } from './information/information.component';
import { TranslationMgrComponent } from './translation-mgr/translation-mgr.component';
import { ActivitesCalendarComponent } from './activites-calendar/activites-calendar.component';
import { SmtpComponent } from './smtp/smtp.component';
import { InfoGenComponent } from './info-gen/info-gen.component';
import { InfoSuppliersComponent } from './info-suppliers/info-suppliers.component';
import { InfoEmgyAgenciesComponent } from './info-emgy-agencies/info-emgy-agencies.component';
import { ShortCodesComponent } from './short-codes/short-codes.component';
import { StripesComponent } from './stripes/stripes.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { RegistersComponent } from './registers/registers.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { RedictLoginComponent } from './redict-login/redict-login.component';
import { ArchivalRecordsComponent } from './archival-records/archival-records.component';
import { AuthorisedVisitorsBkpComponent } from './authorised-visitors-bkp/authorised-visitors-bkp.component';
import { ShippingServicesComponent } from './shipping-services/shipping-services.component';
import { BulletinBoardsComponent } from './bulletin-boards/bulletin-boards.component';
import { CommunitySuppliersComponent } from './community-suppliers/community-suppliers.component';
import { CommunitySuppliersCategoriesComponent } from './community-suppliers-categories/community-suppliers-categories.component';
import { BannersComponent } from './banners/banners.component';
import { TicketConversationsComponent } from './ticket-conversations/ticket-conversations.component';
import { TicketsComponent } from './tickets/tickets.component';
import { EmergenciesComponent } from './emergencies/emergencies.component';
import { TicketsCategoriesComponent } from './tickets-categories/tickets-categories.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { WebRegistrationsComponent } from './web-registrations/web-registrations.component';
import { BulletinBoardsreqsComponent } from './bulletin-boardsreqs/bulletin-boardsreqs.component';
import { CommunitySuppliersreqsComponent } from './community-suppliersreqs/community-suppliersreqs.component';
import { RegistrationsComponent } from './registrations/registrations.component';
import { RegistrationsEmailComponent } from './registrations-email/registrations-email.component';
import { LicensePlateReportsComponent } from './license-plate-reports/license-plate-reports.component';
import { MasterResetsComponent } from './master-resets/master-resets.component';
import { CsvUploadComponent } from './components/csv-upload/csv-upload.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AppFeedbacksComponent } from './app-feedbacks/app-feedbacks.component';
import { DocumentsComponent } from './documents/documents.component';
import { IncidentsComponent } from './incidents/incidents.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { LogsComponent } from './logs/logs.component';
import { FloatingChatComponent } from './floating-chat/floating-chat.component';
// import { CanvasFeedComponent } from './components/canvas-feed/canvas-feed.component';
import { CanvasFeedModule } from './components/canvas-feed/canvas-feed.module';
import { PackageLogsComponent } from './package-logs/package-logs.component';
import { WebServicesComponent } from './web-services/web-services.component';
import { WsLogsComponent } from './ws-logs/ws-logs.component';
import { ClientPasswordRecoveryComponent } from './client-password-recovery/client-password-recovery.component';
import { CommonModule, NgIf } from '@angular/common';
import { AddlogService } from './Services/addlog.service';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
const config: SocketIoConfig = { url: 'https://teknoshost.com:3000', options: {} };
@NgModule({
  declarations: [
    AppComponent,
    DatePickerComponent,
    DateTimePickerComponent,
    TimePickerComponent,
    DateRangePickerComponent,
    SelectSingleComponent,
    SelectMulitpleComponent,
    SelectMultipleWithTagsComponent,
    DropdownComponent,
    SelectComponent,
    TextFieldComponent,
    TextAreaComponent,
    CheckBoxComponent,
    MapViewRetroComponent,
    FilterRadioComponent,
    ImageUploadComponent,
    FileUploadComponent,
    ConsoleComponent,
    RegisterComponent,
    LoginComponent,
    ActivitiesComponent,
    AdminSettingsComponent,
    AuthorizedvistorsComponent,
    BillingPlansComponent,
    BuildingsComponent,
    ComplexesComponent,
    CustomerUsersComponent,
    CustomersComponent,
    EmailTemplatesComponent,
    EmergencyAgenciesComponent,
    EmployeesComponent,
    HelpCategoriesComponent,
    HelpsComponent,
    HomesComponent,
    InvoicesComponent,
    LocationsComponent,
    MmsProvidersComponent,
    OfficesComponent,
    ResidentsComponent,
    SalesComponent,
    SubscriptionsComponent,
    SupplierUsersComponent,
    SuppliersComponent,
    TranslationsComponent,
    TranslationsAppComponent,
    TwiliosComponent,
    UserLevelsComponent,
    UsersComponent,
    VisitsComponent,
    TranslationMgrAppComponent,
    TranslationMgrComponent,
    DashboardComponent,
    SettingsComponent,
    InformationComponent,
    ActivitesCalendarComponent,
    SmtpComponent,
    InfoGenComponent,
    InfoSuppliersComponent,
    InfoEmgyAgenciesComponent,
    ShortCodesComponent,
    StripesComponent,
    VehiclesComponent,
    RegistersComponent,
    ColorPickerComponent,
    CsvUploadComponent,
    RedictLoginComponent,
    ArchivalRecordsComponent,
    AuthorisedVisitorsBkpComponent,
    ShippingServicesComponent,
    BulletinBoardsComponent,
    CommunitySuppliersComponent,
    CommunitySuppliersCategoriesComponent,
    BannersComponent,
    TicketConversationsComponent,
    TicketsComponent,
    EmergenciesComponent,
    TicketsCategoriesComponent,
    WebRegistrationsComponent,
    BulletinBoardsreqsComponent,
    CommunitySuppliersreqsComponent,
    RegistrationsComponent,
    RegistrationsEmailComponent,
    LicensePlateReportsComponent,
    MasterResetsComponent,
    PasswordResetComponent,
    AppFeedbacksComponent,
    DocumentsComponent,
    IncidentsComponent,
    AppointmentsComponent,
    LogsComponent,
    FloatingChatComponent,
    PackageLogsComponent,
    WebServicesComponent,
    WsLogsComponent,
    ClientPasswordRecoveryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    SocketIoModule.forRoot(config),
    CanvasFeedModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    FullCalendarModule,
    CommonModule,
    // NgMultiSelectDropDownModule.forRoot()
    
  ],
  providers: [AppService, AddlogService],
  bootstrap: [AppComponent]
})
export class AppModule { }
