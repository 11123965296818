
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.css']
})
export class IncidentsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public loaded = 'N';
  public incidents = [];
  public isAdmin = localStorage.getItem('user_level');

  public incident: any = {
    incident_id: '-1',
    incident_name: '',
    incident_description: '',
    incident_cplx_id: '-1',
    incident_home_id: '-1',
    incident_res_id: '-1',
    incident_file: '',
    incident_date: '',
    incident_active: 'Y',
    incident_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'incident_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public incident_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public incident_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public incident_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public incident_home_id_payload_search = [
    { id: 'incident_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public incident_res_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public incident_res_id_payload_search = [
    { id: 'incident_cplx_id', value: '-1' },
    { id: 'incident_home_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public incident_date_search = '';
  // tslint:disable-next-line:variable-name
  private incident_date_search_local = '';






  // tslint:disable-next-line:variable-name
  public incident_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public incident_home_id_payload = [
    { id: 'incident_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public incident_res_id_payload = [
    { id: 'incident_cplx_id', value: '-1' },
    { id: 'incident_home_id', value: '-1' },
  ];




  public message = '';
  public logs = [];
  public browse = 'Browse';

  public login: any = {id: '-1'};

  constructor(public app: AppService, private router: Router, private location: Location) {
    this.login = this.app.getLogin();
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
  }

  public dateIsChanged(ox) {
    console.log(ox);
    if (ox !== this.incident_date_search_local) {
      this.incident_date_search_local = ox;
      this.init();
    }
  }

  public searchPayloadsChanged() {

    this.incident_home_id_payload_search = [
      { id: 'incident_cplx_id', value: this.incident_cplx_id_search },
    ];

    this.incident_res_id_payload_search = [
      { id: 'incident_cplx_id', value: this.incident_cplx_id_search },
      { id: 'incident_home_id', value: this.incident_home_id_search },
    ];


  }

  public payloadsChanged() {
    this.incident_home_id_payload = [
      { id: 'incident_cplx_id', value: this.incident.incident_cplx_id },
    ];
    this.incident_res_id_payload = [
      { id: 'incident_cplx_id', value: this.incident.incident_cplx_id },
      { id: 'incident_home_id', value: this.incident.incident_home_id },
    ];
  }

  public init() {
    this.incidents = [];
    this.reset();
    this.getAllIncidents();
  }

  public reset() {
    this.incident = {
      incident_id: '-1',
      incident_name: '',
      incident_description: '',
      incident_cplx_id: '-1',
      incident_home_id: '-1',
      incident_res_id: '-1',
      incident_file: '',
      incident_date: this.incident_date_search_local,
      incident_active: 'Y',
      incident_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.incident_home_id_search = '-1';
    this.incident_res_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.incident_res_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onResIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }


  public onCplxIdChanged(obj) {
    this.incident.incident_home_id = '-1';
    this.incident.incident_res_id = '-1';
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.incident.incident_res_id = '-1';
    this.payloadsChanged();
  }
  public onResIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllIncidents() {
    this.loaded = 'N';
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,

      incident_cplx_id_search: this.incident_cplx_id_search,


      incident_home_id_search: this.incident_home_id_search,


      incident_res_id_search: this.incident_res_id_search,

      incident_date_search: this.incident_date_search_local,
      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('incidents/get_all_incidents', obj).then(result => {

      console.log(result);
      this.loaded = 'Y';
      if (result.status === 'OK') {
        this.incidents = this.incidents.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.incidents.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllIncidents();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.incident));
    this.app.getQuery('incidents/add_edit_incident', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModalincident_id').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModalincident_id').modal('show');
    // $('#myModalincident_id').appendTo('body');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.incident = tempItem;

    this.payloadsChanged();
    $('#myModalincident_id').modal('show');
    // $('#myModalincident_id').appendTo('body');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



