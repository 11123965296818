
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import { AddlogService } from '../Services/addlog.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;
  public user_isAdmin = localStorage.getItem('user_level');
  public user_name = localStorage.getItem('user_name');
  private oldUserPassword:string = "";
  private oldActiveStatus:string ="";


  public users = [];

  public user: any = {
    user_id: '-1',
    user_email: '',
    user_password: '',
    user_first_name: '',
    user_last_name: '',
    user_level: '',
    user_photo: '',
    user_confirmed: '',
    user_active: 'Y',
    user_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'user_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';











  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location, private addLogService: AddlogService) {

  }

  ngOnInit() {
    

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {
  }

  public payloadsChanged() {
  }

  public init() {
    this.users = [];
    this.reset();
    this.getAllUsers();
  }

  public reset() {
    this.user = {
      user_id: '-1',
      user_email: '',
      user_password: '',
      user_first_name: '',
      user_last_name: '',
      user_level: '',
      user_photo: '',
      user_confirmed: '',
      user_active: 'Y',
      user_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }




  public getAllUsers() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('users/get_all_users', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.users = this.users.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.users.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllUsers();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.user));
    
    this.app.getQuery('users/add_edit_user', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModal').modal('hide');
        //Added the log service here

        // if()
        if(result.message === "User entry updated" && this.oldActiveStatus === itemObj.user_active) {
          this.addLogService.addLogs(this.user_name, this.user_isAdmin, `User "${this.user_name}" updated a new user named "${itemObj.user_first_name}" active "${itemObj.user_active}" and with a level of "${itemObj.user_level}"`).then((result) => {
            console.log("Log sucessfully added ", result);
  
          }).catch(error => {
            console.log(error.message);
  
          })
        } else {
          this.addLogService.addLogs(this.user_name, this.user_isAdmin, `User ${this.user_isAdmin} - ${this.user_name} added a new User named ${itemObj.user_first_name} and level of "${itemObj.user_level}"`).then((result) => {
            console.log("Log sucessfully added ", result);
  
          }).catch(error => {
            console.log(error.message);
  
          })
        }
      

        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    tempItem.user_password = '';

    this.user = tempItem;
    this.oldUserPassword = this.user.user_password
    this.oldActiveStatus = this.user.user_active

    $('#myModal').modal('show');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



