
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-supplier-users',
  templateUrl: './supplier-users.component.html',
  styleUrls: ['./supplier-users.component.css']
})
export class SupplierUsersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public supplierUsers = [];
  public isAdmin = localStorage.getItem('user_level');
  

  public supplierUser: any = {
         supu_id: '-1',
                   supu_email: '',
                   supu_password: '',
                   supu_sup_id: '-1',
                   supu_name: '',
                   supu_last_name: '',
                   supu_id_num: '',
                   supu_license_num: '',
                   supu_active: 'Y',
                   supu_log: '',
          };

  
  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'supu_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
                    // tslint:disable-next-line:variable-name
  public supu_sup_id_search = '-1';
    
  // tslint:disable-next-line:variable-name
  public supu_sup_id_payload_search = [
      ];
                                      
    
      
      
      
       
  // tslint:disable-next-line:variable-name
  public supu_sup_id_payload = [
      ];    
        
      
      
      
      
      
        public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {
       
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {
                                                     
                                                                      }

  public payloadsChanged() {
                                                           }

  public init() {
    this.supplierUsers = [];
    this.reset();
    this.getAllSupplierUsers();
  }

  public reset() {
    this.supplierUser = {
              supu_id: '-1',
                   supu_email: '',
                   supu_password: '',
                   supu_sup_id: '-1',
                   supu_name: '',
                   supu_last_name: '',
                   supu_id_num: '',
                   supu_license_num: '',
                   supu_active: 'Y',
                   supu_log: '',
              };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

                    public onSupIdChangedOnSearch(obj) {
        this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
                                  
                  public onSupIdChanged(obj) {
         this.payloadsChanged();
  }
                              
                                                    
  public getAllSupplierUsers() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
                
        supu_sup_id_search: this.supu_sup_id_search,
  
                                        search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('supplierusers/get_all_supplier_users', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
                this.supplierUsers = this.supplierUsers.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.supplierUsers.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllSupplierUsers();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.supplierUser));
    this.app.getQuery('supplierusers/add_edit_supplieruser', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModal').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
                                                      $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
                             tempItem.supu_password = '';
                         
                        this.supplierUser = tempItem;
                                                    
                                                $('#myModal').modal('show');
  }

                                            

  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



