import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-license-plate-reports',
  templateUrl: './license-plate-reports.component.html',
  styleUrls: ['./license-plate-reports.component.css']
})
export class LicensePlateReportsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public visits = [];

  public visit: any = {
    vi_id: '-1',
    vi_cplx_id: '-1',
    vi_home_id: '-1',
    vi_res_id: '-1',
    vi_av_id: '-1',
    vi_name: '',
    vi_last_name: '',
    vi_vehicle: '',
    vi_color: '',
    vi_plate: '',
    vi_approved_by: '',
    vi_photo_1: '',
    vi_photo_2: '',
    vi_auth_id: '',
    vi_auth_type: 'users',
    vi_active: 'Y',
    vi_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'vi_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  // tslint:disable-next-line:variable-name
  public vi_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_cplx_id_payload_search = [
  ];
  // tslint:disable-next-line:variable-name
  public vi_home_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_home_id_payload_search = [
    { id: 'vi_cplx_id', value: '-1' },
  ];
  // tslint:disable-next-line:variable-name
  public vi_res_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public vi_res_id_payload_search = [
    { id: 'vi_home_id', value: '-1' },
  ];




  // tslint:disable-next-line:variable-name
  public vi_cplx_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public vi_home_id_payload = [
    { id: 'vi_cplx_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vi_res_id_payload = [
    { id: 'vi_home_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public vi_av_id_payload = [
  ];










  public authUser = '';
  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    // this.init();
  }

  public searchPayloadsChanged() {

    this.vi_home_id_payload_search = [
      { id: 'vi_cplx_id', value: this.vi_cplx_id_search },
    ];

    this.vi_res_id_payload_search = [
      { id: 'vi_home_id', value: this.vi_home_id_search },
    ];

  }

  public payloadsChanged() {
    this.vi_home_id_payload = [
      { id: 'vi_cplx_id', value: this.visit.vi_cplx_id },
    ];
    this.vi_res_id_payload = [
      { id: 'vi_home_id', value: this.visit.vi_home_id },
    ];
  }

  public init() {
    this.visits = [];
    this.reset();
    this.getAllVisits();
  }

  public reset() {
    this.visit = {
      vi_id: '-1',
      vi_cplx_id: '-1',
      vi_home_id: '-1',
      vi_res_id: '-1',
      vi_av_id: '-1',
      vi_name: '',
      vi_last_name: '',
      vi_vehicle: '',
      vi_color: '',
      vi_plate: '',
      vi_approved_by: '',
      vi_auth_type: 'users',
      vi_photo_1: '',
      vi_photo_2: '',
      vi_auth_id: '',
      vi_active: 'Y',
      vi_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

  public onCplxIdChangedOnSearch(obj) {
    this.vi_home_id_search = '-1';
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onHomeIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
  public onResIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public onCplxIdChanged(obj) {
    this.visit.vi_home_id = '-1';
    this.payloadsChanged();
  }
  public onHomeIdChanged(obj) {
    this.payloadsChanged();
  }
  public onResIdChanged(obj) {
    this.payloadsChanged();
  }
  public onAvIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllVisits() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      license_search: 'Y',
      vi_cplx_id_search: this.vi_cplx_id_search,


      vi_home_id_search: this.vi_home_id_search,


      vi_res_id_search: this.vi_res_id_search,

      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('visits/get_all_visits', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.visits = this.visits.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.visits.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    if (this.search !== '') {
      this.onChangePage();
    }
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllVisits();
  }

  public get_auth_id() {
    this.authUser = '';
    this.app.getQuery('registers/get_selected_auth_id', { id: this.visit.vi_auth_id, type: this.visit.vi_auth_type }).then(result => {
      if (result.status === 'OK') {
        if (result.data.length > 0) {
          this.authUser = result.data[0].text;
        }

      }
    });
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.visit));
    this.app.getQuery('visits/add_edit_visit', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModal').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.visit = tempItem;
    console.log(this.visit);
    this.get_auth_id();
    this.payloadsChanged();
    $('#myModal').modal('show');
  }



  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}
