import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-info-suppliers',
  templateUrl: './info-suppliers.component.html',
  styleUrls: ['./info-suppliers.component.css']
})
export class InfoSuppliersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public suppliers = [];

  // tslint:disable-next-line:variable-name
  public res_cplx_id_search = '-1';

  // tslint:disable-next-line:variable-name
  public res_cplx_id_payload_search = [
  ];

  public supplier: any = {
    sup_id: '-1',
    sup_name: '',
    sup_contact_name: '',
    sup_phone: '',
    sup_email: '',
    sup_logo: '',
    sup_address: '',
    sup_country_id: '-1',
    sup_state_id: '-1',
    sup_city_id: '-1',
    sup_zip: '',
    complexes: [],
    sup_active: 'Y',
    sup_log: '',
  };


  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'sup_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';










  // tslint:disable-next-line:variable-name
  public sup_country_id_payload = [
  ];


  // tslint:disable-next-line:variable-name
  public sup_state_id_payload = [
    { id: 'sup_country_id', value: '-1' },
  ];


  // tslint:disable-next-line:variable-name
  public sup_city_id_payload = [
    { id: 'sup_state_id', value: '-1' },
  ];



  // tslint:disable-next-line:variable-name
  public complexes_payload = [
  ];


  public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }


  public searchPayloadsChanged() {

    // this.res_building_id_payload_search = [
    //   { id: 'res_cplx_id', value: this.res_cplx_id_search },
    // ];

    // this.res_home_id_payload_search = [
    //   { id: 'res_cplx_id', value: this.res_cplx_id_search },
    // ];

  }

  public payloadsChanged() {
    this.sup_state_id_payload = [
      { id: 'sup_country_id', value: this.supplier.sup_country_id },
    ];
    this.sup_city_id_payload = [
      { id: 'sup_state_id', value: this.supplier.sup_state_id },
    ];
  }

  public onCplxIdChangedOnSearch(obj) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  public init() {
    this.suppliers = [];
    this.reset();
    this.getAllSuppliers();
  }

  public reset() {
    this.supplier = {
      sup_id: '-1',
      sup_name: '',
      sup_contact_name: '',
      sup_phone: '',
      sup_email: '',
      sup_logo: '',
      sup_address: '',
      sup_country_id: '-1',
      sup_state_id: '-1',
      sup_city_id: '-1',
      sup_zip: '',
      complexes: [],
      sup_active: 'Y',
      sup_log: '',
    };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }


  public onCountryIdChanged(obj) {
    this.supplier.sup_state_id = '-1';
    this.supplier.sup_city_id = '-1';
    this.payloadsChanged();
  }
  public onStateIdChanged(obj) {
    this.supplier.sup_city_id = '-1';
    this.payloadsChanged();
  }
  public onCityIdChanged(obj) {
    this.payloadsChanged();
  }


  public getAllSuppliers() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      search: this.search,
      active: this.actfilter,
      res_cplx_id_search: this.res_cplx_id_search,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('suppliers/get_all_suppliers_info', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.suppliers = this.suppliers.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.suppliers.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllSuppliers();
  }

  public onSubmit() {
    // this.loadingText = 'Saving...';
    // const itemObj = JSON.parse(JSON.stringify(this.supplier));
    // this.app.getQuery('suppliers/add_edit_supplier', itemObj).then(result => {
    //   this.loadingText = 'Save';
    //   console.log(result);
    //   if (result.status === 'OK') {
    //     $('#myModal').modal('hide');
    //     this.onClear();
    //   } else {
    //     this.message = result.message;
    //     setTimeout(() => {
    //       this.message = '';
    //     }, 4000);
    //   }
    // });
  }



  public add() {
    this.reset();
    this.payloadsChanged(); $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
    this.supplier = tempItem;

    this.payloadsChanged();
    this.get_selected_complexes();
    $('#myModal').modal('show');
  }

  private get_selected_complexes() {
    this.app.getQuery('suppliers/get_selected_complexes', { id: this.supplier.sup_id }).then(result => {
      console.log(result);
      if (result.status === 'OK') {
        this.supplier.complexes = result.data;
      }
    });
  }


  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



