
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('baseForm') baseForm: NgForm;

  public actfilter = true;
  public inactfilter = false;

  public invoices = [];

  public invoice: any = {
         inv_id: '-1',
                   inv_date: '',
                   inv_due_date: '',
                   inv_cust_id: '-1',
                   inv_is_paid: '',
                   inv_is_cancelled: '',
                   inv_name: '',
                   inv_last_name: '',
                   inv_photo: '',
                   inv_active: 'Y',
                   inv_log: '',
          };

  
  public loadingText = 'Save';
  // tslint:disable-next-line:variable-name
  public num_per_page = '10';
  // tslint:disable-next-line:variable-name
  public order_by = 'inv_id';
  public order = 'DESC';
  // tslint:disable-next-line:variable-name
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
                            // tslint:disable-next-line:variable-name
  public inv_cust_id_search = '-1';
    
  // tslint:disable-next-line:variable-name
  public inv_cust_id_payload_search = [
      ];
                                          
    
      
        
        
       
  // tslint:disable-next-line:variable-name
  public inv_cust_id_payload = [
      ];    
        
      
      
      
      
      
      
        public message = '';
  public logs = [];
  public browse = 'Browse';



  constructor(public app: AppService, private router: Router, private location: Location) {
       
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.init();
  }

  public searchPayloadsChanged() {
                                                                     
                                                                              }

  public payloadsChanged() {
                                                                   }

  public init() {
    this.invoices = [];
    this.reset();
    this.getAllInvoices();
  }

  public reset() {
    this.invoice = {
              inv_id: '-1',
                   inv_date: '',
                   inv_due_date: '',
                   inv_cust_id: '-1',
                   inv_is_paid: '',
                   inv_is_cancelled: '',
                   inv_name: '',
                   inv_last_name: '',
                   inv_photo: '',
                   inv_active: 'Y',
                   inv_log: '',
              };
    this.baseForm.form.markAsPristine();
    this.baseForm.form.markAsUntouched();
    this.baseForm.form.updateValueAndValidity();
  }

                            public onCustIdChangedOnSearch(obj) {
        this.searchPayloadsChanged();
    this.onSearchSubmit();
  }
                                      
                      public onCustIdChanged(obj) {
         this.payloadsChanged();
  }
                                  
                                                        
  public getAllInvoices() {
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
                        
        inv_cust_id_search: this.inv_cust_id_search,
  
                                            search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log(JSON.stringify(obj));
    this.app.getQuery('invoices/get_all_invoices', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
                this.invoices = this.invoices.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        // this.activePage = 1;
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = (this.invoices.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public onSearchSubmit() {
    this.onChangePage();
  }

  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  public moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllInvoices();
  }

  public onSubmit() {
    this.loadingText = 'Saving...';
    const itemObj = JSON.parse(JSON.stringify(this.invoice));
    this.app.getQuery('invoices/add_edit_invoice', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log(result);
      if (result.status === 'OK') {
        $('#myModal').modal('hide');
        this.onClear();
      } else {
        this.message = result.message;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    });
  }



  public add() {
    this.reset();
                                                              $('#myModal').modal('show');
  }

  public editItem(itemObj) {
    const tempItem = JSON.parse(JSON.stringify(itemObj));
        this.invoice = tempItem;
                                                            
                                                        $('#myModal').modal('show');
  }

                                                    

  public convertToTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return breakDay;
  }


  public convertToDate(day) {
    const breakDay = moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    return breakDay;
  }

  public convertToDateTime(day) {
    const breakDay = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
    return breakDay;
  }


}



